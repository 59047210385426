import { Directive, HostListener, ElementRef } from '@angular/core';
import { LoginDataService } from './services/dhukan/login-data.service';

@Directive({
  selector: '[ngMaskCusDirectiveDirective]'
})
export class NgMaskCusDirectiveDirective {

  constructor(private el: ElementRef, private loginService: LoginDataService) {
  }

  @HostListener('blur', ['$event'])
  onBlur(event: KeyboardEvent) {
    this.loginService.fnDecimalBlur(event)
  }

}
