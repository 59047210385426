import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-editproductmain',
  templateUrl: './editproductmain.component.html',
  styleUrls: ['./editproductmain.component.css']
})
export class EditproductmainComponent implements OnInit {

    
  @Input() doctype: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  

  @Input() productid: number = 0;
  @Input() productcode: string = '';
  @Input() title: string = '';
  @Input() deptid:  number = 0;
  @Input() categoryid:  number = 0;
  @Input() subcategoryid:  number = 0;
  @Input() brandid:  number = 0;
  @Input() vendorid:  number = 0;
  @Input() taxgrpid:  number = 0;
  @Input() foodsymbolid:  number = 0;
  @Input() certificateid:  number = 0;
  @Input() tags: string = '';
  @Input() inactive: string = '';

  productform=new FormGroup({
    productid:new FormControl(0),      
    productcode:new FormControl('',Validators.required),
    title:new FormControl('',Validators.required),         
    deptid:new FormControl(0,Validators.required),      
    categoryid:new FormControl(0,Validators.required),      
    subcategoryid:new FormControl(0,Validators.required),      
    brandid:new FormControl(0,Validators.required),
    vendorid:new FormControl(0),      
    taxgrpid:new FormControl(0,Validators.required) ,      
    foodsymbolid:new FormControl(0),      
    certificateid:new FormControl(0),      
    tags:new FormControl(0),        
   inactive:new FormControl(false),      
  });
  
   //Save Validation
   submitted:boolean;
  successfully: boolean;
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress: boolean = false;
  
   //Master
   deptColl:any=[];
   categoryColl:any=[];
   subcategoryColl:any=[];
   brandColl:any=[];
   warehouseColl:any=[];
   taxgroupColl:any=[];
   vendorColl:any=[];
   uomColl:any=[];
   certificateColl:any=[];
  foodsymbolColl: any = [];
  
  constructor(private activeModal: NgbActiveModal, private appService: DhukanDataService,
    private loginService:LoginDataService,private toastr: ToastrService) { }

  ngOnInit() {
    this.fngetDepartment();
    this.fngetCategory();
    this.fngetSubCategory();     
    this.fngetBrand();
    this.fngetTaxGroup();
    this.fngetVendor();
    this.fngetUom();
    this.fngetFoodSymbol();
    this.fngetCertificate();
  
  }

  public fnInitializeProductFormGroup(){
    this.productform.patchValue({   
       productid:this.productid,   
       productcode:this.productcode,
       title:this.title,         
       deptid:this.deptid,   
       categoryid:this.categoryid,   
       subcategoryid:this.subcategoryid,   
       brandid:this.brandid, 
       vendorid:this.vendorid,   
       taxgrpid:this.taxgrpid,   
       foodsymbolid:this.foodsymbolid,   
       certificateid:this.certificateid,   
       tags:this.tags,   
       inactive:this.inactive,         
    })


 }

  
  fngetDepartment() {        
    var data = {
      'deptid':0,            
    }
    this.appService.getDepartment(data)
    .subscribe(
      (res)=>{                  
        this.deptColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
  
  fngetCategory() {        
    let _deptid=this.productform.get('deptid').value;
    var data = {
      'categoryid':0,        
      'deptid':_deptid,     
    }
  
    if(_deptid>0){
      this.appService.getCategory(data)
      .subscribe(
        (res)=>{                  
          this.categoryColl=res.json().result;     
        },
      (err)=>{          
        this.toastr.error('Error', err);
      })
    }
    else{
      this.categoryColl=[];
    }
  }
  
  fngetSubCategory() {        
    let _categoryid=this.productform.get('categoryid').value;
    var data = {
      'subcategoryid':0, 
      'categoryid':_categoryid,            
    }
    if(_categoryid>0){
        this.appService.getSubCategory(data)
        .subscribe(
          (res)=>{                        
            this.subcategoryColl=res.json().result;     
          },
        (err)=>{          
          this.toastr.error('Error', err);
        })
    }
    else{
      this.subcategoryColl=[];
    }
  
  }
  
  fngetBrand() {        
    var data = {
      'brandid':0,            
    }
    this.appService.getBrand(data)
    .subscribe(
      (res)=>{                  
        this.brandColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
  
  fngetTaxGroup() {        
    var data = {
      'taxgroupid':0,            
    }
    this.appService.getTaxGroup(data)
    .subscribe(
      (res)=>{                  
        this.taxgroupColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
  
  fngetVendor() {        
    var data = {
      'vendorid':0,            
    }
    this.appService.getVendor(data)
    .subscribe(
      (res)=>{                  
        this.vendorColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
  
  fngetUom() {        
    var data = {
      'uomid':0,            
    }
    this.appService.getUom(data)
    .subscribe(
      (res)=>{                  
        this.uomColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
  
  fngetCertificate() {        
    var data = {
      'certificateid':0,            
    }
    this.appService.getCertificate(data)
    .subscribe(
      (res)=>{                  
        this.certificateColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
  
  fngetFoodSymbol() {        
    var data = {
      'foodsymbolid':0,            
    }
    this.appService.getFoodSymbol(data)
    .subscribe(
      (res)=>{                  
        this.foodsymbolColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
  

  //Save Btn Click
  fnSaveBtnClick(mode){
  
    try{
      this.submitted=true;
      this.successfully=false;
 
 
      //Validate
      if(this.productform.valid){        
     
        
         this.fnShowProgress();
                 
          //Check
          var data = {
                'productid': this.productid,       
                'productcode':this.productform.get('productcode').value,              
                'title':this.productform.get('title').value,                
                'deptid':this.productform.get('deptid').value,    
                'categoryid':this.productform.get('categoryid').value,    
                'subcategoryid':this.productform.get('subcategoryid').value,    
                'brandid':this.productform.get('brandid').value,    
                'vendorid':this.productform.get('vendorid').value,    
                'taxgrpid':this.productform.get('taxgrpid').value,    
                'foodsymbolid':this.productform.get('foodsymbolid').value,    
                'certificateid':this.productform.get('certificateid').value,    
                'tags':this.productform.get('tags').value,    
                'inactive': 0,  
                'createdby': this.loginService.fnGetModUserId(),    
                'modifiedby':this.loginService.fnGetModUserId(),                  
          }
          //CheckMain
          this.appService.fnCheckProduct(data).subscribe(
            (res)=>{
                var _checkresult=res.json().result[0].checkfield;
              
                if(_checkresult==="done"){
                       //Update Product
                       this.fnSetProduct(data);
                }
                else{  
                      this.fnHideProgress();      
                      if(_checkresult==="name"){
                        this.productform.controls['productcode'].setErrors({'notfound': true});
                      }
                }
          },
          (err)=>{
              this.fnHideProgress();      
              this.toastr.error('Error', err);           
          });
       }
    }catch(ex){


      this.fnHideProgress();      
      this.fnShowErrorMsg(ex);
    }
 }
 
  
 fnSetProduct(data){    
  

  this.appService.fnSetProduct(data).subscribe(
   (res)=>{
   
    this.fnHideProgress();      

   if(res.json().result!=""){
       this.toastr.success('', "The product has been updated successfully.");

       this.fnInsertActivityLog('EDIT',this.productid,data.productcode+"-"+data.title);

       this.accept();
   }},
   (err)=>{
     console.log(err)
     this.toastr.error('Error', err);
   });
  
  }
  


  public accept() {
 
    this.emitData.emit(true);  
    this.activeModal.close(true);  
  }

  public decline() {
    this.activeModal.dismiss();
  }

  
  

  fnInsertActivityLog(action,refno,desc){
    try{
      var data={
        logtype:action,
        logat:"Product",
        refnum:refno,
        logdescr:desc,
        logby:this.loginService.fnGetModUserId(),
      }
    

      this.appService.fnInsertActivity(data).subscribe(
        (res)=>{
        },
        (err)=>{
       
        });

    }catch{

    }
  }


  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }
  
  private  fnShowErrorMsg(ex){
    console.log(ex)
    this.toastr.warning('Internal Error', ex);       
  }
  



}
