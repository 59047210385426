import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-feedbackdetails',
  templateUrl: './feedbackdetails.component.html',
  styleUrls: ['./feedbackdetails.component.css']
})
export class FeedbackdetailsComponent implements OnInit {

  @Input() doctype: string;
  @Input() mode: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();


  @Input() editorValue: string = '';
 

  @Input() feedbackid: number = 0;

  @Input() feedbackBO:any={};
 
  feedbackDetailsColl:any=[]



  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,private loginService:LoginDataService) { }

  ngOnInit() {
   
  }
  
  
  
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  public fnGetFeedbackInfo(){
   

      var data = {
        'feedbackid':this.feedbackid,                                  
      }


      this.appService.getFeedbackByFeedbackid(data)
      .subscribe(
        (res)=>{     
          this.feedbackDetailsColl=res.json().result;         

    
        },
      (err)=>{          
        //this.toastr.error('Error', err);
      })
  

  }



}
