import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-customeravaildate',
  templateUrl: './customeravaildate.component.html',
  styleUrls: ['./customeravaildate.component.css']
})
export class CustomeravaildateComponent implements OnInit {

  @Input() doctype: string;
  @Input() docname: string;
  @Input() mode: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  reasonColl:any=[];

  @Input() orderNo: string = '';

  @Input() customeravaildate: Date=null;
  @Input() customeravailtime: any= null;
  @Input() orderid: number = null;
  
  @Input() errordeliverydate: number = 0;
  @Input() errordeliverytime: number = 0;

 @Input() editorValue: string = '';
 

  ordersubmitted:boolean;
  ordersuccessfully:boolean;

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,private loginService:LoginDataService,private datePipe: DatePipe) { }

  ngOnInit() {
   
  }
  
    
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  fnSetDeliveryDateInOrder(){

  
    this.ordersubmitted=true;
    this.ordersuccessfully=false;

    this.errordeliverydate=0
    this.errordeliverytime=0

    var _currentdt=new Date();
    var _deliverydt=this.customeravaildate;

    var regex = new RegExp(':', 'g');
    var _currenttime=new Date().getHours().toString()+":"+new Date().getMinutes();
    var _deliverytime=this.customeravailtime;

    if(Date.parse(_deliverydt.toDateString())<Date.parse(_currentdt.toDateString())){
      this.errordeliverydate=1
      return
    }
    else if(this.fnFormatDatetime(_deliverydt)===this.fnFormatDatetime(_currentdt.toDateString()) && parseInt(_deliverytime.replace(regex, ''), 10)< parseInt(_currenttime.replace(regex, ''), 10)){
      this.errordeliverytime=1
      return
    }




    //Validate
    if(this.orderid>0){  

      var data = {
        'orderid':this.orderid,                 
        'customeravaildate':this.fnFormatDatetime(this.customeravaildate),  
        'customeravailtime': this.customeravailtime,  
        'modifiedby':this.loginService.fnGetModUserId(),            
      }
      this.appService.fnSetOrderCustomerAvailDate(data)
      .subscribe(
        (res)=>{                  
          this.emitData.emit("");  
          this.activeModal.close(true); 
        },
      (err)=>{          
        //this.toastr.error('Error', err);
      })
  
 
    }


  }

  

  fnFormatDatetime(dt){
    if(dt){
      if(dt===""){
        return null
      }
      else{
        return this.datePipe.transform(dt,"yyyy-MM-dd")  
      }
      
      }else {
      return null
    }
    
  }
  
  fnConvertDatetime(dt){
    if(dt){
      if(dt===""){
        return null
      }
      else{
        
  
        var dateParts = dt.split("-"); 
  
         var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 
  
         sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
  
         
         
         return sdate;
      }
      
      }else {
      return null
    }
   
  }

  



}
