import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ToastrService } from 'ngx-toastr';
import { ProductfindComponent } from '../productfind/productfind.component';

@Component({
  selector: 'app-pagelayoutimage',
  templateUrl: './pagelayoutimage.component.html',
  styleUrls: ['./pagelayoutimage.component.css']
})
export class PagelayoutimageComponent implements OnInit {

  @Input() doctype: string;
  @Input() mode: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  layouttypeColl:any=[];


  linktypeColl:any=[];
  deptColl:any=[];
  categoryColl:any=[];
  subcategoryColl:any=[];
  brandColl:any=[];
  vendorColl:any=[];
  pageColl:any=[];
  offerColl:any=[];
  productgroupColl:any=[];
  contentColl:any=[];


  @Input() websiteimagepath:string = '';  
  @Input() mobileimagepath:string = ''; 
  
  @Input() arwebsiteimagepath:string = '';  
  @Input() armobileimagepath:string = ''; 

  //set image
  @Input() image;
  @Input() strImage: any;
  @Input() mobileimage;
  @Input() mobilestrImage: any;
  
  @Input() arimage;
  @Input() arstrImage: any;
  @Input() armobileimage;
  @Input() armobilestrImage: any;

  @Input() linktype:string = '';  
  @Input() linktargetid:any = 0;  
  @Input() linktargetfilter:string = '';

  @Input() deptid:any = 0;  
  @Input() categoryid:any = 0;  
  @Input() subcategoryid:any = 0;  
  @Input() brandid:any = 0;  
  @Input() vendorid:any = 0;  
  @Input() pageid:any = 0;  
  @Input() productgroupid:any = 0;  
  @Input() contentid:any = 0;  
  @Input() offerid:any = 0;
  @Input() productid:any = 0;  
  @Input() productname:string = '';  
  @Input() orderbyid:number=null;

  
  @Input() paddingleft:number =0;  
  @Input() paddingright:number =0;  
  @Input() paddingtop:number =0;  
  @Input() paddingbottom:number =0;  

  layouttypesubmitted:boolean;
  layouttypesuccessfully:boolean;

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,
    private toastr: ToastrService, private productFindService: NgbModal) { }

  ngOnInit() {
    this.fngetLinkType();
  }
  
  
 
  
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  fnAddPageLayoutImages(){
    this.layouttypesubmitted=true;
    this.layouttypesuccessfully=false;
    //Validate


  
      var _req={
        websiteimagepath:this.websiteimagepath,
        websiteimage:this.strImage,      
        websiteimageload:this.image,        
        mobileimagepath:this.mobileimagepath, 
        mobileimage:this.mobilestrImage,
        mobileimageload:this.mobileimage,
        arwebsiteimagepath:this.arwebsiteimagepath,
        arwebsiteimage:this.arstrImage,      
        arwebsiteimageload:this.arimage,        
        armobileimagepath:this.armobileimagepath, 
        armobileimage:this.armobilestrImage,
        armobileimageload:this.armobileimage,
        linktype:this.linktype,    
        linktargetid:this.fnGetLinkTypeId(this.linktype), 
        linktargetfilter:this.linktargetfilter,
        targetproductname:this.productname,
        orderbyid:this.orderbyid,
        paddingleft:this.paddingleft,      
        paddingright:this.paddingright,      
        paddingtop:this.paddingtop,      
        paddingbottom:this.paddingbottom,                                 

      }
  
      this.emitData.emit(_req);  
      this.activeModal.close(true);  
   


  }


  
//Image Import
changeListener($event): void {
  try{
    this.readThis($event.target);
  }catch(ex){
  
  }
}

readThis(inputValue: any): void {

  var file: File = inputValue.files[0];
  var myReader: FileReader = new FileReader();

  myReader.onloadend = (e) => {
      this.image = myReader.result;           
      this.strImage = this.image.split(',')[1];      
  }
  myReader.readAsDataURL(file);
}

removeimage(){
    this.image =null;  
    this.strImage =null;
    this.websiteimagepath="";
}


//Mobile Image Import
mobilechangeListener($event): void {
  try{
    this.mobilereadThis($event.target);
  }catch(ex){
  
  }
}

mobilereadThis(inputValue: any): void {

var file: File = inputValue.files[0];
var myReader: FileReader = new FileReader();

myReader.onloadend = (e) => {
    this.mobileimage = myReader.result;    
    this.mobilestrImage = this.mobileimage.split(',')[1];    
}
myReader.readAsDataURL(file);
}

mobileremoveimage(){
  this.mobileimage =null;  
  this.mobilestrImage =null;
  this.mobileimagepath="";
}



 
//Ar Image Import
archangeListener($event): void {
  try{
    this.arreadThis($event.target);
  }catch(ex){
  
  }
}

arreadThis(inputValue: any): void {

  var file: File = inputValue.files[0];
  var myReader: FileReader = new FileReader();

  myReader.onloadend = (e) => {
      this.arimage = myReader.result;           
      this.arstrImage = this.arimage.split(',')[1];      
  }
  myReader.readAsDataURL(file);
}

arremoveimage(){
    this.arimage =null;  
    this.arstrImage =null;
    this.arwebsiteimagepath="";
}


//AR Mobile Image Import
armobilechangeListener($event): void {
  try{
    this.armobilereadThis($event.target);
  }catch(ex){
  
  }
}

armobilereadThis(inputValue: any): void {

var file: File = inputValue.files[0];
var myReader: FileReader = new FileReader();

myReader.onloadend = (e) => {
    this.armobileimage = myReader.result;    
    this.armobilestrImage = this.armobileimage.split(',')[1];    
}
myReader.readAsDataURL(file);
}

armobileremoveimage(){
  this.armobileimage =null;  
  this.armobilestrImage =null;
  this.armobileimagepath="";
}



fngetLinkType() { 
  let _type=[
    {display:"Department",name:"DEPARTMENT"},
    {display:"Category",name:"CATEGORY"},
    {display:"SubCategory",name:"SUBCATEGORY"},
    {display:"Brand",name:"BRAND"},
    {display:"Product",name:"PRODUCT"},
    {display:"Vendor",name:"VENDOR"},
    {display:"Page",name:"PAGE"},
    {display:"Offer",name:"OFFER"},
    {display:"ProductGroup",name:"PRODUCTGROUP"},
    {display:"Html",name:"HTML"},
    {display:"WebUrl",name:"WEBURL"},
    {display:"Offers Page",name:"OFFERS"},
    {display:"Newarrivals Page",name:"NEWARRIVALS"},
    {display:"Dealoftheday Page",name:"DEALOFTHEDAY"},

  ]

  this.linktypeColl=_type;
}

fngetDepartment() {        
  var data = {
    'deptid':0,            
  }
  this.appService.getDepartment(data)
  .subscribe(
    (res)=>{                  
      this.deptColl=res.json().result;     
    },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}

fngetCategory() {        
 
  var data = {
    'categoryid':0,        
    'deptid':0,     
  }


    this.appService.getCategory(data)
    .subscribe(
      (res)=>{                  
        this.categoryColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetSubCategory() {        
 
  var data = {
    'categoryid':0,        
    'subcategoryid':0,     
  }


    this.appService.getSubCategory(data)
    .subscribe(
      (res)=>{                  
        this.subcategoryColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetBrand() {        
 
  var data = {
    'brandid':0            
  }


    this.appService.getBrand(data)
    .subscribe(
      (res)=>{                  
        this.brandColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetVendor() {        
 
  var data = {
    'vendorid':0            
  }


    this.appService.getVendor(data)
    .subscribe(
      (res)=>{                  
        this.vendorColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}


fngetProductGroup() {        
 
  var data = {
    'productgroupid':0            
  }


    this.appService.getProductGroup(data)
    .subscribe(
      (res)=>{                  
        this.productgroupColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetPage() {        
 
  var data = {
    'pageid':0            
  }


    this.appService.getPageByListView(data)
    .subscribe(
      (res)=>{                  
        this.pageColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetOffer() {        
 
  var data = {
    'offerid':0            
  }


    this.appService.getOfferListView(data)
    .subscribe(
      (res)=>{                  
        this.offerColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetContent() {        
 
  var data = {
    'contentid':0            
  }


    this.appService.getContentmanagement(data)
    .subscribe(
      (res)=>{                  
        this.contentColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}


fnLinkTypeChange(){
  let type=this.linktype;
  if(type==="DEPARTMENT"){
    this.fngetDepartment();
  }
  else if(type==="CATEGORY"){
    this.fngetCategory();
  }
  else if(type==="SUBCATEGORY"){
    this.fngetSubCategory();
  }
  else if(type==="BRAND"){
    this.fngetBrand();
  }
  else if(type==="VENDOR"){
    this.fngetVendor();
  }
  else if(type==="PAGE"){
    this.fngetPage();
  }
  else if(type==="OFFER"){
    this.fngetOffer();
  }
  else if(type==="PRODUCTGROUP"){
    this.fngetProductGroup();
  }
  else if(type==="HTML"){
    this.fngetContent();
  }

}

fnSelectProduct(){
 
  const modalRef = this.productFindService.open(ProductfindComponent ,{ size: <any>'xl' });
  modalRef.componentInstance.doctype ="SELECT PRODUCT" ;

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    for(let wbo of receivedEntry){

      //this.linktargetid=wbo.productid;
      // this.bannerform.patchValue({ 
         this.productid=wbo.skuid;        
         this.productname=wbo.productname;
      // });

    }
  });

}



fnGetLinkTypeId(type){
  if(type==="DEPARTMENT"){
    return this.deptid;
  }
  else if(type==="CATEGORY"){
    return this.categoryid;
  }
  else if(type==="SUBCATEGORY"){
    return this.subcategoryid;
  }
  else if(type==="BRAND"){
    return this.brandid;
  }
  else if(type==="PRODUCT"){
    return this.productid;
  } 
  else if(type==="VENDOR"){
    return this.vendorid;
  } 
  else if(type==="PAGE"){
    return this.pageid;
  } 
  else if(type==="OFFER"){
    return this.offerid;
  } 
  else if(type==="PRODUCTGROUP"){
    return this.productgroupid;
  } 
  else if(type==="HTML"){
    return this.contentid;
  }   
  else {
    return null;
  }
}


}
