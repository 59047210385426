import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-cancelorder',
  templateUrl: './cancelorder.component.html',
  styleUrls: ['./cancelorder.component.css']
})
export class CancelorderComponent implements OnInit {
  @Input() doctype: string;
  @Input() docname: string;
  @Input() mode: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  reasonColl:any=[];

  @Input() productBO: any = {};
  @Input() orderNo: string = '';

  @Input() cancelreasonid: number = null;
  @Input() canceldesc: string = '';
  @Input() orderid: number = null;
  
 @Input() editorValue: string = '';
 

  ordersubmitted:boolean;
  ordersuccessfully:boolean;

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,private loginService:LoginDataService) { }

  ngOnInit() {
    this.fngetReason();
  }
  
  
  fngetReason() {   
  
      var data = {
            'reasonid': 0,            
      }
      this.appService.getReason(data)
          .subscribe(
            (res)=>{             
  
             
              if(res.json().status==200){                          
                this.reasonColl=res.json().result;
               
              }
              else if(res.json().status==400){                          
               console.log('Error', res.json().err_field);
              }
             
             
          },
            (err)=>{          
            
              console.log('Error', err);
            })
  }

  
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  fnCancelOrderProduct(){

  
    this.ordersubmitted=true;
    this.ordersuccessfully=false;

    if(!this.cancelreasonid){
      this.cancelreasonid=0;
    }

    //Validate
    if(this.cancelreasonid>0){  

      var data = {
        'orderid':this.productBO.orderid,            
        'orderdetailsid':this.productBO.orderdetailsid,            
        'iscancelled':true,   
        'cancellreasonid':this.cancelreasonid,         
        'cancelledby':this.loginService.fnGetModUserId(),    
        'cancelleddesc':this.fnGetCancelReason(this.cancelreasonid),                     
      }
      this.appService.fnSetOrderItemCancelled(data)
      .subscribe(
        (res)=>{                  
          this.emitData.emit(res.json().result[0]);  
          this.activeModal.close(true); 
        },
      (err)=>{          
        //this.toastr.error('Error', err);
      })
  
 
    }


  }

  
  fnCancelOrder(){
  
      
    this.ordersubmitted=true;
    this.ordersuccessfully=false;

    if(!this.cancelreasonid){
      this.cancelreasonid=0;
    }

    //Validate
    if(this.cancelreasonid>0){  

      var data = {
        'orderid':this.orderid,                    
        'iscancelled':true,   
        'cancellreasonid':this.cancelreasonid,         
        'modifiedby':this.loginService.fnGetModUserId(),    
        'cancelleddesc':this.fnGetCancelReason(this.cancelreasonid),  

      }
      this.appService.fnSetOrderCancelled(data)
      .subscribe(
        (res)=>{      
                     
          this.emitData.emit(res.json().result[0]);  
          this.activeModal.close(true); 
        },
      (err)=>{          
        //this.toastr.error('Error', err);
      })
  
 
    }


  }

  fnGetCancelReason(reasonid){

      let _res="";
      for(let abo of this.reasonColl){

        if(Number(abo.reasonid)===Number(reasonid)){
          _res=abo.reason;
          
        }
        
      }

      return _res;
  }

}
