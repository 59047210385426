import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addcolor',
  templateUrl: './addcolor.component.html',
  styleUrls: ['./addcolor.component.css']
})
export class AddcolorComponent implements OnInit {

  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;

  //Save Validation
  submitted:boolean;
  successfully:boolean;


  //Edit Get Values
  colorColl:any=[];
  colorid:number=0

  colorform=new FormGroup({
    color:new FormControl('',Validators.required),         
    colorid:new FormControl(0),      
   inactive:new FormControl(false),     
  });


isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Color";

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
     public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
       this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.colorid = params.id
        }  
       });
       this.fnServiceChanges();
}
 

fnServiceChanges(){
  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

fnOnInitForm(){
     try{
         this.isaddmode=true;
         this.colorform.reset();
         this.fnInitializeColorFormGroup(); 

         if(this.colorid>0){
           this.fngetColor();
         }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
}




fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.colorid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}


fnInitializeColorFormGroup(){
     this.colorform.setValue({
        color:'',
        colorid:0,      
       inactive:false,      
     })
}

//Get 
fngetColor() {        
       var data = {
         'colorid':this.colorid,            
       }
       this.appService.getColor(data)
       .subscribe(
         (res)=>{                  
           this.colorColl=res.json().result;
           var editdata = {
             'colorid':this.colorColl[0].colorid,
             'color':this.colorColl[0].color, 
             'inactive':this.colorColl[0].inactive,          
           }
           this.fnEditMode(editdata);
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })
}

//Save Btn Click
fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.colorform.valid){
           //Check
           var data = {
                 'colorid': this.colorid,       
                 'color':this.colorform.get('color').value,            
                 'inactive': 0,  
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(), 
           }
           this.appService.fnCheckColor(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                       if (this.colorform.get('colorid').value>0){
                         //Update Department
                         this.fnSetColor(data);
                       }
                       else{
                         //Insert Department
                         this.fnInsertColor(data);
                       }
                 }
                 else{        
                       if(_checkresult==="name"){
                         this.colorform.controls['color'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
}

//Insert
fnInsertColor(data){    
   
     this.appService.fnInsertColor(data).subscribe(
       (res)=>{
       
       if(res.json().result!=""){
         this.toastr.success('', "The new color has been added successfully.Insert Successfully");

         this.fnInsertActivityLog('INSERT',0,data.color);

         this.fnCloseColor();
       }},
       (err)=>{
         this.toastr.error('Error', err);
       });

}

//Set
fnSetColor(data){    

 this.appService.fnSetColor(data).subscribe(
   (res)=>{
   if(res.json().result!=""){
       this.toastr.success('', "The color has been updated successfully.");

       this.fnInsertActivityLog('EDIT',this.colorid,data.color);

       this.fnCloseColor();        
   }},
   (err)=>{
     this.toastr.error('Error', err);
   });

}

//Close
fnCloseColor(){
 try{
    this.router.navigate(['/Color']);
 }catch(ex){
     this.fnShowErrorMsg(ex);
 }
}

private fnEditMode(deptBO){
   this.colorform.setValue({
     color:deptBO.color,
     colorid:deptBO.colorid,
     inactive:deptBO.inactive,   
   });
  
 this.isaddmode=false;
 this.iseditmode=true;
 this.isdeletemode=false;
 this.isviewmode=false;
}


fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
 this.toastr.warning('Internal Error', ex);       
}




}
