import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { DatePipe } from '@angular/common';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ProductfindComponent } from '../../productfind/productfind.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-addpricechange',
  templateUrl: './addpricechange.component.html',
  styleUrls: ['./addpricechange.component.css']
})
export class AddpricechangeComponent implements OnInit {


  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  pricechangedetailsColl: any = [];

  pricechangedetailsnotmatchColl: any = [];

  warehouseColl: any = [];

  //Edit Get Values
  pricechangeBO: any = {};
  pricechangeColl: any = [];
  pricechangeid: number = 0


  pricechangeform = new FormGroup({
    pricechangeno: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    pricechangedate: new FormControl(null, Validators.required),
    syncdate: new FormControl(null),
    warehouseid: new FormControl(0, Validators.required),
    pricechangeid: new FormControl(0),
    pricechangetype: new FormControl(null, Validators.required),
    inactive: new FormControl(false),
  });

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "PriceChange";

  pricechangetypeColl:any=[]

  filterby: String = "MATCH";
  isimport: boolean = false;

  searchtxt: String = "";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private productFindService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService, private datePipe: DatePipe, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.pricechangeid = params.id
      }
    });


    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.pricechangeform.reset();
      this.fnInitializepricechangeformGroup();

      if (this.pricechangeid > 0) {
        this.fngetPriceChange();
      }
      else {
        this.iseditmode = true;
      }
      this.fngetWarehouse();
      this.fngetStatus();


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.pricechangeid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializepricechangeformGroup() {
    this.pricechangeform.setValue({
      pricechangeno: 'New',
      description: '',
      pricechangedate: new Date(),
      syncdate: new Date(),
      warehouseid: null,
      pricechangeid: 0,
      pricechangetype:null,
      inactive: false,
    })
  }


  fngetStatus() {
    this.pricechangetypeColl = [];
    this.pricechangetypeColl.push({name:'PRICE',displayname:'Price Change'})
    this.pricechangetypeColl.push({name:'STOCK',displayname:'Stock Change'})
    this.pricechangetypeColl.push({ name: 'COST', displayname: 'Cost Change' })
    if (this.pricechangeid === 0) {
      this.pricechangeform.patchValue({'pricechangetype':'PRICE'})  
    }
    

  
  }

  fngetWarehouse() {
    var data = {
      'warehouseid': 0,
    }
    this.appService.getWarehouse(data)
      .subscribe(
        (res) => {
          this.warehouseColl = res.json().result;
          if (this.warehouseColl.length > 0) {
            //this.pricechangeform.patchValue({ 'warehouseid': this.warehouseColl[0].warehouseid })
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  //Get 
  fngetPriceChange() {
    var data = {
      'pricechangeid': this.pricechangeid,
    }
    this.appService.getPriceChangebyPriceChangeid(data)
      .subscribe(
        (res) => {

          this.pricechangeColl = res.json().result[0];

          this.pricechangeBO = this.pricechangeColl[0];

          var editdata = {
            'pricechangeid': this.pricechangeColl[0].pricechangeid,
            'pricechangeno': this.pricechangeColl[0].pricechangeno,
            'description': this.pricechangeColl[0].description,
            'pricechangedate': this.pricechangeColl[0].pricechangedate,
            'syncdate': this.pricechangeColl[0].syncdate,
            'warehouseid': this.pricechangeColl[0].warehouseid,
            'pricechangetype': this.pricechangeColl[0].pricechangetype,
            'inactive': this.pricechangeColl[0].inactive,
          }
          this.fnEditMode(editdata);
          this.pricechangedetailsColl = res.json().result[1];


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.pricechangeform.valid) {
        //Check
        var data = {
          'pricechangeid': this.pricechangeid,
          'pricechangeno': this.pricechangeform.get('pricechangeno').value,
          'description': this.pricechangeform.get('description').value,
          'pricechangedate': this.fnFormatDatetime(this.pricechangeform.get('pricechangedate').value),
          'syncdate': this.fnFormatDatetime(this.pricechangeform.get('syncdate').value),
          'warehouseid': this.pricechangeform.get('warehouseid').value,
          'pricechangetype': this.pricechangeform.get('pricechangetype').value,
          'inactive': 0,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
          'pricechangedetailscoll': this.pricechangedetailsColl
        }

        if (this.pricechangeform.get('pricechangeid').value > 0) {
          //Update Department
          this.fnSetPriceChange(data);
        }
        else {
          //Insert Department
          this.fnInsertPriceChange(data);
        }

        //  this.appService.fnCheckOffer(data).subscribe(
        //    (res)=>{
        //        var _checkresult=res.json().result[0].checkfield;

        //        if(_checkresult==="done"){

        //        }
        //        else{        
        //              if(_checkresult==="name"){
        //                this.pricechangeform.controls['offertype'].setErrors({'notfound': true});
        //              }
        //        }
        //  },
        //  (err)=>{
        //      this.toastr.error('Error', err);           
        //  });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertPriceChange(data) {
    this.fnShowProgress();
    this.appService.fnInsertPriceChange(data).subscribe(
      (res) => {
        this.fnHideProgress();

        if (res.json().result != "") {
          this.toastr.success('', "The new PriceChange has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.desc);

          this.fnClosePriceChange();
        }
      },
      (err) => {
        this.fnHideProgress();
        console.log(err)
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetPriceChange(data) {
    this.fnShowProgress();
    this.appService.fnSetPriceChange(data).subscribe(
      (res) => {
        this.fnHideProgress();

        if (res.json().result != "") {
          this.toastr.success('', "The PriceChange has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.pricechangeid, data.pricechangeno);

          this.fnClosePriceChange();
        }
      },
      (err) => {
        this.fnHideProgress();
        this.toastr.error('Error', err);
      });

  }

  //Post
  public fnPostConfirm() {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Post PriceChange?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnPostPriceChange()
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnPostPriceChange() {
    var data = {
      'pricechangeid': this.pricechangeid,
    }
    this.appService.fnSetPriceChangePost(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            this.toastr.success('', " The PriceChange has been Post successfully.");

            var _no = ""
            this.pricechangeform.get('pricechangeno').value;
            this.fnInsertActivityLog('POST', this.pricechangeid, _no);

            this.fnClosePriceChange();
          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Close
  fnClosePriceChange() {
    try {
      this.router.navigate(['/PriceChange']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  //PriceChange Product
  fnAddPriceChangeProduct() {


    const modalRef = this.productFindService.open(ProductfindComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "PRODUCT";

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {



      for (let wbo of receivedEntry) {

        if (this.pricechangedetailsColl.length === 0) {
          wbo.oldprice = wbo.sellingprice;

          this.pricechangedetailsColl.push(wbo);
        }
        else {

          const exitindex = this.pricechangedetailsColl.findIndex(sku => sku.productid === wbo.productid && sku.skuid === wbo.skuid);

          if (exitindex < 0) {
            wbo.oldprice = wbo.sellingprice;

            this.pricechangedetailsColl.push(wbo);
          }

        }


      }

      this.pricechangedetailsColl = this.pricechangedetailsColl;

      this.fnSetDataTable();
    });


  }

  fnDeletePriceChangeProduct(prodindex) {
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete PriceChange Product')
      .then((confirmed) => {
        if (confirmed) {

          this.pricechangedetailsColl.splice(prodindex, 1);

        }
      });
  }

  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }

  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {


        var dateParts = dt.split("-");

        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);

        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());



        return sdate;
      }

    } else {
      return null
    }

  }


  private fnEditMode(deptBO) {

    deptBO.pricechangedate = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.pricechangedate));
    deptBO.syncdate = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.syncdate));

    this.pricechangeform.setValue({
      pricechangeno: deptBO.pricechangeno,
      description: deptBO.description,
      pricechangedate: deptBO.pricechangedate,
      syncdate: deptBO.syncdate,
      warehouseid: deptBO.warehouseid,
      pricechangeid: deptBO.pricechangeid,
      pricechangetype: deptBO.pricechangetype,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = false;
    this.isdeletemode = false;
    this.isviewmode = true;
  }

  fnChangeEditMode() {
    this.searchtxt = "";
    this.isviewmode = false;
    this.iseditmode = true;
  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }


  fnGridReady(params) {
 
  }



  onFileChange(event: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data); // Data will be logged in array format containing objects

      this.fnInsertImport(data)


    };
  }


  //Insert
  fnInsertImport(detailsdata) {


    this.fnShowProgress();
    var data = {
      'doctype': "PRICECHANGE",
      'date': this.fnFormatDatetime(this.pricechangeform.get('pricechangedate').value),
      'warehouseid': this.pricechangeform.get('warehouseid').value,
      'inactive': 0,
      'createdby': this.loginService.fnGetModUserId(),
      'modifiedby': this.loginService.fnGetModUserId(),
      'importdetailscoll': detailsdata
    }


    this.appService.fnInsertImport(data).subscribe(
      (res) => {
        this.fnHideProgress();

        if (res.json().status == 200) {
          this.toastr.success('', "The new Import has been added successfully.Insert Successfully");

          this.isimport = true;

          this.pricechangedetailsColl = res.json().result[0];
          this.pricechangedetailsnotmatchColl = res.json().result[1];

        }
      },
      (err) => {
        this.fnHideProgress();
        console.log(err)
        this.toastr.error('Error', err);
      });

  }



  private fnSetDataTable() {
  
  }


  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }






}
