import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldsum'
})
export class FieldsumPipe implements PipeTransform {

  transform(items: any[], attr: string): number {
    // console.log('items:', items, 'attr:', attr);
    return  this.roundTo(items.reduce((a, b) => a + b[attr], 0),2);
  }
  
  roundTo(n, digits) {
    if (digits === undefined) {
      digits = 0;
    }
  
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    var test =(Math.round(n) / multiplicator);
    return +(test.toFixed(digits));
  }
  
}
