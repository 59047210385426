import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';

@Component({
  selector: 'app-productspecification',
  templateUrl: './productspecification.component.html',
  styleUrls: ['./productspecification.component.css']
})
export class ProductspecificationComponent implements OnInit {
  @Input() doctype: string;
  @Input() mode: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  specificationColl:any=[];

  @Input() specificationid: number = null;
  @Input() editorValue: string = '';
 

  specificationsubmitted:boolean;
  specificationsuccessfully:boolean;

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService) { }

  ngOnInit() {
    this.fngetSpecification();
  }
  
  
  fngetSpecification() {        
    var data = {
      'specificationid':0,            
    }
    this.appService.getSpecification(data)
    .subscribe(
      (res)=>{                  
        this.specificationColl=res.json().result;     
      },
    (err)=>{          
      //this.toastr.error('Error', err);
    })
  }

  
  
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  fnAddSpecificationProduct(){
    this.specificationsubmitted=true;
    this.specificationsuccessfully=false;
    //Validate
    if(this.specificationid >0 && this.editorValue!==""){  

      const specindex = this.specificationColl.findIndex(sku => sku.specificationid === this.specificationid  );  

      var _req={
        specificationid:this.specificationid,
        specificationname:this.specificationColl[specindex].specificationname,
        value:this.editorValue
      }
  
      this.emitData.emit(_req);  
      this.activeModal.close(true);  
    }


  }


  fnChangeSpecification(){
      if(this.mode==="ADD"){
        const index = this.specificationColl.findIndex(sku => sku.specificationid === this.specificationid  ); 

        if(index>-1){
          this.editorValue=this.specificationColl[index].defaultvalue;
        }
        
      }
  }

}
