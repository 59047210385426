import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { FibaseDataService } from 'src/app/services/dhukan/fibase-data.service';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-deliveryboyaddressmap',
  templateUrl: './deliveryboyaddressmap.component.html',
  styleUrls: ['./deliveryboyaddressmap.component.css']
})
export class DeliveryboyaddressmapComponent implements OnInit {


  @Input() doctype: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();

  @Input() latitude = 11.0168;
  @Input() longitude =  76.9558;
 
  @Input() deliveryboymaptype = "SINGLE";
  

  @Input() deliveryboyname = "";
  @Input() deliveryboyuserid ="";
  @Input() deliveryboyid =0;

  deliveryboyColl:any=[];

  mapType = 'roadmap';
  //markericon = 'http://bigmart.ae/bigmart/bigmartadmin/assets/images/marker.png';
  markericon ="https://i.stack.imgur.com/lDrin.png";

 labelOptions = {
    color: 'white',fontFamily: '',fontSize: '14px',fontWeight: 'bold',text: "some text"
}

 styles=[
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#47ebf3"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ];


  fireshipment: any=[];


  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,
     private firebasedataservice: FibaseDataService,private firestore: AngularFireDatabase) {
       this.fnServiceChanges();
      }

  fnServiceChanges(){
    

    if(this.deliveryboymaptype==="ALL"){
   

      this.firebasedataservice.fnGetDeliveryBoyLocation().subscribe(res=>{    
      
        this.fireshipment=res;

        console.log(this.fireshipment)

        if(this.deliveryboyColl){

          for(let abo of this.deliveryboyColl){
            for(let fbo of this.fireshipment){
              if(abo.userid===fbo.id){       
                abo.latitude=fbo.latitude;
                abo.longitude=fbo.longitude;
              }
            }
          }         
        }       
      })

    }



  }
  ngOnInit() {
  
  }

  public fngetDeliveryBoy() {   
    
      var data = {
            'deliveryboyid': 0,            
      }
      this.appService.getDeliveryBoy(data)
          .subscribe(
            (res)=>{      
             
              if(res.json().status==200){                          
                this.deliveryboyColl=res.json().result;

                for(let abo of this.deliveryboyColl){
                  if(abo.latitude>0){
                    this.latitude=abo.latitude;
                    this.longitude=abo.longitude;
                  }
                  
                }

                  
              }
              else if(res.json().status==400){                          
                
              }
             
             
          },
            (err)=>{          
             
            })
  }

 

  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }



}
