import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-salesbybrand',
  templateUrl: './salesbybrand.component.html',
  styleUrls: ['./salesbybrand.component.css']
})
export class SalesbybrandComponent implements OnInit {

  
  mainColl: any = [];


  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "SalesByBrand";

  fromdate: any = null;
  todate: any = null;

  isshowfromdate: boolean = false;

  private gridApi;
  private gridColumnApi;
  pinnedBottomRowData;

  columnDefs = [   
  { headerName: 'Brand', field: 'brandname', sortable: true, filter: true },  
  { headerName: 'Qty', field: 'qty', sortable: true, filter: true,valueFormatter: this.fnFormatDecimal },
  { headerName: 'Nettotal', field: 'nettotal', sortable: true, filter: true,valueFormatter:this.fnFormatDecimal  },  
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
  };

  
  constructor(private appService: DhukanDataService, public router: Router,
    private confirmationDialogService: ConfirmationDialogService, private datePipe: DatePipe,
    private toastr: ToastrService, private loginService: LoginDataService) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {

      this.fromdate = new Date();
      this.todate = new Date();


      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetReportListView();
  }

  fnCheckPrivilage() {
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  fngetReportListView() {
    if (this.showProgress) {
      return;
    }

    
    var fromdate = null;
    if (!this.isshowfromdate) {
      fromdate = this.fnFormatDatetime(this.fromdate);
    }
    var data = {
      'fromdate': fromdate,
      'todate': this.fnFormatDatetime(this.todate),
      'formname': 'SALESBYBRAND',
      'modifiedby': this.loginService.fnGetModUserId(),
    }
    this.appService.getReportSalesView(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.mainColl = res.json().result[0];

            this.pinnedBottomRowData = res.json().result[1];

          

            this.fnSetTable();

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  private fnSetTable() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }

  }

  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }

  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {


        var dateParts = dt.split("-");

        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);

        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());



        return sdate;
      }

    } else {
      return null
    }

  }


  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {
      const params = {
        columnGroups: true,
        allColumns: true,
        fileName: 'SalesByBrand',
        //columnSeparator: document.querySelector("#columnSeparator").value
      };
      this.gridApi.exportDataAsCsv(params);

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnFormatDecimal(params) {
    if (params.value) {
     return params.value.toFixed(2)
    }    
  }

  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }


}
