import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addproductgroup',
  templateUrl: './addproductgroup.component.html',
  styleUrls: ['./addproductgroup.component.css']
})
export class AddproductgroupComponent implements OnInit {

//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
productgroupColl:any=[];
productgroupid:number=0

pageColl:any=[];

productgroupform=new FormGroup({         
  productgroup:new FormControl('',Validators.required),            
  pageid:new FormControl(null), 
  productgroupid:new FormControl(0),      
  inactive:new FormControl(false),     
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="ProductGroup";

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
     this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.productgroupid = params.id
      }  
     });
     this.fnServiceChanges();
}


fnServiceChanges(){
  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

fnOnInitForm(){
   try{
       this.isaddmode=true;
       this.productgroupform.reset();
       this.fnInitializeProductGroupFormGroup(); 

       if(this.productgroupid>0){
         this.fngetProductGroup();
       }       

       this.fngetPage();
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}



fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.productgroupid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}


fnInitializeProductGroupFormGroup(){
   this.productgroupform.setValue({
    productgroup:'',    
    productgroupid:0, 
    pageid:null,     
    inactive:false,      
   })
}




//Get 
fngetProductGroup() {        
     var data = {
       'productgroupid':this.productgroupid,            
     }
     this.appService.getProductGroup(data)
     .subscribe(
       (res)=>{                  
         this.productgroupColl=res.json().result;
         var editdata = {
           'productgroupid':this.productgroupColl[0].productgroupid,
           'productgroup':this.productgroupColl[0].productgroup,    
           'pageid':this.productgroupColl[0].pageid,          
           'inactive':this.productgroupColl[0].inactive,          
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}


  
fngetPage() {   
  var data = {
        'pageid': 0,            
  }
  this.appService.getPageByListView(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){                          
            this.pageColl=res.json().result;          
          }
          else if(res.json().status==400){                          
            this.toastr.error('Error', res.json().err_field);
          }
         
         
      },
        (err)=>{          
          this.toastr.error('Error', err);
        })
}




//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.productgroupform.valid){
         //Check
         var data = {
               'productgroupid': this.productgroupid,                   
               'productgroup':this.productgroupform.get('productgroup').value,            
               'pageid':this.productgroupform.get('pageid').value,            
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
         }
         this.appService.fnCheckProductGroup(data).subscribe(
           (res)=>{
               var _checkresult=res.json().result[0].checkfield;
             
               if(_checkresult==="done"){
                     if (this.productgroupform.get('productgroupid').value>0){
                       //Update Department
                       this.fnSetProductGroup(data);
                     }
                     else{
                       //Insert Department
                       this.fnInsertProductGroup(data);
                     }
               }
               else{        
                     if(_checkresult==="name"){
                       this.productgroupform.controls['paytype'].setErrors({'notfound': true});
                     }
               }
         },
         (err)=>{
             this.toastr.error('Error', err);           
         });
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertProductGroup(data){    
 
   this.appService.fnInsertProductGroup(data).subscribe(
     (res)=>{
     if(res.json().result!=""){
       this.toastr.success('', "The new productgroup has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.productgroup);

       this.fnCloseProductGroup();
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetProductGroup(data){    

this.appService.fnSetProductGroup(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The productgroup has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.productgroupid,data.productgroup);

     this.fnCloseProductGroup();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseProductGroup(){
try{
  this.router.navigate(['/ProductGroup']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){

 this.productgroupform.setValue({
      productgroup:deptBO.productgroup,      
      productgroupid:deptBO.productgroupid,
      pageid:deptBO.pageid,
      inactive:deptBO.inactive,   
 });


this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}


fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}



private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}

private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}





}
