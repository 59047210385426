import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-adderpinfo',
  templateUrl: './adderpinfo.component.html',
  styleUrls: ['./adderpinfo.component.css']
})
export class AdderpinfoComponent implements OnInit {

  @Input() doctype: string;
  @Input() mode: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  @Input() displaytxt: string="";

  @Input() deliveryorderBO: any = {};
     
  @Input() editorValue: string = '';
 
  @Input() orderBO: any = {};
  
  @Input() trxid: number = 0;
  @Input() trxtype: string = "";


  submitted:boolean=false;
  successfully: boolean = false;
  
  shipmentreadyform=new FormGroup({
    invoiceno:new FormControl('',Validators.required),  
    invoicedate:new FormControl(null,Validators.required),  
    invoiceamt: new FormControl(null, Validators.required),
    controllno:new FormControl('',Validators.required),  
    taxamt:new FormControl(null,Validators.required), 
  })
  

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,private loginService:LoginDataService,private datePipe: DatePipe,) { }

  ngOnInit() {
    //this.fnInitializeStatusFormGroup();
  }

    
 
fnInitializeStatusFormGroup(){



  this.shipmentreadyform.setValue({
   invoiceno:'',
   invoicedate:null,
    invoiceamt: null,    
    controllno: '',
    taxamt:null
  })
}
  
  fnSetShipment() {
    console.log(this.orderBO)

    this.shipmentreadyform.setValue({
       invoiceno:this.orderBO.erpinvoiceno,
       invoicedate:this.orderBO.erpinvoicedate,
       invoiceamt: this.orderBO.erpinvoiceamt,    
       controllno: this.orderBO.erpcontrollno,
       taxamt:this.orderBO.erptaxamt
    })

    console.log(this.orderBO)
  }

  
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


 


  
fnDeliveryOrderReadyToPick(){
  this.submitted=true;
  this.successfully=false;


  if(this.shipmentreadyform.valid){
    this.fnSetERPOrder()
  }

}

fnSetERPOrder(){

    var data = {
      trxid:this.trxid,
      trxtype:this.trxtype,
      invoiceno:this.shipmentreadyform.get('invoiceno').value,
      invoicedate:this.fnFormatDatetime(this.shipmentreadyform.get('invoicedate').value),  
      invoiceamt: this.shipmentreadyform.get('invoiceamt').value,
      taxamt: this.shipmentreadyform.get('taxamt').value,
      controllno:this.shipmentreadyform.get('controllno').value,
      modifiedby:this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetOrderErpInfo(data)
        .subscribe(
          (res)=>{           
           
            if(res.json().status==200){           
              var _result=res.json().result[0][0].checkresult       
              if(_result==='DONE'){
                this.emitData.emit("DONE");  
                this.activeModal.close(true); 
            
              }
              else if(_result==='INVOICENO'){
                this.shipmentreadyform.controls['invoiceno'].setErrors({'notfound': true})
              }
            
            }
            else if(res.json().status==400){     
             
            }                          
        },
          (err)=>{          
          
    })
}
  



fnFormatDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      return this.datePipe.transform(dt,"yyyy-MM-dd")  
    }
    
    }else {
    return null
  }
  
}

fnConvertDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      

      var dateParts = dt.split("-"); 

       var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 

       sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());

       
       
       return sdate;
    }
    
    }else {
    return null
  }
 
}
  
}
