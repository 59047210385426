import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { DeliveryboyaddressmapComponent } from '../deliveryboyaddressmap/deliveryboyaddressmap.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-adddeliveryboy',
  templateUrl: './adddeliveryboy.component.html',
  styleUrls: ['./adddeliveryboy.component.css']
})
export class AdddeliveryboyComponent implements OnInit {

//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
deliveryboyColl:any=[];
detailsBO:any={};
deliveryboyid:number=0
  deliveryboyBO: any = {};
  
deliveryboyform=new FormGroup({
  deliveryboycode:new FormControl('',Validators.required),         
  deliveryboyname:new FormControl('',Validators.required),         
  mobileno:new FormControl(''),
  altmobileno:new FormControl(''),
  userid:new FormControl(''),
  deliveryboyid:new FormControl(0),      
  inactive:new FormControl(false),     
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="DeliveryBoy";

constructor(private appService: DhukanDataService,private route: ActivatedRoute, private productFindService: NgbModal,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
     this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.deliveryboyid = params.id
      }  
     });

     this.fnServiceChanges();
}

fnServiceChanges(){
  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

fnOnInitForm(){
   try{
       this.isaddmode=true;
       this.deliveryboyform.reset();
       this.fnInitializeDeliveryBoyFormGroup(); 

       if(this.deliveryboyid>0){
         this.fngetDeliveryBoy();
       }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}


fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.deliveryboyid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

fnInitializeDeliveryBoyFormGroup(){
   this.deliveryboyform.setValue({
    deliveryboycode:'',
    deliveryboyname:'',
    mobileno:'',
    altmobileno:'',
    userid:'',
    deliveryboyid:0,      
     inactive:false,      
   })
}

//Get 
fngetDeliveryBoy() {        
     var data = {
       'deliveryboyid':this.deliveryboyid,            
     }
     this.appService.getDeliveryBoy(data)
     .subscribe(
       (res)=>{                  
         this.deliveryboyColl=res.json().result;
         this.detailsBO=this.deliveryboyColl[0];
         var editdata = {
           'deliveryboyid':this.deliveryboyColl[0].deliveryboyid,
           'deliveryboycode':this.deliveryboyColl[0].deliveryboycode, 
           'deliveryboyname':this.deliveryboyColl[0].deliveryboyname, 
           'mobileno':this.deliveryboyColl[0].mobile, 
           'altmobileno':this.deliveryboyColl[0].altmobile, 
           'userid':this.deliveryboyColl[0].userid, 
           'inactive':this.deliveryboyColl[0].inactive,          
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.deliveryboyform.valid){
         //Check
         var data = {
               'deliveryboyid': this.deliveryboyid,       
               'deliveryboycode':this.deliveryboyform.get('deliveryboycode').value,            
               'deliveryboyname':this.deliveryboyform.get('deliveryboyname').value,            
               'mobileno':this.deliveryboyform.get('mobileno').value,
               'altmobileno':this.deliveryboyform.get('altmobileno').value,
               'userid':this.deliveryboyform.get('userid').value,
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
         }
         this.appService.fnCheckDeliveryBoy(data).subscribe(
           (res)=>{
               var _checkresult=res.json().result[0].checkfield;
             
               if(_checkresult==="done"){
                     if (this.deliveryboyform.get('deliveryboyid').value>0){
                       //Update Department
                       this.fnSetDeliveryBoy(data);
                     }
                     else{
                       //Insert Department
                       this.fnInsertDeliveryBoy(data);
                     }
               }
               else{        
                     if(_checkresult==="name"){
                       this.deliveryboyform.controls['deliveryboycode'].setErrors({'notfound': true});
                     }
               }
         },
         (err)=>{
             this.toastr.error('Error', err);           
         });
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertDeliveryBoy(data){    
 
   this.appService.fnInsertDeliveryBoy(data).subscribe(
     (res)=>{
     if(res.json().result!=""){
       this.toastr.success('', "The new deliveryboy has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.deliveryboyname);

       this.fnCloseDeliveryBoy();
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetDeliveryBoy(data){    

this.appService.fnSetDeliveryBoy(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The deliveryboy has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.deliveryboyid,data.deliveryboyname);

     this.fnCloseDeliveryBoy();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseDeliveryBoy(){
try{
  this.router.navigate(['/DeliveryBoy']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){

 this.deliveryboyform.setValue({
      deliveryboycode:deptBO.deliveryboycode,
      deliveryboyname:deptBO.deliveryboyname,
      mobileno:deptBO.mobileno,
      altmobileno:deptBO.altmobileno,
      userid:deptBO.userid,
      deliveryboyid:deptBO.deliveryboyid,
      inactive:deptBO.inactive,   
 });


this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}

fnGeoLocationDeliveryBoy(){
  
  let dbBO = this.detailsBO;
  
  const modalRef = this.productFindService.open(DeliveryboyaddressmapComponent ,{ size: <any>'xl'  });
  modalRef.componentInstance.doctype ="COMBO PRODUCT" ;
  modalRef.componentInstance.deliveryboymaptype ="SINGLE" ;    
  modalRef.componentInstance.latitude =dbBO.latitude ;
  modalRef.componentInstance.longitude =dbBO.longitude ;
  modalRef.componentInstance.deliveryboyname =dbBO.deliveryboyname ;
  modalRef.componentInstance.deliveryboyuserid =dbBO.userid ;
  modalRef.componentInstance.deliveryboyid =dbBO.deliveryboyid ;

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    
  });


}
  
   
fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}



private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}



}
