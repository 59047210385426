import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
declare var $ :any;

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css']
})
export class CertificateComponent implements OnInit ,AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: {  };

  certificateColl:any=[];
  
  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="Certificate";


  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,private loginService:LoginDataService) 
    {   this.fnServiceChanges(); }


     //User Check
  fnServiceChanges(){
      this.loginService.serviceloginChange.subscribe((value) => {
            if(this.loginService.fnCheckUser()){                
                this.fnCheckPrivilage();
                if(this.isview){
                  this.isloading=true;
                  this.fnOnInitForm();
                }
                else{
                  this.fnUserFailedForm();
                }    
            }
            else{
              this.fnUserFailedForm();
            }
      });
    }


  ngOnInit() {
    try{
  
      this.dtOptions = {
        searching: true,       
        pageLength: 25,
        serverSide: false,
        processing: true,
        dom:'<"top"f>rt<"bottom"ilp><"clear">',   
        columns:[  
          {title:'Action'},     
          {title:'Picture'},       
          {title:'Certificate'},   
            
        ],  
        order:[1, 'desc'],
        scrollX: true,                 
        scrollCollapse: true,     
        buttons: [        
          {
            title: this.formname +" List",
            extend: "print",            
            exportOptions: {                
                columns: [  1, 2,  ]
            }
          },
          {
            title: this.formname +" List",
            extend: "excel",            
            exportOptions: {
                columns: [  1, 2,  ]
            }
          },        
        ] ,                          
    };

    if(this.loginService.fnCheckUser()){         
      this.fnCheckPrivilage();
      if(this.isview){
        this.isloading=true;
        this.fnOnInitForm();
      }
      else{
        this.fnUserFailedForm();
      }          
    }
    else{
      this.loginService.fnGetUserRoleList();
    }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }
          
  fnOnInitForm(){
    this.fngetCertificate();   
  }

    
  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }
  
  


  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  fngetCertificate() {  
    
           
    if(this.showProgress){
      return;
    }

    this.fnShowProgress();
      var data = {
            'certificateid': 0,            
      }
      this.appService.getCertificate(data)
          .subscribe(
            (res)=>{ 
              this.fnHideProgress();                 
              if(res.json().status==200){                          
                this.certificateColl=res.json().result;
                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
          },
            (err)=>{      
              this.fnHideProgress();    
              this.toastr.error('Error', err);
            })
  }




  fnAddCertificate(){
    try{
      this.router.navigate(['/AddCertificate']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditCertificate(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
        }
  
      this.router.navigate(['/AddCertificate'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete certificate?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteCertificate(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteCertificate(deptid){
       var data = {
         'certificateid': deptid,            
       }
      this.appService.fnDeleteCertificate(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
              this.toastr.success('', " The certificate has been deleted successfully.");                 

              this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));

              this.fngetCertificate();
            }else{
               this.toastr.error('',res.json().err_field);   
            }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

  
  private fnSetDataTable(){
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {      
      dtInstance.destroy();      
      this.dtTrigger.next();
    });
  }


  
  fnPrintData(){
    try{
    
      this.dtElement.dtInstance.then((dtInstance:any) => {      
        dtInstance.buttons(0,0).trigger();
      });                       
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnExportData(){
    try{
    
      this.dtElement.dtInstance.then((dtInstance:any) => {      
        dtInstance.buttons(0,1).trigger();
      });                       
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }



fnGetDescription(id){
  const index = this.certificateColl.findIndex(sku => sku.certificateid === id  ); 

  if(index){
    return this.certificateColl[index].certificatename;
  }
  else{
    return "";
  }

}

fnInsertActivityLog(action,refno,desc){
try{
  var data={
    logtype:action,
    logat:this.formname,
    refnum:refno,
    logdescr:desc,
    logby:this.loginService.fnGetModUserId(),
  }


  this.appService.fnInsertActivity(data).subscribe(
    (res)=>{
    },
    (err)=>{
   
    });

}catch{

}
}


  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }

  
  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }



}
