import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addspecification',
  templateUrl: './addspecification.component.html',
  styleUrls: ['./addspecification.component.css']
})
export class AddspecificationComponent implements OnInit {

//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
specificationColl:any=[];
specificationid:number=0

specificationform=new FormGroup({
  specificationname:new FormControl('',Validators.required),         
  defaultvalue:new FormControl(''),         
  specificationid:new FormControl(0),      
 inactive:new FormControl(false),     
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="SpecificationType";



constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
     this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.specificationid = params.id
      }   
     });

     this.fnServiceChanges();
}


fnServiceChanges(){
  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

fnOnInitForm(){

   try{
       this.isaddmode=true;
       this.specificationform.reset();
       this.fnInitializeSpecificationFormGroup(); 

       if(this.specificationid>0){
         this.fngetSpecification();
       }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}


fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.specificationid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

fnInitializeSpecificationFormGroup(){
   this.specificationform.setValue({
    specificationname:'',
    defaultvalue:'',
    specificationid:0,      
     inactive:false,      
   })
}

//Get 
fngetSpecification() {        
     var data = {
       'specificationid':this.specificationid,            
     }
     this.appService.getSpecification(data)
     .subscribe(
       (res)=>{                  
         this.specificationColl=res.json().result;
         var editdata = {
           'specificationid':this.specificationColl[0].specificationid,
           'specificationname':this.specificationColl[0].specificationname, 
           'defaultvalue':this.specificationColl[0].defaultvalue, 
           'inactive':this.specificationColl[0].inactive,          
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.specificationform.valid){
         //Check
         var data = {
               'specificationid': this.specificationid,       
               'specificationname':this.specificationform.get('specificationname').value, 
               'defaultvalue':this.specificationform.get('defaultvalue').value,                           
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
         }
         this.appService.fnCheckSpecification(data).subscribe(
           (res)=>{
               var _checkresult=res.json().result[0].checkfield;
             
               if(_checkresult==="done"){
                     if (this.specificationform.get('specificationid').value>0){
                       //Update Department
                       this.fnSetSpecification(data);
                     }
                     else{
                       //Insert Department
                       this.fnInsertSpecification(data);
                     }
               }
               else{        
                     if(_checkresult==="name"){
                       this.specificationform.controls['specificationname'].setErrors({'notfound': true});
                     }
               }
         },
         (err)=>{
             this.toastr.error('Error', err);           
         });
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertSpecification(data){    
 
   this.appService.fnInsertSpecification(data).subscribe(
     (res)=>{
     if(res.json().result!=""){
       this.toastr.success('', "The new status has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.specificationname);

       this.fnCloseSpecification();
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetSpecification(data){    

this.appService.fnSetSpecification(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The Specification has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.specificationid,data.specificationname);

     this.fnCloseSpecification();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseSpecification(){
try{
  this.router.navigate(['/Specification']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){
 this.specificationform.setValue({
  specificationname:deptBO.specificationname,
  defaultvalue:deptBO.defaultvalue,
  specificationid:deptBO.specificationid,
   inactive:deptBO.inactive,   
 });

this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}

 
fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}




}
