import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
declare var $ :any;

@Component({
  selector: 'app-bannertype',
  templateUrl: './bannertype.component.html',
  styleUrls: ['./bannertype.component.css']
})
export class BannertypeComponent implements OnInit ,AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: { searching: boolean; pageLength: number; serverSide: boolean; processing: boolean; dom: string; columns: { title: string; }[]; order: (string | number)[]; scrollX: boolean; scrollCollapse: boolean; };


  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService) { }

   
  bannertypeColl:any=[];

  bannertypeid=0
  bannerColl:any=[];
  ngOnInit() {
    try{
      this.fngetBannerType();
      
 this.dtOptions = {
  searching: true,       
  pageLength: 25,
  serverSide: false,
  processing: true,
  dom:'<"top"f>rt<"bottom"ilp><"clear">',   
  columns:[  
    {title:'Action'},     
    {title:'Picture'},       
    {title:'Banner Type'},
    {title:'Banner Type Head'},    
    
  
      
  ],  
  order:[1, 'desc'],
  scrollX: true,                 
  scrollCollapse: true,
                       
};
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  


  
  fngetBannerType() {   
      var data = {
            'bannertypeid': 0,            
      }
      this.appService.getBannerType(data)
          .subscribe(
            (res)=>{                  
              if(res.json().status==200){                          
                this.bannertypeColl=res.json().result;
                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
          },
            (err)=>{          
              this.toastr.error('Error', err);
            })
  }

  fnAddBannerType(){
    try{
      this.router.navigate(['/AddBannerType']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditBannerType(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
        }
  
      this.router.navigate(['/AddBannerType'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete bannertype?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteBannerType(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteBannerType(deptid){
       var data = {
         'bannertypeid': deptid,            
       }
      this.appService.fnDeleteBannerType(data)
        .subscribe(
          (res)=>{ 
           
            this.toastr.success('', " The bannertype has been deleted successfully.");                 
              this.fngetBannerType();
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

  private fnSetDataTable(){
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {      
      dtInstance.destroy();      
      this.dtTrigger.next();
    });
  }

  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }
}
