import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';

@Component({
  selector: 'app-productfaq',
  templateUrl: './productfaq.component.html',
  styleUrls: ['./productfaq.component.css']
})
export class ProductfaqComponent implements OnInit {

  @Input() doctype: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();

  @Input() question: string = '';
  @Input() answer: string = '';
 
  
  faqsubmitted:boolean;
  faqsuccessfully:boolean;


  
  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService) { }

  ngOnInit() {
  }

   
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  fnAddProductFaq(){
    this.faqsubmitted=true;
    this.faqsuccessfully=false;
    //Validate
    if(this.question!=="" && this.answer!==""){         
        var _req={
          question:this.question,     
          answer:this.answer
        }
    
        this.emitData.emit(_req);  
        this.activeModal.close(true);  
      }


  }




}
