import { Component, OnInit } from '@angular/core';
import { PanoViewer } from "@egjs/view360";

@Component({
  selector: 'app-panoviewer',
  templateUrl: './panoviewer.component.html',
  styleUrls: ['./panoviewer.component.css']
})
export class PanoviewerComponent implements OnInit {

 
  
  constructor() {
    const panoViewer = new PanoViewer(
      document.getElementById("myPanoViewer"),
      {
        image: "../assets/images/bookcube1.jpg"
      }
    );
   }


  ngOnInit() {
  }

}
