import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'datetimeFormat'
})
export class DatetimeFormatPipe implements PipeTransform {

 
  transform(value: string) {
    //var datePipe = new DatePipe("en-US");

    var datePipe = new DatePipe("en-IN");
    if(value){
      //value = datePipe.transform(value, 'dd-MM-yyyy h:mm:ss a z', '+0400');

      value = datePipe.transform(value, 'dd-MM-yyyy h:mm:ss a', '+0200');
    }     
     return value;
 }

}
