import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addcoupon',
  templateUrl: './addcoupon.component.html',
  styleUrls: ['./addcoupon.component.css']
})
export class AddcouponComponent implements OnInit {


//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;

//set image
image;
strImage: any;

picurl:string="";
picname:string="";

coupontypeColl:any=[];
limittypeColl:any=[];
  customerColl: any = [];
  
//Edit Get Values
couponColl:any=[];
couponid:number=0

couponform=new FormGroup({
  couponcode:new FormControl('',Validators.required),         
  couponname:new FormControl('',Validators.required),         
  coupontype:new FormControl(''),
  coupontargetid:new FormControl(''),
  ispresentage:new FormControl(''),
  presentage:new FormControl(''),
  amt:new FormControl(''),
  startdt:new FormControl(null),
  enddt:new FormControl(null),
  iscustomerlimit:new FormControl(''),
  customerlimitvalue:new FormControl(''),
  limittype:new FormControl(''),
  limitvalue:new FormControl(''),
  title:new FormControl(''),
  descr:new FormControl(''),
  termandcondition:new FormControl(''),
  couponpicurl:new FormControl(''), 
  couponimage: new FormControl(null),   
  customerid: new FormControl(null),   
  couponid:new FormControl(0),       
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Coupon";

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
     this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.couponid = params.id
      }  
     });

     this.fnServiceChanges();
}


 
fnServiceChanges(){
  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

fnOnInitForm(){
   try{
       this.isaddmode=true;
       this.couponform.reset();
       this.fnInitializeCouponFormGroup(); 

       if(this.couponid>0){
         this.fngetCoupon();
       }

       this.fngetCustomer();
       this.fnGetCouponType();
       this.fnGetLimitype();

   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}



fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.couponid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

fnInitializeCouponFormGroup(){
   this.couponform.setValue({
    couponcode:'',
    couponname:'',
    coupontype:'',
    coupontargetid:'',
    ispresentage:'',
    presentage:'',
    amt:'',
    startdt:null,
    enddt:null,
    iscustomerlimit:'',
    customerlimitvalue:'',
    limittype:'',
    limitvalue:'',
    title:'',
    descr:'',
    termandcondition:'',
    couponpicurl:'',
     couponimage: null,
     customerid:null,
    couponid:0,      
   })
}


fnGetCouponType(){
  this.coupontypeColl.push({name:'TOTAL',displayname:'TOTAL'})
  this.coupontypeColl.push({name:'DEPARTMENT',displayname:'DEPARTMENT'})
  this.coupontypeColl.push({name:'CATEGORY',displayname:'CATEGORY'})
  this.coupontypeColl.push({name:'SUBCATEGORY',displayname:'SUBCATEGORY'})
  this.coupontypeColl.push({name:'BRAND',displayname:'BRAND'})
  this.coupontypeColl.push({name:'VENDOR',displayname:'VENDOR'})
}

fnGetLimitype(){
  this.limittypeColl.push({name:'UNLIMITED',displayname:'UNLIMITED'})
  this.limittypeColl.push({name:'LIMITED',displayname:'LIMITED'})
}



    
fngetCustomer() {
 
  var data = {
    'customerid': 0,
  }
  this.appService.getCustomerAdmin(data)
    .subscribe(
      (res) => {

        this.fnHideProgress();
        if (res.json().status == 200) {
          this.customerColl = res.json().result;            
        }
        else if (res.json().status == 400) {
          this.toastr.error('Error', res.json().err_field);
        }


      },
      (err) => {
        this.fnHideProgress();
        this.toastr.error('Error', err);
      })
}
  
//Get 
fngetCoupon() {        
     var data = {
       'couponid':this.couponid,            
     }
     this.appService.getCoupon(data)
     .subscribe(
       (res)=>{                  
         this.couponColl=res.json().result;
         var editdata = {
          couponcode:this.couponColl[0].couponcode,
          couponname:this.couponColl[0].couponname,
          coupontype:this.couponColl[0].coupontype,
          coupontargetid:this.couponColl[0].coupontargetid,
          ispresentage:this.couponColl[0].ispresentage,
          presentage:this.couponColl[0].presentage,
          amt:this.couponColl[0].amt,
          startdt:this.couponColl[0].startdt,
          enddt:this.couponColl[0].enddt,
          iscustomerlimit:this.couponColl[0].iscustomerlimit,
          customerlimitvalue:this.couponColl[0].customerlimitvalue,
          limittype:this.couponColl[0].limittype,
          limitvalue:this.couponColl[0].limitvalue,
          title:this.couponColl[0].title,
          descr:this.couponColl[0].descr,
          termandcondition:this.couponColl[0].termandcondition,
          couponpicurl:this.couponColl[0].couponpicurl,              
           couponid: this.couponColl[0].couponid,      
           customerid:this.couponColl[0].customerid,      
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.couponform.valid){
         //Check
         var data = {
               'couponid': this.couponid,       
               couponcode:this.couponform.get('couponcode').value,
               couponname:this.couponform.get('couponname').value,
               coupontype:this.couponform.get('coupontype').value,
               coupontargetid:this.couponform.get('coupontargetid').value,
               ispresentage:this.couponform.get('ispresentage').value,
               presentage:this.couponform.get('presentage').value,
               amt:this.couponform.get('amt').value,
               startdt:this.couponform.get('startdt').value,
               enddt:this.couponform.get('enddt').value,
               iscustomerlimit:this.couponform.get('iscustomerlimit').value,
               customerlimitvalue:this.couponform.get('customerlimitvalue').value,
               limittype:this.couponform.get('limittype').value,
               limitvalue:this.couponform.get('limitvalue').value,
               title:this.couponform.get('title').value,
               descr:this.couponform.get('descr').value,
               termandcondition: this.couponform.get('termandcondition').value,   
               customerid:this.couponform.get('customerid').value,   
               'couponimage': this.strImage,
               'couponpicurl': this.picurl,             
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
         }
         this.appService.fnCheckCoupon(data).subscribe(
           (res)=>{
               var _checkresult=res.json().result[0].checkfield;
             
               if(_checkresult==="done"){
                     if (this.couponform.get('couponid').value>0){
                       //Update Department
                       this.fnSetCoupon(data);
                     }
                     else{
                       //Insert Department
                       this.fnInsertCoupon(data);
                     }
               }
               else{        
                     if(_checkresult==="name"){
                       this.couponform.controls['couponcode'].setErrors({'notfound': true});
                     }
               }
         },
         (err)=>{
             this.toastr.error('Error', err);           
         });
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertCoupon(data){    
 
   this.appService.fnInsertCoupon(data).subscribe(
     (res)=>{
       console.log(res.json())
     if(res.json().result!=""){     
       this.toastr.success('', "The new coupon has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.couponname);

       this.fnCloseCoupon();
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetCoupon(data){    

this.appService.fnSetCoupon(data).subscribe(
 (res)=>{
 if(res.json().result!=""){  
     this.toastr.success('', "The coupon has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.couponid,data.couponname);

     this.fnCloseCoupon();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseCoupon(){
try{
  this.router.navigate(['/Coupon']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){

 this.couponform.setValue({
  couponcode:deptBO.couponcode,
  couponname:deptBO.couponname,
  coupontype:deptBO.coupontype,
  coupontargetid:deptBO.coupontargetid,
  ispresentage:deptBO.ispresentage,
  presentage:deptBO.presentage,
  amt:deptBO.amt,
  startdt:deptBO.startdt,
  enddt:deptBO.enddt,
  iscustomerlimit:deptBO.iscustomerlimit,
  customerlimitvalue:deptBO.customerlimitvalue,
  limittype:deptBO.limittype,
  limitvalue:deptBO.limitvalue,
  title:deptBO.title,
  descr:deptBO.descr,
  termandcondition:deptBO.termandcondition,  
  couponid:deptBO.couponid,  
   couponpicurl: deptBO.couponpicurl,  
   customerid:deptBO.customerid,
  couponimage:null,     
 });

 this.picurl =deptBO.couponpicurl;   

this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}


//Image Import
changeListener($event): void {
  try{
     this.readThis($event.target);
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
  }
  
  readThis(inputValue: any): void {
  
   var file: File = inputValue.files[0];
   var myReader: FileReader = new FileReader();
  
   myReader.onloadend = (e) => {
       this.image = myReader.result;
       this.strImage = this.image.split(',')[1];
   }
   myReader.readAsDataURL(file);
  }
  
  removeimage(){
   this.image =null;
   this.strImage =null;
   this.picurl="";
  }
  
  
  fnOpenFileBrowser(event:any){
  
    event.preventDefault();
  
    let element:HTMLElement=document.getElementById('image') as HTMLElement;
    element.click();
  
  }

      
 fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}



  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }

  

private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}




}
