import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addwallet',
  templateUrl: './addwallet.component.html',
  styleUrls: ['./addwallet.component.css']
})
export class AddwalletComponent implements OnInit {


//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;



//Edit Get Values
walletColl:any=[];
walletid:number=0
customerid:number=0


walletform=new FormGroup({         
  transdate:new FormControl('',Validators.required),           
  transtype:new FormControl('',Validators.required), 
  refnum:new FormControl(''), 
  description:new FormControl(''), 
  cramt:new FormControl(0), 
  dramt:new FormControl(0), 
  inactive:new FormControl(false),        
  walletid:new FormControl(0),
});


isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Wallet";

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private datePipe: DatePipe,private loginService:LoginDataService) { 
     this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.walletid = params.id
      }  
      if(params.cid!==undefined){
        this.customerid = params.cid
      }  

     
      
     });

     this.fnServiceChanges();
}


fnServiceChanges(){
  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

fnOnInitForm(){
   try{
       this.isaddmode=true;
       this.walletform.reset();
       this.fnInitializewalletformGroup(); 

       if(this.walletid>0){
         this.fngetWallet();
       }
       

      
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}




fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.walletid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}     


fnInitializewalletformGroup(){
   this.walletform.setValue({
    transdate:'',           
    transtype:'', 
    refnum:'', 
    description:'', 
    cramt:0, 
    dramt:0, 
    inactive:false,
    walletid:0             
   })
}




//Get 
fngetWallet() {        
     var data = {
       'walletid':this.walletid,            
     }
     this.appService.getWalletAdmin(data)
     .subscribe(
       (res)=>{               
       
         this.walletColl=res.json().result;
      
         this.fnEditMode(this.walletColl[0]);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}




//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.walletform.valid){
         //Check
         var data = {
          transdate:this.fnFormatDatetime(this.walletform.get('transdate').value),            
          transtype:this.walletform.get('transtype').value, 
          refnum:this.walletform.get('refnum').value, 
          description:this.walletform.get('description').value, 
          cramt:this.walletform.get('cramt').value, 
          dramt:this.walletform.get('dramt').value, 
          inactive:this.walletform.get('inactive').value, 
          walletid:this.walletform.get('walletid').value, 
          customerid:this.customerid,
          createdby:this.loginService.fnGetModUserId(),
          modifiedby:this.loginService.fnGetModUserId()                               
         }

         if (this.walletform.get('walletid').value>0){
          this.fnSetWallet(data);
         }
         else{
          this.fnInsertWallet(data);
         }
         
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}
//Insert
fnInsertWallet(data){    

  this.appService.fnInsertWalletAdmin(data).subscribe(
   (res)=>{
   if(res.json().result!=""){
       this.toastr.success('', "The Wallet has been updated successfully.");

       this.fnInsertActivityLog('INSERT',this.customerid,data.refnum);

       this.fnCloseWallet();        
   }},
   (err)=>{
     this.toastr.error('Error', err);
   });
  
  }

  

//Set
fnSetWallet(data){    

this.appService.fnSetWalletAdmin(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The Wallet has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.customerid,data.walletid+"--"+data.refnum);

     this.fnCloseWallet();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseWallet(){
try{
  let navigationExtras: NavigationExtras = {
    queryParams: {
      'id': this.customerid,
    }
    }

  this.router.navigate(['/CustomerView'], navigationExtras);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){

  deptBO.transdate= this.fnConvertDatetime(this.fnFormatDatetime(deptBO.transdate));

 this.walletform.setValue({
  transdate:deptBO.transdate,           
  transtype:deptBO.transtype, 
  refnum:deptBO.refnum,
  description:deptBO.description, 
  cramt:deptBO.cramt,
  dramt:deptBO.dramt,
  inactive:deptBO.inactive,
  walletid:deptBO.walletid,      
 });


this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}


fnFormatDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      return this.datePipe.transform(dt,"yyyy-MM-dd")  
    }
    
    }else {
    return null
  }
  
}

fnConvertDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      

      var dateParts = dt.split("-"); 

       var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 

       sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());

       
       
       return sdate;
    }
    
    }else {
    return null
  }
 
}



fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}



private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}



private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}






}
