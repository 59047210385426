import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportgridComponent } from '../grid/exportgrid/exportgrid.component';
import { AddsettingsComponent } from './addsettings/addsettings.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, AfterViewInit, OnDestroy {

  settingsColl: any = [];

  settingsgroupColl: any = [];
  moduleBO: any = {};
  
  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Settings";

  editformtype: String = ""

  searchtxt: any = "";
  columnDefs = [
    { headerName: 'Settings Type', field: 'settingstype', },
    { headerName: 'Settings Name', field: 'settingsname', },
    { headerName: 'Settings Value', field: 'settingsvalue', },
  ]
  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
    pageindex: 1
  };




  constructor(private appService: DhukanDataService, public router: Router,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService,
    private loginService: LoginDataService, private productFindService: NgbModal) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {


      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetSettings();
  }

  fnCheckPrivilage() {
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  fngetSettings() {
    this.fnShowProgress();
    var data = {
      'settingsid': 0,
    }
    this.appService.getSettings(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.settingsColl = res.json().result[0];
            this.settingsgroupColl = res.json().result[1];



            for (let abo of this.settingsColl) {
              // if (abo.mode === 'LOOKUP') {
              //   abo.settingsvalueoptionslookup = abo.settingsvalueoptions.replace(/ /g, '').split(',')
              // }
              if (abo.mode === 'TEXTBOX') {
                abo.settingsvalue = abo.settingsvalue
              }
              if (abo.mode === 'CHECKBOX') {

                if (abo.settingsvalue === '1') {
                  abo.settingsvalue = true;
                }
                else {
                  abo.settingsvalue = false;
                }
              }
              if (abo.mode === 'MASTERLOOKUP') {
                if (Number(abo.settingsvalue) > 0) {
                  abo.settingsvalue = Number(abo.settingsvalue)
                } else {
                  abo.settingsvalue = null
                }

              }
            }


            if (this.settingsgroupColl.length > 0) {

              this.fnTabClickModule(this.settingsgroupColl[0])
            }



          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  //Grid

  fnTabClickModule(item) {

    this.editformtype = item.settingstype;

    for (let abo of this.settingsgroupColl) {

      if (abo.settingstype === item.settingstype) {

        abo.activeCls = "activetab"


        this.moduleBO = abo

      }
      else {
        abo.activeCls = ""
      }
    }



  }

  

  fnShowEditSettings(data){
    
    const modalRef = this.productFindService.open(AddsettingsComponent ,{ size: <any>'l'  });
    modalRef.componentInstance.doctype ="Product Filter" ;
    
    modalRef.componentInstance.settingsid = data.settingsid;

    
  
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      this.fngetSettings();
    })
  
  }

  


  fnActionExportClick() {
    const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xl', backdrop: 'static' });
    modalRef.componentInstance.doctype = this.formname;
    modalRef.componentInstance.columnDefs = this.columnDefs;
    modalRef.componentInstance.contentColl = this.settingsColl;
    modalRef.componentInstance.fnSetTable();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


    });
  }



  fnEditSettings(deptid) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
      }

      this.router.navigate(['/AddSettings'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  private fnSetDataTable() {

  }
  fnResetGrid(data) {
    this.searchtxt = data;
    this.gridOptions.pageindex = 1
  }
  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }




}
