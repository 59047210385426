import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-productimageview',
  templateUrl: './productimageview.component.html',
  styleUrls: ['./productimageview.component.css']
})
export class ProductimageviewComponent implements OnInit {


  @Input() doctype: string;

  @Output() emitData = new EventEmitter();


  @Input() picurl: string = '';
  @Input() image: any;

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,private loginService:LoginDataService) { }

  ngOnInit() {
   
  }
  
  
  
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


}
