import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addreason',
  templateUrl: './addreason.component.html',
  styleUrls: ['./addreason.component.css']
})
export class AddreasonComponent implements OnInit {



//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
reasonColl:any=[];
reasonid:number=0

reasonform=new FormGroup({         
  reason:new FormControl('',Validators.required),             
  reasonid:new FormControl(0),      
  isshowtocustomer:new FormControl(false), 
  inactive:new FormControl(false),     
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Reason";

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
     this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.reasonid = params.id
      }  
     });
     this.fnServiceChanges();
}


fnServiceChanges(){
  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

fnOnInitForm(){
   try{
       this.isaddmode=true;
       this.reasonform.reset();
       this.fnInitializereasonformGroup(); 

       if(this.reasonid>0){
         this.fngetReason();
       }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}



fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.reasonid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}


fnInitializereasonformGroup(){
   this.reasonform.setValue({
    reason:'',    
     reasonid: 0, 
     isshowtocustomer:false,
    inactive:false,      
   })
}






//Get 
fngetReason() {        
     var data = {
       'reasonid':this.reasonid,            
     }
     this.appService.getReason(data)
     .subscribe(
       (res)=>{                  
         this.reasonColl=res.json().result;
         var editdata = {
           'reasonid':this.reasonColl[0].reasonid,
           'reason': this.reasonColl[0].reason, 
           'isshowtocustomer': this.reasonColl[0].isshowtocustomer, 
           'inactive':this.reasonColl[0].inactive,          
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.reasonform.valid){
         //Check
         var data = {
               'reasonid': this.reasonid,                                
                'reason': this.reasonform.get('reason').value,
               'isshowtocustomer': this.reasonform.get('isshowtocustomer').value,
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
         }
         this.appService.fnCheckReason(data).subscribe(
           (res)=>{
               var _checkresult=res.json().result[0].checkfield;
             
               if(_checkresult==="done"){
                     if (this.reasonform.get('reasonid').value>0){
                       //Update Department
                       this.fnSetReason(data);
                     }
                     else{
                       //Insert Department
                       this.fnInsertReason(data);
                     }
               }
               else{        
                     if(_checkresult==="name"){
                       this.reasonform.controls['paytype'].setErrors({'notfound': true});
                     }
               }
         },
         (err)=>{
             this.toastr.error('Error', err);           
         });
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertReason(data){    
 
   this.appService.fnInsertReason(data).subscribe(
     (res)=>{
     if(res.json().result!=""){
       this.toastr.success('', "The new reason has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.reason);

       this.fnCloseReason();
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetReason(data){    

this.appService.fnSetReason(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The reason has been updated successfully.");

    this.fnInsertActivityLog('EDIT',this.reasonid,data.reason);

     this.fnCloseReason();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseReason(){
try{
  this.router.navigate(['/Reason']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){

 this.reasonform.setValue({      
      reason:deptBO.reason,      
      reasonid: deptBO.reasonid,
      isshowtocustomer:deptBO.isshowtocustomer,
      inactive:deptBO.inactive,   
 });


this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}



fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}

private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}





}
