import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-orderoption',
  templateUrl: './orderoption.component.html',
  styleUrls: ['./orderoption.component.css']
})
export class OrderoptionComponent implements OnInit {
  @Input() doctype: string;
  @Input() docname: string;
  @Input() mode: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  reasonColl:any=[];

  @Input() productBO: any = {};
  @Input() orderNo: string = '';

  @Input() cancelreasonid: number = null;
  @Input() canceldesc: string = '';
  @Input() orderid: number = null;
  
  @Input() editorValue: string = '';
  @Input() orderBO: any = {};

  //SHIPMENT
  @Input() deliveryorderBO: any = {};
  @Input() isdeleteshipment: boolean = false;
  @Input() isorderreadytopick: boolean = false;
  @Input() isorderassign: boolean = false;
  @Input() isorderpick: boolean = false;
  @Input() isorderdeliver: boolean = false;
  @Input() isorderreceived: boolean = false;

  ordersubmitted:boolean;
  ordersuccessfully:boolean;

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,private loginService:LoginDataService) { }

  ngOnInit() {
  
  }
   
  
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

    
  fnSetOption(type){

    this.emitData.emit(type);  
    this.activeModal.close(true); 

  }


}
