import { AfterViewInit, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ImageformatterComponent } from '../../grid/imageformatter/imageformatter.component';

@Component({
  selector: 'app-offerproduct',
  templateUrl: './offerproduct.component.html',
  styleUrls: ['./offerproduct.component.css']
})
export class OfferproductComponent implements OnInit, AfterViewInit, OnDestroy {


  @Input() doctype: string;
  @Input() req: {};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();

  @Input() productColl:  any = [];

  constructor(private activeModal: NgbActiveModal, private appService: DhukanDataService, private toastr: ToastrService) { }

  


  showProgress: boolean = false;


  private gridApi;
  private gridColumnApi;

  columnDefs = [
    { headerName: 'Pic', field: 'picurl', width: 100, sortable: false, cellRendererFramework: ImageformatterComponent, pinned: 'left' },
    { headerName: 'Sku', field: 'sku', width: 110, minWidth: 110, sortable: true, filter: true, pinned: 'left' },//,pinned: 'left'    
    { headerName: 'Name', field: 'productname', width: 260, minWidth: 260, sortable: true, filter: true, pinned: 'left' },
    { headerName: 'Department', field: 'deptname', width: 120, minWidth: 120, sortable: true, filter: true },
    { headerName: 'Category', field: 'categoryname', width: 120, minWidth: 120, sortable: true, filter: true },
    { headerName: 'SubCategory', field: 'subcategoryname', width: 150, minWidth: 150, sortable: true, filter: true },
    { headerName: 'Brand', field: 'brandname', width: 150, minWidth: 150, sortable: true, filter: true },
    { headerName: 'MRP', field: 'mrp', sortable: true, filter: true },
    { headerName: 'Price', field: 'sellingprice', sortable: true, filter: true },
    { headerName: 'Stock', field: 'stock', sortable: true, filter: true },
    { headerName: 'Published', field: 'published', sortable: true, filter: true },
    { headerName: 'Status', field: 'activestatus', sortable: true, filter: true },
  ]

  gridOptions = {
    rowSelection: 'multiple',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 2,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
    sideBar: 'columns',

  };


  ngOnInit() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

  }



  onSelectionChanged(event) {
    // 

  }


  ngAfterViewInit(): void {
    //  this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // this.dtTrigger.unsubscribe();
  }


  public decline() {
    this.activeModal.close(false);
  }


  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }



  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }


}
