import { DatePipe } from '@angular/common';
import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-paidorder',
  templateUrl: './paidorder.component.html',
  styleUrls: ['./paidorder.component.css']
})
export class PaidorderComponent implements OnInit {

  @Input() doctype: string;

  @Output() emitData = new EventEmitter();

  @Input() orderNo: string = '';

  @Input() editorValue: string = '';

  @Input() orderid: number = 0;
  @Input() ordermainBo: any = {}

  @Input() cardtypeid: number = 0;
  @Input() payrefno: string = '';
  @Input() paydesc: string = '';
  @Input() cardpayamt: any = null;

  @Input() cardtypeid2: number = 0;
  @Input() payrefno2: string = '';
  @Input() paydesc2: string = '';
  @Input() cardpayamt2: any = null;


  @Input() cardtypeid3: number = 0;
  @Input() payrefno3: string = '';
  @Input() paydesc3: string = '';
  @Input() cardpayamt3: any = null;

  @Input() cardtypeid4: number = null;
  @Input() payrefno4: string = '';
  @Input() paydesc4: string = '';
  @Input() cardpayamt4: any = null;


  @Input() cardtypeid5: number = 0;
  @Input() payrefno5: string = '';
  @Input() paydesc5: string = '';
  @Input() cardpayamt5: any = null;

  @Input() totalpaid: any = null;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  paidform = new FormGroup({
    cardtypeid: new FormControl(null, Validators.required),
    payrefno: new FormControl(''),
    paydesc: new FormControl(null),
    cardpayamt: new FormControl(null),
    cardtypeid2: new FormControl(null),
    payrefno2: new FormControl(''),
    paydesc2: new FormControl(null),
    cardpayamt2: new FormControl(null),
    cardtypeid3: new FormControl(null),
    payrefno3: new FormControl(''),
    paydesc3: new FormControl(null),
    cardpayamt3: new FormControl(null),
    cardtypeid4: new FormControl(null),
    payrefno4: new FormControl(''),
    paydesc4: new FormControl(null),
    cardpayamt4: new FormControl(null),

    cardtypeid5: new FormControl(null),
    payrefno5: new FormControl(''),
    paydesc5: new FormControl(null),
    cardpayamt5: new FormControl(null),
  })

  cardtypeColl: any = [];

  ordersubmitted: boolean;
  ordersuccessfully: boolean;

  constructor(private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService,
    private appService: DhukanDataService, private toastr: ToastrService, private productFindService: NgbModal, private loginService: LoginDataService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.fnInitilize();
    this.fngetCardType();

  }

  fnInitilize() {
    this.paidform.setValue({
      cardtypeid: this.cardtypeid,
      payrefno: this.payrefno,
      paydesc: this.paydesc,
      cardpayamt: this.cardpayamt,
      cardtypeid2: this.cardtypeid2,
      payrefno2: this.payrefno2,
      paydesc2: this.paydesc2,
      cardpayamt2: this.cardpayamt2,
      cardtypeid3: this.cardtypeid3,
      payrefno3: this.payrefno3,
      paydesc3: this.paydesc3,
      cardpayamt3: this.cardpayamt3,
      cardtypeid4: this.cardtypeid4,
      payrefno4: this.payrefno4,
      paydesc4: this.paydesc4,
      cardpayamt4: this.cardpayamt4,

      cardtypeid5: this.cardtypeid5,
      payrefno5: this.payrefno5,
      paydesc5: this.paydesc5,
      cardpayamt5: this.cardpayamt5,
    })

    this.fnCalculateTotal()
  }


  fngetCardType() {

    var data = {
      'cardtypeid': 0,
    }
    this.appService.getCardType(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            this.cardtypeColl = res.json().result;

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {

          this.toastr.error('Error', err);
        })
  }


  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  fnSetOrder() {

    this.submitted = true;
    this.successfully = false;

    if (this.paidform.valid) {

      if (this.aaNumber( this.ordermainBo.grandtotal) !== this.aaNumber(this.totalpaid)) {
        this.toastr.error("Validate", "Payment Amount Not Equal",{timeOut:3000})
        return
      }


      var ressdata = {
        'orderid': this.orderid,
        'payrefno': this.paidform.get("payrefno").value,
        'cardtypeid': this.paidform.get("cardtypeid").value,
        'paydesc': this.paidform.get('paydesc').value,
        'cardpayamt': this.paidform.get('cardpayamt').value,

        'cardtypeid2': this.paidform.get("cardtypeid2").value,
        'payrefno2': this.paidform.get("payrefno2").value,
        'paydesc2': this.paidform.get('paydesc2').value,
        'cardpayamt2': this.paidform.get('cardpayamt2').value,

        'cardtypeid3': this.paidform.get("cardtypeid3").value,
        'payrefno3': this.paidform.get("payrefno3").value,
        'paydesc3': this.paidform.get('paydesc3').value,
        'cardpayamt3': this.paidform.get('cardpayamt3').value,

        'cardtypeid4': this.paidform.get("cardtypeid4").value,
        'payrefno4': this.paidform.get("payrefno4").value,
        'paydesc4': this.paidform.get('paydesc4').value,
        'cardpayamt4': this.paidform.get('cardpayamt4').value,

        'cardtypeid5': this.paidform.get("cardtypeid5").value,
        'payrefno5': this.paidform.get("payrefno5").value,
        'paydesc5': this.paidform.get('paydesc5').value,
        'cardpayamt5': this.paidform.get('cardpayamt5').value,
      }

      this.emitData.emit(ressdata);
      this.activeModal.close(true);
    }



  }


  fnRemovePayment() {
    var ressdata = {
      'orderid': this.orderid,
      'payrefno':null,
      'cardtypeid':null,
      'paydesc':null,
      'cardpayamt': null,

      'cardtypeid2': null,
      'payrefno2': null,
      'paydesc2':null,
      'cardpayamt2':null,

      'cardtypeid3':null,
      'payrefno3': null,
      'paydesc3': null,
      'cardpayamt3': null,

      'cardtypeid4':null,
      'payrefno4': null,
      'paydesc4': null,
      'cardpayamt4': null,

      'cardtypeid5':null,
      'payrefno5': null,
      'paydesc5': null,
      'cardpayamt5': null,
    }

    this.emitData.emit(ressdata);
    this.activeModal.close(true);

  }
  fnCalculateTotal() {
    this.totalpaid = 0;
    let cardpayamt = this.paidform.get('cardpayamt').value;
    let cardpayamt2 = this.paidform.get('cardpayamt2').value;
    let cardpayamt3 = this.paidform.get('cardpayamt3').value;
    let cardpayamt4 = this.paidform.get('cardpayamt4').value;
    let cardpayamt5 = this.paidform.get('cardpayamt5').value;

    let cardtypeid = this.paidform.get("cardtypeid").value;
    let cardtypeid2 = this.paidform.get("cardtypeid2").value;
    let cardtypeid3 = this.paidform.get("cardtypeid3").value;
    let cardtypeid4 = this.paidform.get("cardtypeid4").value;
    let cardtypeid5 = this.paidform.get("cardtypeid5").value;

    if (cardtypeid) {
      this.totalpaid = this.aaNumber(this.totalpaid) + this.aaNumber(cardpayamt)
    }
    if (cardtypeid2) {
      this.totalpaid = this.aaNumber(this.totalpaid) + this.aaNumber(cardpayamt2)
    }

    if (cardtypeid3) {
      this.totalpaid = this.aaNumber(this.totalpaid) + this.aaNumber(cardpayamt3)
    }

    if (cardtypeid4) {
      this.totalpaid = this.aaNumber(this.totalpaid) + this.aaNumber(cardpayamt4)
    }

    if (cardtypeid5) {
      this.totalpaid = this.aaNumber(this.totalpaid) + this.aaNumber(cardpayamt5)
    }

  }

  aaNumber(total) {

    if (isNaN(total) || total === undefined || total === null) {
      return Number(0)
    }
    else {
      return Number.parseFloat(Number(total).toFixed(2))
    }
  }

  fnInsertActivityLog(action, refno, desc, doid) {
    try {
      var data = {
        logtype: action,
        logat: "ORDER",
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
        orderid: this.orderid,
        deliveryorderid: doid
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }



  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }

  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {


        var dateParts = dt.split("-");

        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);

        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());



        return sdate;
      }

    } else {
      return null
    }

  }





}
