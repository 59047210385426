import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportgridComponent } from '../grid/exportgrid/exportgrid.component';
declare var $ :any;

@Component({
  selector: 'app-taxgroup',
  templateUrl: './taxgroup.component.html',
  styleUrls: ['./taxgroup.component.css']
})
export class TaxgroupComponent implements OnInit ,AfterViewInit, OnDestroy {
 

  taxgroupColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="TaxGroup";


  searchtxt: any = "";
  columnDefs = [
    {  headerName: 'TaxGroup', field: 'taxgrpname'  },
    { headerName: 'Rate', field: 'taxrate' },
    { headerName: 'CGST%', field: 'cgsttaxrate' },
    { headerName: 'SGST%', field: 'sgsttaxrate' },
    { headerName: 'IGST%', field: 'igsttaxrate' },
  ]
  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
    pageindex: 1
  };

  



  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,
    private loginService:LoginDataService, private productFindService: NgbModal) {   this.fnServiceChanges(); }

   //User Check
   fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){                
              this.fnCheckPrivilage();
              if(this.isview){
                this.isloading=true;
                this.fnOnInitForm();
              }
              else{
                this.fnUserFailedForm();
              }    
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }


  ngOnInit() {
    try{
     
     
      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetTaxGroup();     
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }
  
  ngAfterViewInit(): void {
  
  }

  ngOnDestroy(): void {
    
  }

  fngetTaxGroup() {
    if(this.showProgress){
      return;
    }
       
    this.fnShowProgress();
      var data = {
            'taxgroupid': 0,            
      }
      this.appService.getTaxGroup(data)
          .subscribe(
            (res)=>{      
              this.fnHideProgress();            
              if(res.json().status==200){                          
                this.taxgroupColl=res.json().result;
                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
             
          },
            (err)=>{          
              this.fnHideProgress();
              this.toastr.error('Error', err);
            })
  }

  //Grid



  fnActionEditClick(data) {


    if (!this.isedit) {
      return;
    }
    this.fnEditTaxGroup(data.taxgrpid);
   
  }

  fnActionDeleteClick(data) {
  
    if (!this.isdelete) {
      return;
    }

    this.fnDeleteConfirm(data.taxgrpid);
   
  }

  fnActionExportClick() {
    const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xl', backdrop: 'static' });
    modalRef.componentInstance.doctype = this.formname;
    modalRef.componentInstance.columnDefs = this.columnDefs;
    modalRef.componentInstance.contentColl = this.taxgroupColl;
    modalRef.componentInstance.fnSetTable();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
  
    
    });
  }



  fnAddTaxGroup(){
    try{
      this.router.navigate(['/AddTaxGroup']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditTaxGroup(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
        }
  
      this.router.navigate(['/AddTaxGroup'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete TaxGroup?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteTaxGroup(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteTaxGroup(deptid){
       var data = {
         'taxgroupid': deptid,            
       }
      this.appService.fnDeleteTaxGroup(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
                this.toastr.success('', " The TaxGroup has been deleted successfully.");                 

                this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));

                this.fngetTaxGroup();
            }else{
              this.toastr.error('',res.json().err_field);   
            }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

  private fnSetDataTable(){
  
  }
  fnResetGrid(data) {
    this.searchtxt = data;
    this.gridOptions.pageindex = 1
  }

  
      
  fnPrintData(){
     
  }

  fnExportData(){
    
  }
  
  
  


  fnGetDescription(id){
    const index = this.taxgroupColl.findIndex(sku => sku.taxgrpid === id  ); 

    if(index){
      return this.taxgroupColl[index].taxgrpname;
    }
    else{
      return "";
    }

}
  
fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }



}
