import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-zoomproduct',
  templateUrl: './zoomproduct.component.html',
  styleUrls: ['./zoomproduct.component.css']
})
export class ZoomproductComponent implements OnInit {
  
  @Input() doctype: string;
  @Input() mode: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();


  @Input() editorValue: string = '';
 

  @Input() skuid: number = 0;
  productBO:any={};


  @Input() picurl: string = '';
  

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,private loginService:LoginDataService) { }

  ngOnInit() {
   
  }
  
  
  
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  public fnGetProductInfo(){
   

      var data = {
        'productid':this.skuid,                                  
      }


      this.appService.getProductByListView(data)
      .subscribe(
        (res)=>{     
          let productColl=res.json().result;         

          if(productColl.length>0){
            this.productBO=productColl[0]
          }
    
        },
      (err)=>{          
        //this.toastr.error('Error', err);
      })
  

  }

}
