import { DatePipe } from '@angular/common';
import { EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { EditdeliverydateComponent } from '../editdeliverydate/editdeliverydate.component';

@Component({
  selector: 'app-confirmorder',
  templateUrl: './confirmorder.component.html',
  styleUrls: ['./confirmorder.component.css']
})
export class ConfirmorderComponent implements OnInit {
  submitted: boolean = false;

  @Input() doctype: string;
 
  @Output() emitData = new EventEmitter();

  @Input() orderNo: string = '';

  @Input() expectdeliverydate: Date=null;
  @Input() expectdeliverytime: any= null;
  @Input() deliverytypeid: number = null;
  @Input() branchid: number = null;
  
  @Input() editorValue: string = '';

  @Input() orderid:number=0;
  @Input() ordermainBo:any={}

  orderconfirmform = new FormGroup({
    deliverytypeid: new FormControl(null),
    expectdeliverydate: new FormControl(null, Validators.required),
    expectdeliverytime: new FormControl(null, Validators.required),
   
  })
  ordersubmitted:boolean;
  ordersuccessfully:boolean;

  constructor(private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService,
    private appService: DhukanDataService, private toastr: ToastrService, private productFindService: NgbModal,private loginService: LoginDataService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.fnInitilize();
       
  }
  
  fnInitilize() {
    this.orderconfirmform.setValue({
      deliverytypeid: this.deliverytypeid,
      expectdeliverydate: this.expectdeliverydate,
      expectdeliverytime: this.expectdeliverytime,
    
    })
  }  

  
 

  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  fnSetOrder(){
    this.submitted = true;
    if (this.orderconfirmform.valid) {

      var _currentdt = new Date();
      var _deliverydt = this.orderconfirmform.get('expectdeliverydate').value;

      var regex = new RegExp(':', 'g');
      var _currenttime = new Date().getHours().toString() + ":" + new Date().getMinutes();
      var _deliverytime = this.orderconfirmform.get('expectdeliverytime').value;


      console.log(this.fnFormatDatetime(_deliverydt))
      console.log(this.fnFormatDatetime(_currentdt.toDateString()))


      if (Date.parse(_deliverydt) < Date.parse(_currentdt.toDateString())) {
      
        this.orderconfirmform.controls['expectdeliverydate'].setErrors({ 'notfound': true });
        return
      }
      else if (this.fnFormatDatetime(_deliverydt) === this.fnFormatDatetime(_currentdt.toDateString()) && parseInt(_deliverytime.replace(regex, ''), 10) < parseInt(_currenttime.replace(regex, ''), 10)) {
        this.orderconfirmform.controls['expectdeliverytime'].setErrors({ 'notfound': true });
        return
      }

      this.fnConfirmStatus();
    }

  


  }

  

  fnConfirmStatus() {
    var data = {
      'orderid': this.orderid,
      'status': 'CONFIRMED',
      'deliverytypeid': this.orderconfirmform.get('deliverytypeid').value,
      'expectdeliverydate': this.fnFormatDatetime(this.orderconfirmform.get('expectdeliverydate').value),
      'expectdeliverytime': this.orderconfirmform.get('expectdeliverytime').value,
      'modifiedby': this.loginService.fnGetModUserId()
    }

    if (this.orderid > 0) {
      //Update Department
      this.appService.fnSetOrderConfirm(data).subscribe(
        (res) => {

          if (res.json().result != "") {
            var _result = res.json().result[0];
            var _checkresult = "";

            if (_result.length) {
              _checkresult = _result[0].checkresult;

              if (_checkresult === 'DONE') {
                var resdaata = {
                  'status': "DONE",
                
                }
                this.emitData.emit(resdaata);  
                this.activeModal.close(true); 
              }
              else {
                this.toastr.warning('', "Already confirmed this order.");
                var resdaata = {
                  'status': "EXIST",                  
                }
                this.emitData.emit(resdaata);  
                this.activeModal.close(true); 
              }

            }


          }
        },
        (err) => {
          this.toastr.error('Error', err);
        });

    }

  }

  
  //Change Delivery Date Time Order 
  fnChangeDeliveryDt() {


    const modalRef = this.productFindService.open(EditdeliverydateComponent, { size: <any>'l' });

    modalRef.componentInstance.orderid = this.ordermainBo.orderid;
    modalRef.componentInstance.expectdeliverydate = this.fnConvertDatetime(this.fnFormatDatetime(this.ordermainBo.expectdeliverydate));
    modalRef.componentInstance.expectdeliverytime = this.ordermainBo.expectdeliverytime;


    console.log(this.ordermainBo.expectdeliverydate + " " + this.ordermainBo.expectdeliverytime)

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fnInsertActivityLog('CHANGEDELIVERYDT', this.ordermainBo.orderno, "", 0);

      window.location.reload()

    });

  }
  
  fnInsertActivityLog(action, refno, desc, doid) {
    try {
      var data = {
        logtype: action,
        logat: "ORDER",
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
        orderid: this.orderid,
        deliveryorderid: doid
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }



  fnFormatDatetime(dt){
    if(dt){
      if(dt===""){
        return null
      }
      else{
        return this.datePipe.transform(dt,"yyyy-MM-dd")  
      }
      
      }else {
      return null
    }
    
  }
  
  fnConvertDatetime(dt){
    if(dt){
      if(dt===""){
        return null
      }
      else{
        
  
        var dateParts = dt.split("-"); 
  
         var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 
  
         sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
  
         
         
         return sdate;
      }
      
      }else {
      return null
    }
   
  }

  




}
