import { Component, OnInit, ɵCompiler_compileModuleAsync__POST_R3__, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray, NgForm } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router, NavigationExtras, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductfindComponent } from '../../productfind/productfind.component';
import { nextTick } from 'q';
import { ProductspecificationComponent } from '../../productspecification/productspecification.component';
import { ProductfaqComponent } from '../../productfaq/productfaq.component';
import {NgxImageCompressService} from 'ngx-image-compress';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { EditproductmainComponent } from '../editproductmain/editproductmain.component';
import { EditproductskuComponent } from '../editproductsku/editproductsku.component';
import { AdduomComponent } from '../../uom/adduom/adduom.component';
import { AdduompopComponent } from '../../uom/adduompop/adduompop.component';
import { AdddepartmentpopComponent } from '../../department/adddepartmentpop/adddepartmentpop.component';
import { AddcategorypopComponent } from '../../category/addcategorypop/addcategorypop.component';
import { AddbrandpopComponent } from '../../brand/addbrandpop/addbrandpop.component';
import { Subscription } from 'rxjs';
import { ProductimageviewComponent } from '../productimageview/productimageview.component';

@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['./addproduct.component.css']
})
export class AddproductComponent implements OnInit {

  @ViewChild('skuForm', { static: false }) skuForm: NgForm;
  @ViewChild('skuEditForm', { static: false }) skuEditForm: NgForm;

  //@ViewChild('tabset',{static:false}) tabset;

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;
  faqsubmitted: boolean;
  faqsuccessfully: boolean;

  isadduom: boolean = false;
  isadddepartment: boolean = false;
  isaddcategory: boolean = false;
  isaddbrand: boolean = false;


  //set image
  image;
  strImage: any;

  //Edit Get Values
  productBO: any = {}
  productColl: any = [];
  productskuColl: any = [];
  productskupriceColl: any = [];
  productskuimageColl: any = [];
  productid: number = 0

  //Master
  deptColl: any = [];
  categoryColl: any = [];
  subcategoryColl: any = [];
  brandColl: any = [];
  warehouseColl: any = [];
  taxgroupColl: any = [];
  vendorColl: any = [];
  uomColl: any = [];
  certificateColl: any = [];
  foodsymbolColl: any = [];
  itemtypeColl: any = [];
  productgroupColl: any = [];

  loopindex: number = 0;

  warehouseid: number = 0;
  deptid: number = 0;
  categoryid: number = null;
  subcategoryid: number = null;
  brandid: number = null;
  published: string = "";
  inactive: string = "";
  sku: string = "";
  productname: string = "";
  viewmode: string = "";

  taxrate: number = 0;

  productform = new FormGroup({
    productid: new FormControl(0),
    productcode: new FormControl('', Validators.required),
    title: new FormControl(''),
    deptid: new FormControl(0, Validators.required),
    categoryid: new FormControl(0, Validators.required),
    subcategoryid: new FormControl(0),
    brandid: new FormControl(0, Validators.required),
    vendorid: new FormControl(0),
    taxgrpid: new FormControl(0, Validators.required),
    foodsymbolid: new FormControl(0),
    certificateid: new FormControl(0),
    tags: new FormControl(0),
    sku: new FormControl('', Validators.required),
    autosku: new FormControl(''),
    productname: new FormControl('', Validators.required),
    shortdescr: new FormControl(''),
    fulldescr: new FormControl(''),
    uomqty: new FormControl('', Validators.required),
    uomid: new FormControl(0, Validators.required),
    mrp: new FormControl(0),
    sellingprice: new FormControl(0),
    currentstock: new FormControl(0),
    ispublished: new FormControl(false),
    cost: new FormControl(0),
    inactive: new FormControl(false),
  });

  question: string = "";
  answer: string = "";

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Product";



  skuBO: any = {};
  i: number = -1;

  isskueditmode: boolean = false;

  imagesize: number = 70;

  mainproductrelated: any = [];
  mainproductspecification: any = [];
  mainproductfaq: any = [];
    filtertab: String = "RELATED";

    
  _routerSearchSub = Subscription.EMPTY;

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private confirmationDialogService: ConfirmationDialogService,
    private productFindService: NgbModal, private loginService: LoginDataService,
    private imageCompress: NgxImageCompressService, private ng2ImgMaxService: Ng2ImgMaxService) {
  this._routerSearchSub=  this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.productid = params.id
      }
    
      if (params.warehouseid !== undefined) {
        this.warehouseid = params.warehouseid
      }
    
      

      if (params.deptid !== undefined) {
        this.deptid = params.deptid
      }

      if (params.categoryid !== undefined) {
        this.categoryid = params.categoryid
      }

      if (params.subcategoryid !== undefined) {
        this.subcategoryid = params.subcategoryid
      }

      if (params.brandid !== undefined) {
        this.brandid = params.brandid
      }

      if (params.published !== undefined) {
        this.published = params.published
      }

      if (params.inactive !== undefined) {
        this.inactive = params.inactive
      }


      if (params.sku !== undefined) {
        this.sku = params.sku
      }


      if (params.productname !== undefined) {
        this.productname = params.productname
      }
      if (params.viewmode !== undefined) {
        this.viewmode = params.viewmode
      }


    });

    this.fnServiceChanges();

  }




  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {

      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  ngOnDestroy(): void {
  
    if (this._routerSearchSub !== undefined) {
      this._routerSearchSub.unsubscribe();
    }
   
  }


  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.productform.reset();
      this.fnInitializeProductFormGroup();


      if (this.productid > 0) {
        this.fngetProduct();
      } else {
        this.fnGetAutoCodeProduct();
      }
      this.fngetWarehouse();
      this.fngetDepartment();
      this.fngetDepartment();
      this.fngetCategory();

      this.fngetBrand();
      this.fngetTaxGroup();
      this.fngetVendor();      
      this.fngetFoodSymbol();
      this.fngetCertificate();
      this.fnGetItemType();
      this.fngetProductGroup();
      this.fngetSettings();
      this.fngetUom()

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.productid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }


    this.isadduom = this.loginService.fnCheckUserRoleAction("UOM", 'CREATE')
    this.isadddepartment = this.loginService.fnCheckUserRoleAction("Department", 'CREATE')
    this.isaddcategory = this.loginService.fnCheckUserRoleAction("Category", 'CREATE')
    this.isaddbrand = this.loginService.fnCheckUserRoleAction("Brand", 'CREATE')
    
    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }
  fnInitializeProductFormGroup() {
    this.productform.patchValue({
      productid: 0,
      productcode: '',
      title: '',
      deptid: null,
      categoryid: null,
      subcategoryid: null,
      brandid: null,
      vendorid: null,
      taxgrpid: null,
      foodsymbolid: null,
      certificateid: null,
      tags: '',
      sku: '',
      autosku: '',
      productname: '',
      shortdescr: '',
      fulldescr: '',
      uomqty: 1,
      uomid: null,
      mrp: 0,
      sellingprice: 0,
      currentstock: 0,
      cost: 0,
      ispublished:false,
      inactive: false,
    })

    this.productColl = [];
    this.productskuColl = [];
    this.productskupriceColl = [];
    this.deptColl = [];
    this.categoryColl = [];
    this.subcategoryColl = [];
    this.brandColl = [];
    this.warehouseColl = [];
    this.taxgroupColl = [];
    this.vendorColl = [];
    this.uomColl = [];
    this.certificateColl = [];
    this.foodsymbolColl = [];
    this.itemtypeColl = [];

  }

  fngetWarehouse() {        
    var data = {
      'warehouseid':0,            
    }
    this.appService.getWarehouse(data)
    .subscribe(
      (res)=>{                  
        this.warehouseColl=res.json().result;     
  
        if (this.productid === 0) {           
          this.productskupriceColl = [];
          for (let wbo of this.warehouseColl) {

            var priceBO = {
              warehouseid: wbo.warehouseid,
              warehousename: wbo.warehousename,
              productid: this.productid,
              skuid: 0,
              sellingprice: 0,
              stock: 0,
              qtyonorder: 0,
              isoutoforder: 0,
            }
            this.productskupriceColl.push(priceBO)
      
          }
        }
  
  
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }

  fngetDepartment() {
    var data = {
      'deptid': 0,
    }
    this.appService.getDepartment(data)
      .subscribe(
        (res) => {
          this.deptColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetCategory() {
    let _deptid = this.productform.get('deptid').value;
    var data = {
      'categoryid': 0,
      'deptid': _deptid,
    }

    if (_deptid > 0) {
      this.appService.getCategory(data)
        .subscribe(
          (res) => {
            this.categoryColl = res.json().result;

            if (this.categoryColl.length === 1 && this.productid===0) {
              this.productform.patchValue({'categoryid':this.categoryColl[0].categoryid})
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          })
    }
    else {
      this.categoryColl = [];
    }
  }

  fngetBrand() {
    var data = {
      'brandid': 0,
    }
    this.appService.getBrand(data)
      .subscribe(
        (res) => {
          this.brandColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetTaxGroup() {
    var data = {
      'taxgroupid': 0,
    }
    this.appService.getTaxGroup(data)
      .subscribe(
        (res) => {
          this.taxgroupColl = res.json().result;
          this.fnChangeTaxgroup();
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fnChangeTaxgroup() {

    for (let abo of this.taxgroupColl) {
      if (this.productform.get('taxgrpid').value === abo.taxgrpid) {
        this.taxrate = abo.taxrate;
      }
    }

    this.fnCalcSpWoTaxAmount();
  }

  


  fngetVendor() {
    var data = {
      'vendorid': 0,
    }
    this.appService.getVendor(data)
      .subscribe(
        (res) => {
          this.vendorColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetUom() {
    var data = {
      'uomid': 0,
    }
    this.appService.getUom(data)
      .subscribe(
        (res) => {
          this.uomColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetCertificate() {
    var data = {
      'certificateid': 0,
    }
    this.appService.getCertificate(data)
      .subscribe(
        (res) => {
          this.certificateColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetFoodSymbol() {
    var data = {
      'foodsymbolid': 0,
    }
    this.appService.getFoodSymbol(data)
      .subscribe(
        (res) => {
          this.foodsymbolColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fnGetItemType() {
    this.itemtypeColl = []
    this.itemtypeColl.push({ name: 'S', displayname: 'Simple' })
    this.itemtypeColl.push({ name: 'C', displayname: 'Combo' })

  }

  fngetProductGroup() {
    var data = {
      'productgroupid': 0,
    }
    this.appService.getProductGroup(data)
      .subscribe(
        (res) => {
          this.productgroupColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetSettings() {
    var data = {
      'settingsid': 0,
    }
    this.appService.getSettings(data)
      .subscribe(
        (res) => {

          var settingsColl = res.json().result;

          for (var abo of settingsColl) {
            if (abo.settingsname === 'MAXIMAGESIZE' && abo.settingstype === 'PRODUCT') {
              if (Number(abo.settingsvalue) > 0) {
                this.imagesize = Number(abo.settingsvalue);
              }
            }
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  //Change Event
  fnDepartmentChange() {
    let deptid = this.productform.get('deptid').value;
    if (deptid === null) {
      this.productform.patchValue({ categoryid: null });
      this.productform.patchValue({ subcategoryid: null });
    }

  }

  fnCategoryChange() {
    let categoryid = this.productform.get('categoryid').value;
    if (categoryid === null) {
      this.productform.patchValue({ subcategoryid: null });
    }

  }

  //Get Product
  fngetProduct() {
    this.fnShowProgress();

    var data = {
      'productid': this.productid,
    }
    this.appService.getProduct(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();

          //console.log(res);
          if (res.json().status == 200) {

            console.log(res.json().result)
            
            //Product Main
            this.productColl = res.json().result[0];


            //Product Sku
            let _prodsku = res.json().result[1];
            let _prodskuprice = res.json().result[2];
            let _prodskupictures = res.json().result[3];
            let _prodskuproductrelated = res.json().result[4];
            let _prodskuproductcrosssell = res.json().result[5];
            let _prodskuproductfaq = res.json().result[6];
            let _prodskuproductspecification = res.json().result[7];
            let _prodskuproductcombo = res.json().result[8];

            this.productskuColl = [];

            this.skuBO = {};
            this.i = -1;

            for (let pbo of _prodsku) {
              //Product Sku Price
              var productskupriceColl = []
              for (let mbo of _prodskuprice) {

                if (mbo.skuid === pbo.skuid) {
                  productskupriceColl.push(mbo)
                }
              }
              pbo.skuprice = productskupriceColl;

              //Product Sku Pictures
              var productskupictureColl = []
              for (let cbo of _prodskupictures) {
                if (cbo.skuid === pbo.skuid) {
                  productskupictureColl.push(cbo)

                }
              }                            
              pbo.skuimages = productskupictureColl;

              //Product Related
              var productrelatedColl = []
              for (let cbo of _prodskuproductrelated) {
                if (cbo.skuid === pbo.skuid) {
                  productrelatedColl.push(cbo)
    
                }
              }              
              pbo.productrelated = productrelatedColl;


               //Product Crossell
               var productcrosssellColl = []
               for (let cbo of _prodskuproductcrosssell) {
                 if (cbo.skuid === pbo.skuid) {
                  productcrosssellColl.push(cbo)
     
                 }
               }              
              pbo.productcrosssell = productcrosssellColl;


               //Product Faq
               var productfaqColl = []
               for (let cbo of _prodskuproductfaq) {
                 if (cbo.skuid === pbo.skuid) {
                    productfaqColl.push(cbo)     
                 }
               }              
              pbo.productfaq = productfaqColl;

              //Product Faq
              var productspecificationColl = []
              for (let cbo of _prodskuproductspecification) {
                if (cbo.skuid === pbo.skuid) {
                    productspecificationColl.push(cbo)     
                }
              }              
              pbo.productspecification = productspecificationColl;

              pbo.productcombo = _prodskuproductcombo;

              this.productskuColl.push(pbo);
            }

            if (this.productskuColl.length) {
              this.skuBO = this.productskuColl[0];
              this.i = 0;
              this.isskueditmode = true;

            }


            this.productBO = this.productColl[0];
            var editdata = {
              'productid': this.productColl[0].productid,
              'productcode': this.productColl[0].productcode,
              'title': this.productColl[0].title,
              'deptid': this.productColl[0].deptid,
              'categoryid': this.productColl[0].categoryid,
              'subcategoryid': this.productColl[0].subcategoryid,
              'brandid': this.productColl[0].brandid,
              'vendorid': this.productColl[0].vendorid,
              'taxgrpid': this.productColl[0].taxgrpid,
              'foodsymbolid': this.productColl[0].foodsymbolid,
              'certificateid': this.productColl[0].certificateid,
              'tags': this.productColl[0].tags,
              'inactive': this.productColl[0].inactive,
            }

            this.taxrate = this.productColl[0].taxrate;

            this.fnEditMode(editdata);

            this.fnChangeTaxgroup()
            this.fnCalcSpWoTaxAmount();

            if (this.viewmode === "EDIT") {
              this.fnEditProductSkuMainProduct();
              this.fnCurrentRouteChange()
            }
        
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  

  fnCurrentRouteChange(){

    const queryParams: Params = {   
      'warehouseid':this.warehouseid,
      'deptid': this.deptid,
      'categoryid': this.categoryid,
      'subcategoryid': this.subcategoryid,
      'brandid': this.brandid,
      'published': this.published,
      'inactive': this.inactive,
      'sku': this.sku,
      'productname': this.productname,          
      'productid': this.productid,     
      'viewmode':''
    };

    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: queryParams, 
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }
  
  fnGetAutoCodeProduct() {
    var data = {}

    this.appService.getAutoCodeProduct(data).subscribe(
      (res) => {

        if (res.json().status === 200) {
          let code = res.json().result[0][0].productcode;
          this.productform.patchValue({ productcode: code });


          let sku = res.json().result[1][0].sku;
          this.productform.patchValue({ sku: sku });
          
        }

      },
      (err) => {
        this.toastr.error('Error', err);
      });
  }

  fnGetAutoSkuProduct(index) {
    var data = {}

    this.appService.getAutoCodeProduct(data).subscribe(
      (res) => {
        if (res.json().status === 200) {
          let sku = res.json().result[1][0].sku;
          this.productskuColl[index].sku = sku;
          this.productskuColl[index].autosku = sku;
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });
  }

  //Validate
  fnValidateProduct(myForm): boolean {
    let _check: boolean = false


    if (!this.productform.valid) {
      _check = true;
    }
    // else if (myForm) {

    //   //  console.log(myForm);
    //   if (!myForm.valid && this.productid === 0) {

    //     var _p = 0;
    //     for (let wbo of this.productskuColl) {
    //       if (wbo.sku === "" || wbo.productname === "" || wbo.uomid === null || wbo.uomqty === "") {
    //         //this.tabset.select("ngb-tab-"+_p);
    //         break;
    //       }
    //       _p = _p + 1;
    //     }



    //     _check = true;
    //   }

    // }


    //else{    
    // for(let wbo of this.productskuColl){
    //   const index = this.productskuColl.findIndex(sku => sku.skuid !== wbo.skuid  && sku.sku === wbo.sku );
    //    if(index>-1){
    //     _check=true;
    //    return _check;
    //  }
    //  }
    // }


    return _check;
  }


  //Save Btn Click
  fnSaveBtnClick(myForm, mode) {

    try {
      this.submitted = true;
      this.successfully = false;


      //Validate
      if (this.productform.valid) {

        this.fnShowProgress();

        this.fnSetModifiedBySku();

        //Check
        var data = {
          'productid': this.productid,
          'productcode': this.productform.get('productcode').value,
          'title': this.productform.get('productname').value,
          'deptid': this.productform.get('deptid').value,
          'categoryid': this.productform.get('categoryid').value,
          'subcategoryid': this.productform.get('subcategoryid').value,
          'brandid': this.productform.get('brandid').value,
          'vendorid': this.productform.get('vendorid').value,
          'taxgrpid': this.productform.get('taxgrpid').value,
          'foodsymbolid': this.productform.get('foodsymbolid').value,
          'certificateid': this.productform.get('certificateid').value,
          'tags': this.productform.get('tags').value,
          'sku': this.productform.get('sku').value,
          'autosku': this.productform.get('autosku').value,
          'productname': this.productform.get('productname').value,
          'shortdescr': this.productform.get('shortdescr').value,
          'fulldescr': this.productform.get('fulldescr').value,
          'uomqty': this.productform.get('uomqty').value,
          'uomid': this.productform.get('uomid').value,
          'mrp': this.productform.get('mrp').value,
          'sellingprice': this.productform.get('sellingprice').value,
          'currentstock': this.productform.get('currentstock').value,
          'cost': this.productform.get('cost').value,
          'ispublished': this.productform.get('ispublished').value,

          'inactive': 0,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
          "productsku": this.productskuColl,
          "productskuimage":this.productskuimageColl,
          "productskuprice":this.productskupriceColl
        }
        //CheckMain
        this.appService.fnCheckProduct(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.productid === 0) {
                if (this.productform.get('productid').value > 0) {
                  //Update Product
                  this.fnSetProduct(data, mode);
                }
                else {
                  //Insert Product
                  this.fnInsertProduct(data, mode);
                }
              }
              else {
                //Update Product
                this.fnSetProduct(data, mode);
              }
            }
            else {
              this.fnHideProgress();
              if (_checkresult === "name") {
                this.productform.controls['productcode'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.fnHideProgress();
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {


      this.fnHideProgress();
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertProduct(data, mode) {

    this.appService.fnInsertProduct(data).subscribe(
      (res) => {
        this.fnHideProgress();
        if (res.json().result != "") {
          this.toastr.success('', "The new product has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.productcode + "-" + data.title);

          this.fnInsertActivityLogSku('INSERT', res.json().result, this.skuBO.sku + "-" + this.skuBO.productname);

          if (mode === 'EDITSAVE') {
            this.fnReloadProduct(res.json().result);
          }
          else if (mode === 'SAVE') {
            this.fnCloseProduct();
          }

        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetProduct(data, mode) {


    this.appService.fnSetProduct(data).subscribe(
      (res) => {

        this.fnHideProgress();

        if (res.json().result != "") {
          this.toastr.success('', "The product has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.productid, data.productcode + "-" + data.title);

          if (mode === 'SAVEEDIT') {
            this.fnReloadProduct(this.productid);
          }
          else if (mode === 'SAVE') {
            this.fnCloseProduct();
          }
        }
      },
      (err) => {
        console.log(err)
        this.toastr.error('Error', err);
      });

  }

  onSubmit(myForm) {
    console.log(myForm);
  }

  fnReloadProduct(id) {
    try {



      let navigationExtras: NavigationExtras = {
        queryParams: {
          'warehouseid':this.warehouseid,
          'deptid': this.deptid,
          'categoryid': this.categoryid,
          'subcategoryid': this.subcategoryid,
          'brandid': this.brandid,
          'id': id,
        }
        }

      this.router.navigate(['/AddProduct'], navigationExtras).then(() => {
        this.ngOnInit();
      //  window.location.reload();
      });;

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  //Close
  fnCloseProduct() {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'warehouseid':this.warehouseid,
          'deptid': this.deptid,
          'categoryid': this.categoryid,
          'subcategoryid': this.subcategoryid,
          'brandid': this.brandid,
          'published': this.published,
          'inactive': this.inactive,
          'sku': this.sku,
          'productname': this.productname,
        }
      }

      this.router.navigate(['/Product'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  private fnEditMode(deptBO) {


    this.productform.patchValue({
      productid: deptBO.productid,
      productcode: deptBO.productcode,
      title: deptBO.title,
      deptid: deptBO.deptid,
      categoryid: deptBO.categoryid,
      subcategoryid: deptBO.subcategoryid,
      brandid: deptBO.brandid,
      taxgrpid: deptBO.taxgrpid,
      vendorid: deptBO.vendorid,
      foodsymbolid: deptBO.foodsymbolid,
      certificateid: deptBO.certificateid,
      tags: deptBO.tags,
      sku: deptBO.sku,
      autosku: deptBO.autosku,
      productname: deptBO.productname,
      shortdescr: deptBO.shortdescr,
      fulldescr: deptBO.fulldescr,
      uomqty: deptBO.uomqty,
      uomid: deptBO.uomid,
      mrp: deptBO.mrp,
      sellingprice: deptBO.sellingprice,
      currentstock: deptBO.currentstock,
      cost: deptBO.cost,
      inactive: deptBO.inactive,
      ispublished: deptBO.ispublished,
    });

    this.fngetCategory();



    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }

  //fnEditProductMain
  private fnEditProductMain() {

    const modalRef = this.productFindService.open(EditproductmainComponent, { size: <any>'xl' });
    modalRef.componentInstance.productid = this.productBO.productid;
    modalRef.componentInstance.productcode = this.productBO.productcode;
    modalRef.componentInstance.title = this.productBO.title;
    modalRef.componentInstance.deptid = this.productBO.deptid;
    modalRef.componentInstance.categoryid = this.productBO.categoryid;
    modalRef.componentInstance.subcategoryid = this.productBO.subcategoryid;
    modalRef.componentInstance.brandid = this.productBO.brandid;
    modalRef.componentInstance.vendorid = this.productBO.vendorid;
    modalRef.componentInstance.taxgrpid = this.productBO.taxgrpid;
    modalRef.componentInstance.foodsymbolid = this.productBO.foodsymbolid;
    modalRef.componentInstance.certificateid = this.productBO.certificateid;
    modalRef.componentInstance.tags = this.productBO.tags;
    modalRef.componentInstance.inactive = this.productBO.inactive;
    modalRef.componentInstance.fnInitializeProductFormGroup();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      if (receivedEntry === true) {
        this.fnReloadProduct(this.productid);
      }
    });

  }


  //fnAddProductSku
  private fnAddProductSkuMain() {

    var skupriceColl = [];
    for (let wbo of this.warehouseColl) {
      var priceBO = {
        warehouseid: wbo.warehouseid,
        warehousename: wbo.warehousename,
        productid: this.productid,
        skuid: 0,
        sellingprice: 0,
        stock: 0,
        qtyonorder: 0,
        isoutoforder: 0,
      }
      skupriceColl.push(priceBO)
    }


    const modalRef = this.productFindService.open(EditproductskuComponent, { size: <any>'xl',keyboard: false });
    modalRef.componentInstance.productid = this.productid;
    modalRef.componentInstance.skuprice = skupriceColl
    modalRef.componentInstance.fnGetAutoCodeProduct()
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      if (receivedEntry === true) {
        this.fnReloadProduct(this.productid);
      }
    });

  }

  
  //fnEditProductSku--Main Product
  private fnEditProductSkuMainProduct() {

    for (let abo of this.productskuColl) {
      if (this.productBO.skuid === abo.skuid) {
        this.fnEditProductSkuMain(abo,true);
        return;
      }
    }

  }


  //fnEditProductSku
  private fnEditProductSkuMain(pBO,ismain) {

    const modalRef = this.productFindService.open(EditproductskuComponent, { size: <any>'xxxl' });
    modalRef.componentInstance.productid = this.productBO.productid;
    modalRef.componentInstance.ismainsku = ismain;
    modalRef.componentInstance.productBO = pBO;    
    modalRef.componentInstance.skuprice = pBO.skuprice;
    modalRef.componentInstance.skuimages = pBO.skuimages;
    modalRef.componentInstance.productspecification = pBO.productspecification;
    modalRef.componentInstance.productrelated = pBO.productrelated;
    modalRef.componentInstance.productfaq = pBO.productfaq;

    modalRef.componentInstance.fnInitializeProductFormGroup();
    modalRef.componentInstance.fnValidateRequiredFilter();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      if (receivedEntry === true) {
        this.fnReloadProduct(this.productid);
      }
    });

  }


  //Delete SKu
  private fnDeleteSku(skuid) {

    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Product Sku?')
      .then((confirmed) => {
        if (confirmed) {

          if (skuid > 0) {
            this.fnDeleteSkuExist(skuid);
            this.fngetProduct();
          }
        }
      }
      );


  }

  fnDeleteSkuExist(skuid) {
    var data = {
      'skuid': skuid,
    }
    this.appService.fnDeleteProductSku(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {

            this.fnInsertActivityLogSku('DELETE', this.productid, this.skuBO.sku + "-" + this.skuBO.productname);


            const index = this.productskuColl.findIndex(sku => sku.skuid === skuid);
            this.productskuColl.splice(index, 1);
            this.fngetProduct();
          } else {
            this.toastr.error('', res.json().err_field);
          }

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }



  //Add SKU
  private fnAddSku() {
    this.productskuColl.push(this.fnAddSkuBO());
    this.fnGetAutoSkuProduct(this.productskuColl.length - 1);

    this.skuBO = this.productskuColl[this.productskuColl.length - 1];
    this.i = this.productskuColl.length - 1;

    this.isskueditmode = true;


  }

  private fnAddSkuBO() {

    var productsku = {
      sno: this.productskuColl.length + 1,
      skuid: 0,
      productid: this.productid,
      sku: "",
      autosku: "",
      productname: "",
      productnamear: "",
      shortdescr: "",
      fulldescr: "",
      uomqty: 1,
      uomid: null,
      mrp: 0,
      ispublished: 0,
      allowreview: 0,
      availfrom: "",
      availto: "",
      isnew: 0,
      newfrom: "",
      newto: "",
      admincomment: "",
      ratings: 0,
      deliverydays: null,
      itemtype: '',
      productgroupid: null,
      isreturnable: 0,
      isfreecharge: 0,
      warrenty: 0,
      iswarrenty: 0,
      isexpressdelivery: 0,
      inactive: 0,
      createdby: 0,
      modifiedby: 0,
      image: null,
      strImage: null,
      picurl: "",
      picid: 0,
      picname: "",
      skuprice: this.fnAddSkuPriceBO(),
      skuimages: [],
      productrelated: [],
      productcrosssell: [],
      productfaq: [],
      productspecification: [],
      productcombo: []
    }
    return productsku;

  }

  private fnAddSkuPriceBO() {
    var productskupriceColl = []

    for (let wbo of this.warehouseColl) {
      var priceBO = {
        warehouseid: wbo.warehouseid,
        warehousename: wbo.warehousename,
        productid: this.productid,
        skuid: 0,
        sellingprice: 0,
        stock: 0,
        qtyonorder: 0,
        isoutoforder: 0,
      }
      productskupriceColl.push(priceBO)

    }

    return productskupriceColl;

  }

  //Edit Sku
  private fnEditSkuBtnClick(index) {

    this.skuBO = this.productskuColl[index];
    this.i = index;
    this.isskueditmode = true;
  }


 
  private fnSetModifiedBySku(){
    for(let mbo of this.productskuColl){
      mbo.createdby=this.loginService.fnGetModUserId();
      mbo.modifiedby=this.loginService.fnGetModUserId();
  
      for(let pbo of mbo.skuprice){
        pbo.createdby=this.loginService.fnGetModUserId();
        pbo.modifiedby=this.loginService.fnGetModUserId();
      }
  
    }
  }


  //Pop
  private fnAddUom() {

    const modalRef = this.productFindService.open(AdduompopComponent, { size: <any>'l' });
    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetUom();

      if (receivedEntry > 0) {
        this.productform.patchValue({ uomid: receivedEntry })
      }
    })

  }

  private fnAddDepartment() {

    const modalRef = this.productFindService.open(AdddepartmentpopComponent, { size: <any>'l' });
    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetDepartment();

      if (receivedEntry > 0) {
        this.productform.patchValue({ deptid: receivedEntry })
      }
    })

  }

  private fnAddCategory() {

    const modalRef = this.productFindService.open(AddcategorypopComponent, { size: <any>'l' });

    modalRef.componentInstance.deptid=this.productform.get('deptid').value
    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetCategory();

      if (receivedEntry > 0) {
        this.productform.patchValue({ categoryid: receivedEntry })
      }
    })

  }

  private fnAddBrand() {

    const modalRef = this.productFindService.open(AddbrandpopComponent, { size: <any>'l' });
    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetBrand();

      if (receivedEntry > 0) {
        this.productform.patchValue({ brandid: receivedEntry })
      }
    })

  }

  
  private fnCalcSpWoTaxAmount(){
    
    for (let wbo of this.productskuColl) {
    
      for (let abo of wbo.skuprice) {
      
        var _sptaxamt = this.taxrate * (abo.sellingprice / 100);
  
        abo.sellingpricewotax = abo.sellingprice - _sptaxamt
  
        abo.sellingpricewotax = abo.sellingpricewotax.toFixed(2);
      
  
      }
    }
  
    for (let abo of this.productskupriceColl) {
     
      var _sptaxamt = this.taxrate * (abo.sellingprice / 100);

      abo.sellingpricewotax = abo.sellingprice - _sptaxamt

      abo.sellingpricewotax = abo.sellingpricewotax.toFixed(2);
    

    }

    
  }


  private fnEditSkuImages(event) {
    try {

      if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;

        for (let i = 0; i < filesAmount; i++) {

          var file: File = event.target.files[i];


          // if (file.size / 1000 > this.imagesize) {
          //   continue;
          // }

          const fileReader: FileReader = new FileReader();

          fileReader.onload = (event) => {

            this.image = fileReader.result;
            this.strImage = this.image.split(',')[1];

            this.imageCompress.compressFile(this.image, 75, 50).then(
              result => {
                this.strImage = result.split(',')[1];
                var _skuimages =
                {
                  productid: this.productid,
                  skuid: 0,
                  picid: 0,
                  image: result,
                  strImage: this.strImage,
                  isclear: 0,
                  picurl: ""
                }
                this.productskuimageColl.push(_skuimages);
              }
            );

          }

          fileReader.readAsDataURL(event.target.files[i]);
        }




      }


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }

  }




  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }

  // Sku  Images
  removeimage(pictureindex) {


    if (pictureindex > -1) {

      if (this.productskuimageColl[pictureindex].picid > 0) {
        this.productskuimageColl[pictureindex].isclear = 1;
      }
      else {
        this.productskuimageColl.splice(pictureindex, 1);
      }

    }



  }


  fnShowZoomProduct(image,picurl) {
    const modalRef = this.productFindService.open(ProductimageviewComponent, { size: <any>'l' },);
    modalRef.componentInstance.doctype = "";
    modalRef.componentInstance.picurl = picurl;
    modalRef.componentInstance.image = image;
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


    });

  }

  

  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }


  fnInsertActivityLogSku(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: "SKU",
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }

  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    console.log(ex)
    this.toastr.warning('Internal Error', ex);
  }


  

}

