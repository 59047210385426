import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addfoodsymbol',
  templateUrl: './addfoodsymbol.component.html',
  styleUrls: ['./addfoodsymbol.component.css']
})
export class AddfoodsymbolComponent implements OnInit {


  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;

  //Save Validation
  submitted:boolean;
  successfully:boolean;

  //set image
  image;
  strImage: any;

  //Edit Get Values
  foodsymbolColl:any=[];
  foodsymbolid:number=0
  picurl:string="";
  picname:string="";

  foodsymbolform=new FormGroup({
  foodsymbol:new FormControl('',Validators.required),         
  foodsymbolid:new FormControl(0),
  foodsymbolpic:new FormControl(''), 
  foodsymbolimage:new FormControl(null),        
   inactive:new FormControl(false),     
  });

  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="FoodSymbol";

  
constructor(private appService: DhukanDataService,private route: ActivatedRoute,
     public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
       this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.foodsymbolid = params.id
        }   
       });

       this.fnServiceChanges();
}
 

fnServiceChanges(){
  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

fnOnInitForm(){
     try{
         this.isaddmode=true;
         this.foodsymbolform.reset();
         this.fnInitializeFoodSymbolFormGroup(); 

         if(this.foodsymbolid>0){
           this.fngetFoodSymbol();
         }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
}


fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.foodsymbolid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

fnInitializeFoodSymbolFormGroup(){
     this.foodsymbolform.setValue({
      foodsymbol:'',      
      foodsymbolid:0,
      foodsymbolpic:'',
      foodsymbolimage:null,
       inactive:false,      
     })
}

//Get 
fngetFoodSymbol() {        
       var data = {
         'foodsymbolid':this.foodsymbolid,            
       }
       this.appService.getFoodSymbol(data)
       .subscribe(
         (res)=>{                  
           this.foodsymbolColl=res.json().result;
           var editdata = {
             'foodsymbolid':this.foodsymbolColl[0].foodsymbolid,
             'foodsymbol':this.foodsymbolColl[0].foodsymbol,              
             'foodsymbolpic':this.foodsymbolColl[0].foodsymbolpic,              
             'inactive':this.foodsymbolColl[0].inactive,
             'picurl':this.foodsymbolColl[0].picurl,                                    
           }
           this.fnEditMode(editdata);
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })
}

//Save Btn Click
fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.foodsymbolform.valid){
           //Check
           var data = {
                 'foodsymbolid': this.foodsymbolid,       
                 'foodsymbol':this.foodsymbolform.get('foodsymbol').value,                                         
                 'inactive': 0,  
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(),    
                 'foodsymbolimage': this.strImage,
                 'foodsymbolpic': this.picurl,                 
           }
           this.appService.fnCheckFoodSymbol(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                       if (this.foodsymbolform.get('foodsymbolid').value>0){
                         //Update Department
                         this.fnSetFoodSymbol(data);
                       }
                       else{
                         //Insert Department
                         this.fnInsertFoodSymbol(data);
                       }
                 }
                 else{        
                       if(_checkresult==="name"){
                         this.foodsymbolform.controls['foodsymbol'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
}

//Insert
fnInsertFoodSymbol(data){    
   
     this.appService.fnInsertFoodSymbol(data).subscribe(
       (res)=>{
       if(res.json().result!=""){
         this.toastr.success('', "The new foodsymbol has been added successfully.Insert Successfully");

         this.fnInsertActivityLog('INSERT',0,data.foodsymbol);

         this.fnCloseFoodSymbol();
       }},
       (err)=>{
         this.toastr.error('Error', err);
       });

}

//Set
fnSetFoodSymbol(data){    

 this.appService.fnSetFoodSymbol(data).subscribe(
   (res)=>{
   if(res.json().result!=""){
       this.toastr.success('', "The foodsymbol has been updated successfully.");

       this.fnInsertActivityLog('EDIT',this.foodsymbolid,data.foodsymbol);

       this.fnCloseFoodSymbol();        
   }},
   (err)=>{
     this.toastr.error('Error', err);
   });

}

//Close
fnCloseFoodSymbol(){
 try{
    this.router.navigate(['/FoodSymbol']);
 }catch(ex){
     this.fnShowErrorMsg(ex);
 }
}

private fnEditMode(deptBO){
   this.foodsymbolform.setValue({
    foodsymbol:deptBO.foodsymbol,
    foodsymbolid:deptBO.foodsymbolid,
    foodsymbolpic:deptBO.foodsymbolpic,
    inactive:deptBO.inactive, 
    foodsymbolimage:null,     
  });
   this.picurl =deptBO.foodsymbolpic;   

 this.isaddmode=false;
 this.iseditmode=true;
 this.isdeletemode=false;
 this.isviewmode=false;
}

//Image Import
changeListener($event): void {
try{
   this.readThis($event.target);
 }catch(ex){
   this.fnShowErrorMsg(ex);
 }
}

readThis(inputValue: any): void {

 var file: File = inputValue.files[0];
 var myReader: FileReader = new FileReader();

 myReader.onloadend = (e) => {
     this.image = myReader.result;
     this.strImage = this.image.split(',')[1];
 }
 myReader.readAsDataURL(file);
}

removeimage(){
 this.image =null;
 this.strImage =null;
 this.picurl="";
}

fnOpenFileBrowser(event:any){

  event.preventDefault();

  let element:HTMLElement=document.getElementById('image') as HTMLElement;
  element.click();

}


fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}

private  fnShowErrorMsg(ex){
 this.toastr.warning('Internal Error', ex);       
}



}

