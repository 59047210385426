import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addsubcategory',
  templateUrl: './addsubcategory.component.html',
  styleUrls: ['./addsubcategory.component.css']
})
export class AddsubcategoryComponent implements OnInit {

 //Mode Values
 isaddmode:boolean=false;
 iseditmode:boolean=false;
 isdeletemode:boolean=false;
 isviewmode:boolean=false;

 //Save Validation
 submitted:boolean;
 successfully:boolean;

 //set image
 image;
 strImage: any;

 //Edit Get Values
cateColl:any=[];
 subcategoryColl:any=[]; 
 subcategoryid:number=0
 picurl:string="";
 picname:string="";

 subcategoryform=new FormGroup({
  subcategoryname:new FormControl('',Validators.required),         
  subcategoryid:new FormControl(0),
  categoryid:new FormControl(null,Validators.required), 
  image:new FormControl(null),        
  inactive:new FormControl(false),     
 });

 isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Subcategory";



 list = [];

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
    public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
      this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.subcategoryid = params.id
        }  
      });

      this.fnServiceChanges();
}

fnServiceChanges(){
  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

fnOnInitForm(){
    try{
        this.isaddmode=true;
        this.subcategoryform.reset();
        this.fnInitializeSubCategoryFormGroup(); 

        if(this.subcategoryid>0){
          this.fngetSubCategory();
        }

        this.fngetCategory();
        
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}


fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.subcategoryid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

fnInitializeSubCategoryFormGroup(){
    this.subcategoryform.setValue({
      subcategoryname:'',
      subcategoryid:0,
      categoryid:null,
      image:null,
      inactive:false,      
    })
}

//Get 
fngetCategory() {        
  var data = {
    'categoryid':0,            
  }
  this.appService.getCategory(data)
  .subscribe(
    (res)=>{                  
      this.cateColl=res.json().result;     
    },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}

//Get 
fngetSubCategory() {        
      var data = {
        'subcategoryid':this.subcategoryid,            
      }
      this.appService.getSubCategory(data)
      .subscribe(
        (res)=>{                     
          this.subcategoryColl=res.json().result;
          var editdata = {
            'subcategoryid':this.subcategoryColl[0].subcategoryid,
            'subcategoryname':this.subcategoryColl[0].subcategoryname, 
            'categoryid':this.subcategoryColl[0].categoryid,
            'inactive':this.subcategoryColl[0].inactive,
            'picurl':this.subcategoryColl[0].picurl,            
            'picname':this.subcategoryColl[0].picname,            
          }
          this.fnEditMode(editdata);
      },
      (err)=>{          
        this.toastr.error('Error', err);
      })
}

//Save Btn Click
fnSaveBtnClick(){
    try{
      this.submitted=true;
      this.successfully=false;
      //Validate
      if(this.subcategoryform.valid){
          //Check
          var data = {
                'subcategoryid': this.subcategoryid,       
                'subcategoryname':this.subcategoryform.get('subcategoryname').value, 
                'categoryid':this.subcategoryform.get('categoryid').value,           
                'inactive': 0,  
                'createdby': this.loginService.fnGetModUserId(),    
                'modifiedby':this.loginService.fnGetModUserId(),    
                'image': this.strImage,
                'picurl': this.picurl,
                'picname': this.picname,
          }

          
          this.appService.fnCheckSubCategory(data).subscribe(
            (res)=>{
                var _checkresult=res.json().result[0].checkfield;
               
                if(_checkresult==="done"){
                      if (this.subcategoryform.get('subcategoryid').value>0){
                        //Update Department
                        this.fnSetSubCategory(data);
                      }
                      else{
                        //Insert Department
                        this.fnInsertSubCategory(data);
                      }
                }
                else{        
                      if(_checkresult==="name"){
                        this.subcategoryform.controls['subcategoryname'].setErrors({'notfound': true});
                      }
                }
          },
          (err)=>{
              this.toastr.error('Error', err);           
          });
       }
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

//Insert
fnInsertSubCategory(data){    
  
    this.appService.fnInsertSubCategory(data).subscribe(
      (res)=>{
      if(res.json().result!=""){
        this.toastr.success('', "The new subcategory has been added successfully.Insert Successfully");

        this.fnInsertActivityLog('INSERT',0,data.subcategoryname);

        this.fnCloseSubCategory();
      }},
      (err)=>{
        this.toastr.error('Error', err);
      });

}

//Set
fnSetSubCategory(data){    

this.appService.fnSetSubCategory(data).subscribe(
  (res)=>{
  if(res.json().result!=""){
      this.toastr.success('', "The subcaetgory has been updated successfully.");

      this.fnInsertActivityLog('EDIT',this.subcategoryid,data.subcategoryname);


      this.fnCloseSubCategory();        
  }},
  (err)=>{
    this.toastr.error('Error', err);
  });

}

//Close
fnCloseSubCategory(){
try{
   this.router.navigate(['/SubCategory']);
}catch(ex){
    this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){
  this.subcategoryform.setValue({
    subcategoryname:deptBO.subcategoryname,
    subcategoryid:deptBO.subcategoryid,
    categoryid:deptBO.categoryid,    
    inactive:deptBO.inactive, 
    image:null,     
  });
  this.picurl =deptBO.picurl;
  this.picname =deptBO.picname;

this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}

//Image Import
changeListener($event): void {
try{
  this.readThis($event.target);
}catch(ex){
  this.fnShowErrorMsg(ex);
}
}

readThis(inputValue: any): void {

var file: File = inputValue.files[0];
var myReader: FileReader = new FileReader();

myReader.onloadend = (e) => {
    this.image = myReader.result;
    this.strImage = this.image.split(',')[1];
}
myReader.readAsDataURL(file);
}

removeimage(){
  this.image =null;
  this.strImage =null;
  this.picurl="";
}

fnOpenFileBrowser(event:any){

  event.preventDefault();

  let element:HTMLElement=document.getElementById('image') as HTMLElement;
  element.click();

}


fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}



private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}



private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}





}
