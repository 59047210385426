import { Input } from '@angular/core';
import { Component, EventEmitter,  OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import * as XLSX from 'xlsx'
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-importproduct',
  templateUrl: './importproduct.component.html',
  styleUrls: ['./importproduct.component.css']
})

export class ImportproductComponent implements OnInit {

  @Input()  warehouseid: any = 0;
  @Output() emitData = new EventEmitter();
  
  showProgress: boolean = false;

  constructor(private toastr: ToastrService, private appService: DhukanDataService,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) { }

  excelColl: any = [];

  ngOnInit() {
  }
  onFileChange(event: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      type NewType = XLSX.WorkSheet;

      const ws: NewType = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data); // Data will be logged in array format containing objects
      this.excelColl = data;

    };
  }



  fnSaveBtnClick() {

    let _dataColl=[];


    for (let row of this.excelColl) {
      var data = {
        barcode: row.barcode,
        itemname: row.itemname,
        itemcode: row.itemcode,
        department: row.department,
        category: row.category,
        subcategory: row.category,
        brand: row.brand,
        uom: row.uom,
        uomqty: row.uomqty,
        mrp: row.mrp,
        price: row.sellingprice,
        taxrate: row.taxrate,    
        sellingprice: row.sellingprice,
        stock: row.stock,
        ispack: row.ispack,        
        ispopular: 0,
        mainbarcode: row.mainbarcode,
        inactive:row.inactive,
        picurl: row.picurl,
        cost:row.cost,
        colortxt: row.colortxt,
        sizetxt: row.sizetxt,
        modifiedby: this.loginService.fnGetModUserId(),
      }

      _dataColl.push(data)     
    }

    this.fnInsertExcelImport(_dataColl)    

  }


  fnInsertExcelImport(_dataColl) {
    this.fnShowProgress()
    var data = {
      doctype:'jjj',    
      warehouseid:this.warehouseid,  
      //date:new Date(),
      modifiedby: this.loginService.fnGetModUserId(),
      importdetailscoll:_dataColl,
    }
    this.appService.fnInsertImportProduct(data).subscribe((res) => {
      this.fnHideProgress()
      console.log(res.json())
      if (res.json().status === 200) {
        
        this.toastr.success('', " The Product import successfully.");
        this.emitData.emit("DONE"); 
        this.activeModal.close(false);
       
      }
      else if (res.json().status === 400) { 
        this.toastr.error('Error', res.json().err_message);
      }
    },
      (err) => {
        this.fnHideProgress()
        this.toastr.error('Error', err);
      });
  }


  

private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}

  
  public decline() {
    this.activeModal.close(false);
  }

}
