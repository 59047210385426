import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {


  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  reportColl:any=[];
  

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="BillSummary";

  fromdate:String="";
  todate:String="";

  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private datePipe: DatePipe,
    private toastr: ToastrService,private loginService:LoginDataService)
     {     this.fnServiceChanges(); }

 //User Check
 fnServiceChanges(){
  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){                
            this.fnCheckPrivilage();
            if(this.isview){
              this.isloading=true;
              this.fnOnInitForm();
            }
            else{
              this.fnUserFailedForm();
            }    
        }
        else{
          this.fnUserFailedForm();
        }
  });
  }  


  ngOnInit() {
    try{

      
      this.dtOptions = {
        searching: true,       
        pageLength: 25,
        order:[1, 'desc'],
        serverSide: false,
        processing: true,
        dom:'<"top"f>rt<"bottom"ilp><"clear">',   
        columns:[       
          {title:'Order'}, 
          {title:'Date'}, 
          {title:'Customer'},       
          {title:'Mobile'}, 
          {title:'Items'}, 
          {title:'NetTotal'},  
          {title:'DiscountAmt'},
          {title:'DeliveryCharges'},
          {title:'RoundOff'},
          {title:'GrandTotal'},
        ],  
        columnDefs: [
          { targets:1, type: 'datetime' }      
        ],       
        scrollX: true,                 
        scrollCollapse: true,
                             
      };

      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
      
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetReportListView();     
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }


  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  
  fngetReportListView() {   
    this.fnShowProgress();
      var data = {
            'fromdate': null,
            'todate': null,
            'filter': null,
      }
      this.appService.getOrderListView(data)
          .subscribe(
            (res)=>{      
              this.fnHideProgress();                   
              if(res.json().status==200){                          
                this.reportColl=res.json().result;
                this.fnSetDataTable();
             
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }                          
          },
            (err)=>{          
              this.fnHideProgress();       
              this.toastr.error('Error', err);
            })
  }

 

  private fnSetDataTable(){
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {      
      dtInstance.destroy();      
      this.dtTrigger.next();
  
    });

  
  }

  public fnSearchDatatable(value){    
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {      
      dtInstance.search(value).draw();
    });
  }


  
fnFormatDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      return this.datePipe.transform(dt,"yyyy-MM-dd")  
    }
    
    }else {
    return null
  }
  
}

fnConvertDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      

      var dateParts = dt.split("-"); 

       var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 

       sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());

       
       
       return sdate;
    }
    
    }else {
    return null
  }
 
}

  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }




}
