import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportgridComponent } from '../grid/exportgrid/exportgrid.component';

@Component({
  selector: 'app-deliverycharges',
  templateUrl: './deliverycharges.component.html',
  styleUrls: ['./deliverycharges.component.css']
})
export class DeliverychargesComponent implements OnInit ,AfterViewInit, OnDestroy {


  deliverychargesColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="DeliveryCharges";

  searchtxt: any = "";
  columnDefs = [
    { headerName: 'Warehouse', field: 'warehousename',  },
    { headerName: 'Delivery Type', field: 'deliverytype',  },    
    { headerName: 'From Amt', field: 'fromamt',  },
    { headerName: 'To Amt', field: 'toamt',  },
    { headerName: 'Charges Amt', field: 'chargeamt',  },
  ]
  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
    pageindex: 1
  };

  



  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,
    private loginService:LoginDataService, private productFindService: NgbModal) 
    {    this.fnServiceChanges(); }

    fnServiceChanges(){
      this.loginService.serviceloginChange.subscribe((value) => {
            if(this.loginService.fnCheckUser()){                
                this.fnCheckPrivilage();
                if(this.isview){
                  this.isloading=true;
                  this.fnOnInitForm();
                }
                else{
                  this.fnUserFailedForm();
                }    
            }
            else{
              this.fnUserFailedForm();
            }
      });
    } 
 

  ngOnInit() {
    try{
      
      
     
      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetDeliveryCharges();     
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }


  ngAfterViewInit(): void {
  
  }

  ngOnDestroy(): void {
   
  }
  
  fngetDeliveryCharges() { 
           
    if(this.showProgress){
      return;
    }

    this.fnShowProgress();
      var data = {
            'deliverychargesid': 0,            
      }
      this.appService.getDeliveryCharges(data)
          .subscribe(
            (res)=>{      
              this.fnHideProgress();            
              if(res.json().status==200){                          
                this.deliverychargesColl=res.json().result;
                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
             
          },
            (err)=>{          
              this.fnHideProgress();
              this.toastr.error('Error', err);
            })
  }

  fnAddDeliveryCharges(){
    try{
      this.router.navigate(['/AddDeliveryCharges']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditDeliveryCharges(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
        }
  
      this.router.navigate(['/AddDeliveryCharges'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete DeliveryCharges?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteDeliveryCharges(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteDeliveryCharges(deptid){
       var data = {
         'deliverychargeid': deptid,            
       }
      this.appService.fnDeleteDeliveryBoy(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
             this.toastr.success('', " The DeliveryCharges has been deleted successfully.");    
             
             this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));
             
              this.fngetDeliveryCharges();
            }else{
              this.toastr.error('',res.json().err_field);   
            }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

     
  
  //Grid
  


  fnActionEditClick(data) {
  

    if (!this.isedit) {
      return;
    }this.fnEditDeliveryCharges(data.deliverychargeid);

  }

  fnActionDeleteClick(data) {
 

    if (!this.isdelete) {
      return;
    }
    this.fnDeleteConfirm(data.deliverychargeid);

   
  }

  fnActionExportClick() {
    const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xl', backdrop: 'static' });
    modalRef.componentInstance.doctype = this.formname;
    modalRef.componentInstance.columnDefs = this.columnDefs;
    modalRef.componentInstance.contentColl = this.deliverychargesColl;
    modalRef.componentInstance.fnSetTable();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
  
    
    });
  }


  private fnSetDataTable(){
   
  }

  fnResetGrid(data) {
    this.searchtxt = data;
    this.gridOptions.pageindex = 1
  }
      
  fnPrintData(){
    try{
                          
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnExportData(){
    try{
                          
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }




fnGetDescription(id){
  const index = this.deliverychargesColl.findIndex(sku => sku.deliverychargeid === id  ); 

  if(index){
    return this.deliverychargesColl[index].deliverytypeid;
  }
  else{
    return "";
  }

}

fnInsertActivityLog(action,refno,desc){
try{
  var data={
    logtype:action,
    logat:this.formname,
    refnum:refno,
    logdescr:desc,
    logby:this.loginService.fnGetModUserId(),
  }


  this.appService.fnInsertActivity(data).subscribe(
    (res)=>{
    },
    (err)=>{
   
    });

}catch{

}
}


  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }

  
  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }



}
