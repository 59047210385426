import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductfindComponent } from '../productfind/productfind.component';
import { ZoomproductComponent } from '../orders/zoomproduct/zoomproduct.component';
import { ImageformatterComponent } from '../grid/imageformatter/imageformatter.component';

@Component({
  selector: 'app-productgrouping',
  templateUrl: './productgrouping.component.html',
  styleUrls: ['./productgrouping.component.css']
})
export class ProductgroupingComponent implements OnInit ,AfterViewInit, OnDestroy {
 
  productgroupid:number=null;
  productColl:any=[];
  productgroupColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="ProductGrouping";

  
  private gridApi;
  private gridColumnApi;

  columnDefs = [
    { headerName: 'Pic', field: 'picurl', width: 100,sortable: false, cellRendererFramework: ImageformatterComponent,pinned: 'left' },
    { headerName: 'Sku', field: 'sku', type: "stringColumn", width:110,minWidth: 110,sortable: true, filter: true,pinned: 'left'},//,pinned: 'left'
    { headerName: 'Code', field: 'productcode', sortable: true, filter: true ,pinned: 'left'},     
    { headerName: 'Name', field: 'productname', width:260,minWidth: 260, sortable: true, filter: true,pinned: 'left' },     
    { headerName: 'Department', field: 'deptname', width:120,minWidth: 120, sortable: true, filter: true },     
    { headerName: 'Category', field: 'categoryname', width:120,minWidth: 120, sortable: true, filter: true },     
    { headerName: 'SubCategory', field: 'subcategoryname', width:150,minWidth: 150, sortable: true, filter: true },     
    { headerName: 'Brand', field: 'brandname', width:150,minWidth: 150, sortable: true, filter: true },     
    { headerName: 'MRP', field: 'mrp', sortable: true, filter: true },     
    { headerName: 'Price', field: 'sellingprice', sortable: true, filter: true },     
    { headerName: 'Stock', field: 'stock', sortable: true, filter: true }, 
    { headerName: 'Published', field: 'published', sortable: true, filter: true },  
    { headerName: 'Status', field: 'activestatus', sortable: true, filter: true },         
  ]

  gridOptions = {       
    rowSelection:'single',
   rowHeight: 40,   
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 2,
      width:100,
      minWidth: 100,      
      enableValue: false,      
      enableRowGroup: true,      
      enablePivot: true,
      sortable: true,
      filter: true,       
    },  
    sideBar: 'columns',  
   
  };


  
  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,private loginService:LoginDataService
    , private productFindService: NgbModal) { this.fnServiceChanges(); }

   //User Check
   fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){                
              this.fnCheckPrivilage();
              if(this.isview){
                this.isloading=true;
                this.fnOnInitForm();
              }
              else{
                this.fnUserFailedForm();
              }    
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }


  ngOnInit() {
    try{
  
         if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetProductGroup();     
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }
  


  ngAfterViewInit(): void {
  
  }

  ngOnDestroy(): void {
   
  }

      
  
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;   
  }

  fnActionEditClick() {
    if (!this.gridApi) {
      return;
    }
    
    if (this.gridApi.getSelectedRows().length > 0 && this.isedit) {
    
   }   
  }

  fnActionDeleteClick() {
    if (this.gridApi.getSelectedRows().length > 0) {
      var data = this.gridApi.getSelectedRows()[0];
      this.fnDeleteConfirm(data.productid,data.productname);
    }   
  }

  fnActionExportClick()  {
      const params = {
        columnGroups: true,
        allColumns: true,
        fileName: 'productgrouplist',
        //columnSeparator: document.querySelector("#columnSeparator").value
      };
      this.gridApi.exportDataAsCsv(params);
  }
  



  fngetProductGroup() {        
    var data = {
      'productgroupid':0,            
    }
    this.appService.getProductGroup(data)
    .subscribe(
      (res)=>{                  
        this.productgroupColl=res.json().result;     

        if(this.productgroupColl.length>0){
          this.productgroupid=this.productgroupColl[0].productgroupid;

          this.fngetProduct();
        }
      },
    (err)=>{              
    })
  }

  fngetProduct() {   
    
    if(this.showProgress){
      return;
    }

    if(Number(this.productgroupid)===0){
      if(this.productgroupColl.length>0){
        this.productgroupid=this.productgroupColl[0];
      }
      else{
        return;
      }
    }

    this.fnShowProgress();
    var data = {
          'productid': 0,  
          'deptid':0,
          'warehouseid':0,          
          'productgroupid':this.productgroupid                  
    }

   // console.log(data)
    this.appService.getProductByListView(data)
        .subscribe(          
          (res)=>{           
            
            if(res.json().status==200){                          
              this.productColl=res.json().result;
              this.fnSetDataTable();
              this.fnHideProgress();
            }
            else if(res.json().status==400){                          
              this.toastr.error('Error', res.json().err_field);
              this.fnHideProgress();
            }
           
           
        },
          (err)=>{          
            this.toastr.error('Error', err);
            this.fnHideProgress();
          })
  }

  
fnSelectProduct(){
 
  const modalRef = this.productFindService.open(ProductfindComponent ,{ size: <any>'xl' });
  modalRef.componentInstance.doctype ="SELECT PRODUCT" ;

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    for(let wbo of receivedEntry){

      this.fnAddProductGrouping(wbo.skuid,wbo.productname);

    }
  });

}


private fnAddProductGrouping(skuid,prodname){
  var data = {
    'skuid': skuid,            
    'productgroupid': this.productgroupid,            
    'modifiedby':this.loginService.fnGetModUserId()
  }
 this.appService.fnSetProductSkuByProductgroup(data)
   .subscribe(
     (res)=>{ 
       if(res.json().status===200){
        this.toastr.success('', " The Product has been Added successfully.");                                 
        this.fnInsertActivityLog('INSERT',skuid,prodname);
         this.fngetProduct();
       }else{
         this.toastr.error('',res.json().err_field);   
       }
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}




  public fnDeleteConfirm(deptid,prodname) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to remove product?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteProductGrouping(deptid,prodname)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteProductGrouping(skuid,prodname){
    var data = {
      'skuid': skuid,            
      'productgroupid': 0,            
      'modifiedby':this.loginService.fnGetModUserId()
    }
   this.appService.fnSetProductSkuByProductgroup(data)
     .subscribe(
       (res)=>{ 
         if(res.json().status===200){
          this.toastr.success('', " The Product has been removed successfully.");                                 
          this.fnInsertActivityLog('DELETE',skuid,prodname);
           this.fngetProduct();
         }else{
           this.toastr.error('',res.json().err_field);   
         }
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })

  }

     
  private fnSetDataTable(){
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

    
  

  fnGetDescription(id){
    const index = this.productColl.findIndex(sku => sku.paymodeid === id  ); 

    if(index){
      return this.productColl[index].paymode;
    }
    else{
      return "";
    }

  }

  fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }


    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
    
      });

  }catch{

  }
  }
  
  
  //Zoom Product
  fnShowZoomProduct(skuid){
    const modalRef = this.productFindService.open(ZoomproductComponent ,{ size: <any>'l' },);
    modalRef.componentInstance.doctype ="" ;
    modalRef.componentInstance.skuid=skuid;
    modalRef.componentInstance.fnGetProductInfo();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
  
    
    });
        
  }


  
  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }


  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }





}
