import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addbranch',
  templateUrl: './addbranch.component.html',
  styleUrls: ['./addbranch.component.css']
})
export class AddbranchComponent implements OnInit {

//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;



//Edit Get Values
branchColl:any=[];
  branchid: number = 0
  
  warehouseColl:any=[];

branchform=new FormGroup({         
  branch:new FormControl('',Validators.required),             
  branchid: new FormControl(0),   
  warehouseid:new FormControl(0),   
  inactive:new FormControl(false),     
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Branch";

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
     this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.branchid = params.id
      }  
     });
     this.fnServiceChanges();
}


fnServiceChanges(){
  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

fnOnInitForm(){
   try{
       this.isaddmode=true;
       this.branchform.reset();
       this.fnInitializebranchFormGroup(); 

       if(this.branchid>0){
         this.fngetBranch();
       }
     this.fngetWarehouse();

   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}



fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.branchid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}


fnInitializebranchFormGroup(){
   this.branchform.setValue({ 
    branch:'',    
     branchid: 0,    
     warehouseid:null,
    inactive:false,      
   })
}



fngetWarehouse() {        
  var data = {
    'warehouseid':0,            
  }
  this.appService.getWarehouse(data)
  .subscribe(
    (res)=>{                  
      this.warehouseColl=res.json().result;     
    },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}


//Get 
fngetBranch() {        
     var data = {
       'branchid':this.branchid,            
     }
     this.appService.getBranch(data)
     .subscribe(
       (res)=>{                  
         this.branchColl=res.json().result;
         var editdata = {
           'branchid':this.branchColl[0].branchid,
           'branch': this.branchColl[0].branch, 
           'warehouseid': this.branchColl[0].warehouseid, 
           'inactive':this.branchColl[0].inactive,          
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.branchform.valid){
         //Check
         var data = {
               'branchid': this.branchid,                     
           'branch': this.branchform.get('branch').value,     
               'warehouseid': this.branchform.get('warehouseid').value,     
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
         }
         this.appService.fnCheckBranch(data).subscribe(
           (res)=>{
               var _checkresult=res.json().result[0].checkfield;
             
               if(_checkresult==="done"){
                     if (this.branchform.get('branchid').value>0){
                       //Update Department
                       this.fnSetBranch(data);
                     }
                     else{
                       //Insert Department
                       this.fnInsertBranch(data);
                     }
               }
               else{        
                     if(_checkresult==="name"){
                       this.branchform.controls['paytype'].setErrors({'notfound': true});
                     }
               }
         },
         (err)=>{
             this.toastr.error('Error', err);           
         });
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertBranch(data){    
 
   this.appService.fnInsertBranch(data).subscribe(
     (res)=>{
     if(res.json().result!=""){
       this.toastr.success('', "The new Branch has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.branch);

       this.fnCloseBranch();
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetBranch(data){    

this.appService.fnSetBranch(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The Branch has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.branchid,data.branch);

     this.fnCloseBranch();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseBranch(){
try{
  this.router.navigate(['/Branch']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){

 this.branchform.setValue({     
      branch:deptBO.branch,      
   branchid: deptBO.branchid,
   warehouseid:deptBO.warehouseid,
      inactive:deptBO.inactive,   
 });


this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}

   
 fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
 }
  

private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}

private  fnShowErrorMsg(ex){
  console.log(ex)
this.toastr.warning('Internal Error', ex);       
}





}
