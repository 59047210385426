import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-adddeliveryoption',
  templateUrl: './adddeliveryoption.component.html',
  styleUrls: ['./adddeliveryoption.component.css']
})
export class AdddeliveryoptionComponent implements OnInit {

  @Input() doctype: string;
  @Input() mode: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  @Input() displaytxt: string="";

  @Input() deliveryorderBO: any = {};
     
  @Input() editorValue: string = '';
 
  
  deliveryboyColl:any=[];

  @Input()  deliveryboyid:number=null;

  ordersubmitted:boolean=false;
  ordersuccessfully:boolean=false;

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,private loginService:LoginDataService) { }

  ngOnInit() {
   this.fngetDeliveryBoy();
  }

    
  fngetDeliveryBoy() {   
    var data = {
          'deliveryboyid': 0,            
    }
    this.appService.getDeliveryBoy(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                          
              this.deliveryboyColl=res.json().result;
            }
            else if(res.json().status==400){                          
            console.log(res.json().err_field);
            }
          
          
        },
          (err)=>{          
            console.log('Error', err);
          })
  }
    
  
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  fnSetDeliveryOrder(){
    this.ordersubmitted=true;
    this.ordersuccessfully=false;

    if(!this.deliveryboyid){
      this.deliveryboyid=0;
    }

    if(this.deliveryboyid>0){
      var _data={
        deliveryboyid:this.deliveryboyid
      }
      this.emitData.emit(_data);  
      this.activeModal.close(true); 
    }
    


  }



}
