import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';

@Component({
  selector: 'app-orderaddressmap',
  templateUrl: './orderaddressmap.component.html',
  styleUrls: ['./orderaddressmap.component.css']
})
export class OrderaddressmapComponent implements OnInit {


  @Input() doctype: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();

  @Input() latitude = 11.0168;
  @Input() longitude =  76.9558;
 
  @Input() arealatitude = 11.0168;
  @Input() arealongitude =  76.9558;

  public origin: any;
  public destination: any;

  mapType = 'roadmap';

  public renderOptions = {
    suppressMarkers: true,
  }

  public markerOptions = {
    origin: {
        icon: 'http://bigmart.ae/bigmart/bigmartadmin/assets/images/marker.png',
        draggable: true,
    },
    destination: {
        icon: 'http://bigmart.ae/bigmart/bigmartadmin/assets/images/locationbranch.png',
        label: '',        
    },
}

public transitOptions: string = 'TRANSIT'
public travelMode: string = 'DRIVING'

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService) { }

  ngOnInit() {
    this.getDirection();
  }

   
  getDirection() {
    this.origin = { lat: this.latitude, lng: this.longitude };
    this.destination = { lat: this.arealatitude, lng:this.arealongitude };
    
  }

  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


}
