import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addpopularsearch',
  templateUrl: './addpopularsearch.component.html',
  styleUrls: ['./addpopularsearch.component.css']
})
export class AddpopularsearchComponent implements OnInit {


//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
popularsearchColl:any=[];
popularsearchid:number=0

popularsearchform=new FormGroup({
  searchword:new FormControl('',Validators.required),         
  popularsearchid:new FormControl(0),      
 inactive:new FormControl(false),     
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="PopularSearch";

ispopularsearchreadonly:boolean=false;

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
     this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.popularsearchid = params.id
      }  

     });

     this.fnServiceChanges();
}


fnServiceChanges(){
  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

fnOnInitForm(){
   try{
       this.isaddmode=true;
       this.popularsearchform.reset();
       this.fnInitializePopularSearchFormGroup(); 

       if(this.popularsearchid>0){
         this.fngetPopularSearch();
       }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}



fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.popularsearchid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

fnInitializePopularSearchFormGroup(){
   this.popularsearchform.setValue({
    searchword:'',
    popularsearchid:0,      
     inactive:false,      
   })
}

//Get 
fngetPopularSearch() {        
     var data = {
       'popularsearchid':this.popularsearchid,            
     }
     this.appService.getPopularSearch(data)
     .subscribe(
       (res)=>{                  
         this.popularsearchColl=res.json().result;
         var editdata = {
           'popularsearchid':this.popularsearchColl[0].popularsearchid,
           'searchword':this.popularsearchColl[0].searchword, 
           'inactive':this.popularsearchColl[0].inactive,          
         }
         this.fnEditMode(editdata);

        if(this.popularsearchColl[0].shipmenttag==='OWNDELIVERY'){
          this.ispopularsearchreadonly=true
        }
         
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.popularsearchform.valid){
         //Check
         var data = {
               'popularsearchid': this.popularsearchid,       
               'searchword':this.popularsearchform.get('searchword').value,            
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
         }
         this.appService.fnCheckPopularSearch(data).subscribe(
           (res)=>{
               var _checkresult=res.json().result[0].checkfield;
             
               if(_checkresult==="done"){
                     if (this.popularsearchform.get('popularsearchid').value>0){
                       //Update Department
                       this.fnSetPopularSearch(data);
                     }
                     else{
                       //Insert Department
                       this.fnInsertPopularSearch(data);
                     }
               }
               else{        
                     if(_checkresult==="name"){
                       this.popularsearchform.controls['popularsearch'].setErrors({'notfound': true});
                     }
               }
         },
         (err)=>{
             this.toastr.error('Error', err);           
         });
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertPopularSearch(data){    
 
   this.appService.fnInsertPopularSearch(data).subscribe(
     (res)=>{
     if(res.json().result!=""){
       this.toastr.success('', "The new popularsearch has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.popularsearch);

       this.fnClosePopularSearch();
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetPopularSearch(data){    

this.appService.fnSetPopularSearch(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The popularsearch has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.popularsearchid,data.popularsearch);

     this.fnClosePopularSearch();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnClosePopularSearch(){
try{
  this.router.navigate(['/PopularSearch']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){
 this.popularsearchform.setValue({
  searchword:deptBO.searchword,
  popularsearchid:deptBO.popularsearchid,
   inactive:deptBO.inactive,   
 });

this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}



fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}

private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}




}
