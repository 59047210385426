import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-statusorders',
  templateUrl: './statusorders.component.html',
  styleUrls: ['./statusorders.component.css']
})
export class StatusordersComponent implements OnInit {

  @Input() orderBO :any={};
  
  constructor() { }

  ngOnInit() {
  }

}
