import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-productorderby',
  templateUrl: './productorderby.component.html',
  styleUrls: ['./productorderby.component.css']
})
export class ProductorderbyComponent implements OnInit {


  
  @Input() doctype: string;
  @Input() mode: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  @Input() displaytxt: string = "";

  @Input() productColl: any = [];
  
  constructor(private activeModal: NgbActiveModal, private appService: DhukanDataService, private loginService: LoginDataService) { }

  
  ngOnInit() {
   
  }

  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.productColl, event.previousIndex, event.currentIndex);
  }
}
