import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addaddress',
  templateUrl: './addaddress.component.html',
  styleUrls: ['./addaddress.component.css']
})
export class AddaddressComponent implements OnInit {


//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;

areaColl:any=[]; 

//Edit Get Values
orderaddressColl:any=[];
addressid:number=0
customerid:number=0


addressform=new FormGroup({         
  firstname:new FormControl('',Validators.required),           
  mobile:new FormControl('',Validators.required), 
  houseno:new FormControl('',Validators.required), 
  street:new FormControl('',Validators.required), 
  areaid:new FormControl(0,Validators.required), 
  cityid:new FormControl(0), 
  landmark:new FormControl('',Validators.required), 
  addressnickname:new FormControl('',Validators.required), 
  latitude:new FormControl(0), 
  longitude:new FormControl(0), 
  maplocationname:new FormControl(''), 
  city:new FormControl('',Validators.required), 
  addressothername:new FormControl(''), 
  isdefault:new FormControl(false), 
  addressid:new FormControl(0),        
});


constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
     this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.addressid = params.id
      }  
      if(params.cid!==undefined){
        this.customerid = params.cid
      }  

     
      
     });
}

ngOnInit() {
   try{
       this.isaddmode=true;
       this.addressform.reset();
       this.fnInitializeaddressformGroup(); 

       if(this.addressid>0){
         this.fngetaddress();
       }
       this.fngetArea();

      
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

fnInitializeaddressformGroup(){
   this.addressform.setValue({
    firstname:'',           
    mobile:'', 
    houseno:'', 
    street:'', 
    areaid:0, 
    cityid:0, 
    landmark:'', 
    addressnickname:'', 
    latitude:0, 
    longitude:0, 
    maplocationname:'', 
    city:'', 
    addressothername:'', 
    isdefault:false, 
    addressid:0,             
   })
}




//Get 
fngetaddress() {        
     var data = {
       'addressid':this.addressid,            
     }
     this.appService.getAddressAdmin(data)
     .subscribe(
       (res)=>{               
       
         this.orderaddressColl=res.json().result;
      
         this.fnEditMode(this.orderaddressColl[0]);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

fngetArea() {        
  var data = {
    'areaid':0,            
  }
  this.appService.getArea(data)
  .subscribe(
    (res)=>{                  
      this.areaColl=res.json().result;         
  },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}



//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.addressform.valid){
         //Check
         var data = {
                firstname:this.addressform.get('firstname').value,            
                mobile:this.addressform.get('mobile').value, 
                houseno:this.addressform.get('houseno').value, 
                street:this.addressform.get('street').value, 
                areaid:this.addressform.get('areaid').value, 
                cityid:this.addressform.get('cityid').value, 
                landmark:this.addressform.get('landmark').value, 
                addressnickname:this.addressform.get('addressnickname').value, 
                latitude:this.addressform.get('latitude').value, 
                longitude:this.addressform.get('longitude').value, 
                maplocationname:this.addressform.get('maplocationname').value, 
                city:this.addressform.get('city').value, 
                addressothername:this.addressform.get('addressothername').value, 
                isdefault:this.addressform.get('isdefault').value, 
                addressid:this.addressform.get('addressid').value, 
                customerid:this.customerid,  
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
         }

         if (this.addressform.get('addressid').value>0){
          this.fnSetaddress(data);
         }
         else{
          this.fnInsertaddress(data);
         }
         
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}
//Insert
fnInsertaddress(data){    

  this.appService.fnInsertAddressAdmin(data).subscribe(
   (res)=>{
   if(res.json().result!=""){
       this.toastr.success('', "The orderaddress has been updated successfully.");

       this.fnInsertActivityLog('INSERT',this.customerid,data.maplocationname);

       this.fnCloseaddress();        
   }},
   (err)=>{
     this.toastr.error('Error', err);
   });
  
  }

  

//Set
fnSetaddress(data){    

this.appService.fnSetAddressAdmin(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The address has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.customerid,data.maplocationname);

     this.fnCloseaddress();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseaddress(){
try{
  let navigationExtras: NavigationExtras = {
    queryParams: {
      'id': this.customerid,
    }
    }

  this.router.navigate(['/CustomerView'], navigationExtras);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){

 this.addressform.setValue({
  firstname:deptBO.firstname,           
  mobile:deptBO.mobile, 
  houseno:deptBO.houseno,
  street:deptBO.street, 
  areaid:deptBO.areaid,
  cityid:deptBO.cityid,
  landmark:deptBO.landmark,
  addressnickname:deptBO.addressnickname,
  latitude:deptBO.latitude,
  longitude:deptBO.longitude,
  maplocationname:deptBO.maplocationname,
  city:deptBO.city,
  addressothername:deptBO.addressothername,
  isdefault:deptBO.isdefault,
  addressid:deptBO.addressid,      
 });


this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}



fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:"CustomerAddress",
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}



private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}



}
