import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addsize',
  templateUrl: './addsize.component.html',
  styleUrls: ['./addsize.component.css']
})
export class AddsizeComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  sizeColl: any = [];
  sizeid: number = 0

  sizeform = new FormGroup({
    size: new FormControl('', Validators.required),
    sizeid: new FormControl(0),
    inactive: new FormControl(false),
  });


  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Size";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.sizeid = params.id
      }
    });
    this.fnServiceChanges();
  }
 

  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
         
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
            
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }
              
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.sizeform.reset();
      this.fnInitializeSizeFormGroup();

      if (this.sizeid > 0) {
        this.fngetSize();
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }




  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.sizeid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeSizeFormGroup() {
    this.sizeform.setValue({
      size: '',
      sizeid: 0,
      inactive: false,
    })
  }

  //Get 
  fngetSize() {
    var data = {
      'sizeid': this.sizeid,
    }
    this.appService.getSize(data)
      .subscribe(
        (res) => {
          this.sizeColl = res.json().result;
          var editdata = {
            'sizeid': this.sizeColl[0].sizeid,
            'size': this.sizeColl[0].size,
            'inactive': this.sizeColl[0].inactive,
          }
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.sizeform.valid) {
        //Check
        var data = {
          'sizeid': this.sizeid,
          'size': this.sizeform.get('size').value,
          'inactive': 0,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckSize(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;
               
            if (_checkresult === "done") {
              if (this.sizeform.get('sizeid').value > 0) {
                //Update Department
                this.fnSetSize(data);
              }
              else {
                //Insert Department
                this.fnInsertSize(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.sizeform.controls['size'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertSize(data) {
   
    this.appService.fnInsertSize(data).subscribe(
      (res) => {
       
        if (res.json().result != "") {
          this.toastr.success('', "The new size has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.size);

          this.fnCloseSize();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetSize(data) {

    this.appService.fnSetSize(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The size has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.sizeid, data.size);

          this.fnCloseSize();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseSize() {
    try {
      this.router.navigate(['/Size']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {
    this.sizeform.setValue({
      size: deptBO.size,
      sizeid: deptBO.sizeid,
      inactive: deptBO.inactive,
    });
  
    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }
  

      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {
     
        });

    } catch {

    }
  }


  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }




}
