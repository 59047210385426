import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-updateproductrequest',
  templateUrl: './updateproductrequest.component.html',
  styleUrls: ['./updateproductrequest.component.css']
})
export class UpdateproductrequestComponent implements OnInit {

  @Input() doctype: string;

  @Output() emitData = new EventEmitter();

  @Input() orderNo: string = '';

  @Input() editorValue: string = '';

  @Input() productrequestid: number = 0;
  @Input() productrequestBo: any = {}

  @Input() productstatus: string = '';
  @Input() remarks: string = '';
  @Input() isclosed: boolean = false;


  //Save Validation
  submitted: boolean;
  successfully: boolean;

  productrequestform = new FormGroup({
    productrequestid: new FormControl(null, Validators.required),
    productstatus: new FormControl(''),
    remarks: new FormControl(null),
    isclosed: new FormControl(false),
  })

 statusColl:any=[];

  ordersubmitted: boolean;
  ordersuccessfully: boolean;

  constructor(private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService,
    private appService: DhukanDataService, private toastr: ToastrService, private productFindService: NgbModal, private loginService: LoginDataService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.fnInitilize();
    this.fngetStatus();

  }

  fnInitilize() {
    this.productrequestform.setValue({
      productrequestid: this.productrequestid,
      productstatus: this.productstatus,
      remarks: this.remarks,
      isclosed: this.isclosed,
    })

  }


  fngetStatus() {
    this.statusColl = [];
    this.statusColl.push({name:'Progress',displayname:'Progress'})
    this.statusColl.push({name:'UnAvailable',displayname:'UnAvailable'})
    this.statusColl.push({name:'Delivered',displayname:'Delivered'})

  
  }


  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  fnSetOrder() {

    this.submitted = true;
    this.successfully = false;

    if (this.productrequestform.valid) {

      var data = {
        'productrequestid': this.productrequestform.get("productrequestid").value,
        'productstatus': this.productrequestform.get("productstatus").value,
        'remarks': this.productrequestform.get("remarks").value,
        'isclosed': this.productrequestform.get("isclosed").value,
        'modifiedby': this.loginService.fnGetModUserId()
      }
      this.appService.fnSetProductRequestStatus(data)
        .subscribe(
          (res) => {
            if (res.json().status == 200) {
              this.emitData.emit("DONE");
              this.activeModal.close(true);

            }
            else if (res.json().status == 400) {
              this.toastr.error('Error', res.json().err_field);
            }


          },
          (err) => {

            this.toastr.error('Error', err);
          })

    }



  }


  aaNumber(total) {

    if (isNaN(total) || total === undefined || total === null) {
      return Number(0)
    }
    else {
      return Number.parseFloat(Number(total).toFixed(2))
    }
  }

  fnInsertActivityLog(action, refno, desc, doid) {
    try {
      var data = {
        logtype: action,
        logat: "ProductRequest",
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
        orderid: this.productrequestid,
        deliveryorderid: doid
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }



  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }

  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {


        var dateParts = dt.split("-");

        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);

        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());



        return sdate;
      }

    } else {
      return null
    }

  }






}
