import { DatePipe } from '@angular/common';
import { EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { EditdeliverydateComponent } from '../editdeliverydate/editdeliverydate.component';

@Component({
  selector: 'app-readytopickorder',
  templateUrl: './readytopickorder.component.html',
  styleUrls: ['./readytopickorder.component.css']
})
export class ReadytopickorderComponent implements OnInit {

 
  @Input() doctype: string;
 
  @Output() emitData = new EventEmitter();

  @Input() orderNo: string = '';

  @Input() editorValue: string = '';

  @Input() orderid:number=0;
  @Input() ordermainBo:any={}


  //Save Validation
  submitted: boolean;
  successfully: boolean;

  shipmentreadyform = new FormGroup({
    branchid: new FormControl(null, Validators.required),
    trackno: new FormControl('', Validators.required),
    shipmenttypeid: new FormControl(null, Validators.required),
  })

  shipmenttypeColl: any = [];
  branchColl: any = [];

  ordersubmitted:boolean;
  ordersuccessfully:boolean;

  constructor(private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService,
    private appService: DhukanDataService, private toastr: ToastrService, private productFindService: NgbModal,private loginService: LoginDataService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.fnInitilize();
    this.fngetShipmentType();
    this.fngetBranch();
   
  }
  
  fnInitilize() {
    this.shipmentreadyform.setValue({
      trackno: '',
      shipmenttypeid: null,
      branchid: null,
    })
  }  

  fngetBranch() {

    var data = {
      'branchid': 0,
    }
    this.appService.getBranch(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            this.branchColl = res.json().result;
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {          
          this.toastr.error('Error', err);
        })
  }

  fngetShipmentType() {

    var data = {
      'shipmenttypeid': 0,
    }
    this.appService.getShipmentType(data)
      .subscribe(
        (res) => {         
          if (res.json().status == 200) {
            this.shipmenttypeColl = res.json().result;

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
      
          this.toastr.error('Error', err);
        })
  }

  
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  fnSetOrder(){

    this.submitted = true;
    this.successfully=false;

    if (this.shipmentreadyform.valid) {
      var ressdata = {
        'status': 'DONE',
        'trackno':this.shipmentreadyform.get("trackno").value,
        'shipmenttypeid': this.shipmentreadyform.get("shipmenttypeid").value,
        'branchid':this.shipmentreadyform.get('branchid').value
      }

      this.emitData.emit(ressdata);  
      this.activeModal.close(true); 
    }
    


  }

  
  fnInsertActivityLog(action, refno, desc, doid) {
    try {
      var data = {
        logtype: action,
        logat: "ORDER",
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
        orderid: this.orderid,
        deliveryorderid: doid
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }



  fnFormatDatetime(dt){
    if(dt){
      if(dt===""){
        return null
      }
      else{
        return this.datePipe.transform(dt,"yyyy-MM-dd")  
      }
      
      }else {
      return null
    }
    
  }
  
  fnConvertDatetime(dt){
    if(dt){
      if(dt===""){
        return null
      }
      else{
        
  
        var dateParts = dt.split("-"); 
  
         var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 
  
         sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
  
         
         
         return sdate;
      }
      
      }else {
      return null
    }
   
  }

  




}
