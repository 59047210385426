import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-productfilter',
  templateUrl: './productfilter.component.html',
  styleUrls: ['./productfilter.component.css']
})
export class ProductfilterComponent implements OnInit {

  @Input() doctype: string;
  @Input() mode: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  @Input() displaytxt: string="";

  @Input() productgroupid: number=null;
  @Input() published: string="";
  @Input() inactive: string="";
  @Input() sku: string="";
  @Input() productname: string="";
     
  @Input() editorValue: string = '';
 
  productgroupColl:any=[];
  publishedColl:any=[];
  inactiveColl:any=[];


  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,private loginService:LoginDataService) { }

  ngOnInit() {
    this.fnGetPublished();
    this.fnGetInActive();
    this.fngetProductGroup();
  }

    
  
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  
fnGetPublished(){
  this.publishedColl=[];
  this.publishedColl.push({name:'PUBLISHED',displayname:'Published'})
  this.publishedColl.push({name:'UNPUBLISHED',displayname:'UnPublished'})
  this.publishedColl.push({name:'ALL',displayname:'All'})
  
}


fnGetInActive(){
  this.inactiveColl=[];
  this.inactiveColl.push({name:'ACTIVE',displayname:'Active'})
  this.inactiveColl.push({name:'INACTIVE',displayname:'Inactive'})
  this.inactiveColl.push({name:'ALL',displayname:'All'})
  
}


  
fngetProductGroup() {        
  var data = {
    'productgroupid':0,            
  }
  this.appService.getProductGroup(data)
  .subscribe(
    (res)=>{                  
      this.productgroupColl=res.json().result;     
    },
  (err)=>{              
  })
}




  fnSetFilter(){
    var _data={
      productgroupid:this.productgroupid,
      published:this.published,
      inactive:this.inactive,
      sku:this.sku,
      productname:this.productname
    }
    this.emitData.emit(_data);  
    this.activeModal.close(true); 


  }


}
