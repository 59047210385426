import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ToastrService } from 'ngx-toastr';
import { ProductfindComponent } from '../productfind/productfind.component';

@Component({
  selector: 'app-sectiontype',
  templateUrl: './sectiontype.component.html',
  styleUrls: ['./sectiontype.component.css']
})
export class SectiontypeComponent implements OnInit {

  @Input() doctype: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();

  @Input() sectiontypeid: number=0;
  @Input() sectiontypename: string = '';
  @Input() sectiontypedisplayname: string = '';
 
  @Input()  deptid:number=null; 
  @Input()  categoryid:number=null; 
  @Input()  subcategoryid:number=null; 
  @Input()  brandid:number=null; 
  @Input()  productid:number=null;  
  @Input()  productname:string='';  

  @Input() linktype:string=''; 
  @Input() linktargetid:number=null;   
  @Input() linkurl:string='';   
  @Input() linkhtml:string='';  

  faqsubmitted:boolean;
  faqsuccessfully:boolean;

  deptColl:any=[];
  categoryColl:any=[];
  subcategoryColl:any=[];
  brandColl:any=[];
  linktypeColl:any=[];

  constructor(private activeModal: NgbActiveModal,private appService: DhukanDataService,
    private toastr: ToastrService, private productFindService: NgbModal) { }

  ngOnInit() {
    this.fngetLinkType();
  }

   
  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  fnAddSectionType(){
    this.faqsubmitted=true;
    this.faqsuccessfully=false;
    //Validate
    if(this.sectiontypedisplayname!==""){         
        var _req={
          sectiontypeid:this.sectiontypeid,
          sectiontypename:this.sectiontypename,     
          sectiontypedisplayname:this.sectiontypedisplayname,
          linktype:this.linktype,    
          linktargetid:this.fnGetLinkTypeId(this.linktype),            
          linkurl:this.fnGetLinkTypeUrl(this.linktype),            
          linkhtml:this.linkhtml,  
        }
    
        this.emitData.emit(_req);  
        this.activeModal.close(true);  
      }


  }

  
fngetLinkType() { 
    let _type=[
      {display:"Department",name:"DEPARTMENT"},
      {display:"Category",name:"CATEGORY"},
      {display:"SubCategory",name:"SUBCATEGORY"},
      {display:"Brand",name:"BRAND"},
      {display:"Product",name:"PRODUCT"},
      {display:"Html",name:"HTML"}
    ]
  
    this.linktypeColl=_type;
}
  
fngetDepartment() {        
  var data = {
    'deptid':0,            
  }
  this.appService.getDepartment(data)
  .subscribe(
    (res)=>{                  
      this.deptColl=res.json().result;     
    },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}

fngetCategory() {        
 
  var data = {
    'categoryid':0,        
    'deptid':0,     
  }


    this.appService.getCategory(data)
    .subscribe(
      (res)=>{                  
        this.categoryColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetSubCategory() {        
 
  var data = {
    'categoryid':0,        
    'subcategoryid':0,     
  }


    this.appService.getSubCategory(data)
    .subscribe(
      (res)=>{                  
        this.subcategoryColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetBrand() {        
 
  var data = {
    'brandid':0            
  }


    this.appService.getBrand(data)
    .subscribe(
      (res)=>{                  
        this.brandColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fnLinkTypeChange(){
  let type=this.linktype;
  if(type==="DEPARTMENT"){
    this.fngetDepartment();
  }
  else if(type==="CATEGORY"){
    this.fngetCategory();
  }
  else if(type==="SUBCATEGORY"){
    this.fngetSubCategory();
  }
  else if(type==="BRAND"){
    this.fngetBrand();
  }
}


fnSelectProduct(){
 
  const modalRef = this.productFindService.open(ProductfindComponent ,{ size: <any>'xl' });
  modalRef.componentInstance.doctype ="SELECT PRODUCT" ;

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    for(let wbo of receivedEntry){
      this.productid=wbo.productid;
      this.productname=wbo.productname;
    }
  });

}

public fnEditSectionType(){
  
  this.fnLinkTypeChange();

  let type=this.linktype;
   if(type==="DEPARTMENT"){
    this.deptid=this.linktargetid;
   }
   else if(type==="CATEGORY"){
    this.categoryid=this.linktargetid;
   }
   else if(type==="SUBCATEGORY"){
    this.subcategoryid=this.linktargetid;
   } 
   else if(type==="BRAND"){
    this.brandid=this.linktargetid;
   } 
   else if(type==="PRODUCT"){
    this.productid=this.linktargetid;
   } 
}

fnGetLinkTypeId(type){
   if(type==="DEPARTMENT"){
     return this.deptid;
  }
  else if(type==="CATEGORY"){
    return this.categoryid;
  }
  else if(type==="SUBCATEGORY"){
    return this.subcategoryid;
  }
  else if(type==="BRAND"){
    return this.brandid;
  }
  else if(type==="PRODUCT"){
    return this.productid;
  } 
  else {
    return null;
  }
}

fnGetLinkTypeUrl(type){
  let para="";

  if(type==="DEPARTMENT"){
    for(let wbo of this.deptColl){
      if(this.deptid===wbo.deptid){
        para=wbo.deptid+"-"+this.fnRemoveSpecialCharater(wbo.deptname);        
        return para        
      }
    }
  }
  else if(type==="CATEGORY"){
    for(let wbo of this.categoryColl){
      if(this.categoryid===wbo.categoryid){
        para=wbo.deptid+"-"+this.fnRemoveSpecialCharater(wbo.deptname);        
        para=para+"/"+wbo.categoryid+"-"+this.fnRemoveSpecialCharater(wbo.categoryname);        
        return para        
      }
    }
  }
  else if(type==="SUBCATEGORY"){
    for(let wbo of this.subcategoryColl){
      if(this.subcategoryid===wbo.subcategoryid){
        para=wbo.deptid+"-"+this.fnRemoveSpecialCharater(wbo.deptname);        
        para=para+"/"+wbo.categoryid+"-"+this.fnRemoveSpecialCharater(wbo.categoryname);        
        para=para+"/"+wbo.subcategoryid+"-"+this.fnRemoveSpecialCharater(wbo.subcategoryname);        
        return para        
      }
    }
  } 
  else if(type==="BRAND"){
    for(let wbo of this.brandColl){
      if(this.brandid===wbo.brandid){
        para=wbo.brandid+"-"+this.fnRemoveSpecialCharater(wbo.brandname);        
        return para        
      }
    }
  }
  else if(type==="PRODUCT"){

    para=this.productid+"-"+this.fnRemoveSpecialCharater(this.productname);        
    return para   
  } 
  else {
    return para;
  }
}




private fnRemoveSpecialCharater(str){
  str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
  str = str.toString().replace(/ /g, "");
  return str;
}



}
