import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FibaseDataService } from 'src/app/services/dhukan/fibase-data.service';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database/database';
import { AdddeliveryoptionComponent } from '../adddeliveryoption/adddeliveryoption.component';
import * as moment from 'moment';
import { ExportgridComponent } from '../../grid/exportgrid/exportgrid.component';


@Component({
  selector: 'app-settlement',
  templateUrl: './settlement.component.html',
  styleUrls: ['./settlement.component.css']
})
export class SettlementComponent implements OnInit {


  deliveryorderColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
  isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="Settlement";

  filterby:String="DELIVERED";
  isselectlist:boolean=false;

  unassigncount:number=0;
  assigncount:number=0;
  pickedcount:number=0;
  deliverycount:number=0;
  receivedcount:number=0;
  allcount:number=0;

  fireshipment: any=[];

  
 searchtxt: any = "";
 columnDefs = [
  {headerName:'Order', field: 'orderno'},
  {headerName: 'Order Date', field: 'orderdate',type:'datetime', colcls:"tbtx"},
  {headerName: 'Customer', field: 'customername', colcls:"tbtx"},
  {headerName: 'Mobile', field: 'mobile', colcls:"tbtx"},
  {headerName: 'Items', field: 'productcount', colcls:"tbtx"},
  {headerName: 'Total', field: 'grandtotal', colcls:"tbtx"},
  {headerName: 'Order Status', field: 'orderno', colcls:"tbtx"},
  {headerName: 'City', field: 'cityname', colcls:"tbtx"},
  {headerName: 'Area', field: 'areaname', colcls:"tbtx"},
  {headerName: 'Warehouse', field: 'warehousename', colcls:"tbtx"},
  {headerName: 'Shipment Type', field: 'shipmenttype', colcls:"tbtx"},
  {headerName: 'Delivery Type', field: 'deliverytype', colcls:"tbtx" },
  {headerName: 'ExpectDate', field: 'expectdeliverydt', colcls:"tbtx" },
  {headerName: 'Paymode', field: 'paymode', colcls:"tbtx"},
  {headerName: 'Confirmed', field: 'confirmbyname', colcls:"tbtx"},
  {headerName: 'ConfirmOn', field: 'confirmon', colcls:"tbtx" },
  {headerName: 'ReadyToPick', field: 'readytopickbyname', colcls:"tbtx"},
  {headerName: 'ReadyToPickOn', field: 'readytopickon', colcls:"tbtx" },
  {headerName: 'Assigned', field: 'assignedbyname', colcls:"tbtx" },
  {headerName: 'AssignedOn', field: 'assignedon', colcls:"tbtx" },
  {headerName: 'Picked', field: 'pickedbyname', colcls:"tbtx" },
  {headerName: 'PickedOn', field: 'pickedon', colcls:"tbtx"},        
  {headerName: 'Delivered', field: 'deliveredbyname', colcls:"tbtx" },
  {headerName: 'DeliveredOn', field: 'deliveredon', colcls:"tbtx" },        
  {headerName: 'Received', field: 'receivedbyname', colcls:"tbtx" },
  {headerName: 'ReceivedOn', field: 'receivedon', colcls:"tbtx"},
 ]
 gridOptions = {
   enableactioncol: true,
   columnDefs: this.columnDefs,
   pageindex: 1
  };
  
  


  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,private loginService:LoginDataService,
    private productFindService: NgbModal)
     {  this.fnServiceChanges(); }

     //User Check
    fnServiceChanges(){
      this.loginService.serviceloginChange.subscribe((value) => {
            if(this.loginService.fnCheckUser()){                
                this.fnCheckPrivilage();
                if(this.isview){
                  this.isloading=true;
                  this.fnOnInitForm();
                }
                else{
                  this.fnUserFailedForm();
                }    
            }
            else{
              this.fnUserFailedForm();
            }
      });

      // this.firebasedataservice.fnGetShipmentList().subscribe(res=>{    
      //   this.fireshipment=res;
      //   this.fireshipment.forEach(element => {             
      //     if(element.id==="ADMIN"){           
      //       if(element.ischange===1){
      //         this.fngetOrderListView();
      //         this.fnFirebaseSetAdminChanges();
      //       }
      //     }                   
      //   })
      // })

    }
    
   

  ngOnInit() {
    try{
               
      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
       this.loginService.fnGetUserRoleList();          
      }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetStatus();
    this.fngetOrderListView();     
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }
  

  ngAfterViewInit(): void {
    
    
  }

  ngOnDestroy(): void {
   
   
  }

  
  //Grid
  
  //Event  
  fnActionEditClick(data) {
  
    this.fnEditDeliveryOrder(data.deliveryorderid,data.orderid);
  }

  fnActionExportClick()  {
  
    const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xl', backdrop: 'static' });
    modalRef.componentInstance.doctype = this.formname;
    modalRef.componentInstance.columnDefs = this.columnDefs;
    modalRef.componentInstance.contentColl = this.deliveryorderColl;
    modalRef.componentInstance.fnSetTable();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
  
    
    });
  }


  
  fngetStatus() {   
    
    var data = {
          'modifiedby':this.loginService.fnGetModUserId(),            
    }
    this.appService.fnGetDeliveryOrderStatus(data)
        .subscribe(
          (res)=>{                  
        
            if(res.json().status==200){                          
              let result=res.json().result[0];
             
              this.unassigncount=result.unassigncount;
              this.assigncount=result.assigncount;
              this.pickedcount=result.pickedcount;
              this.deliverycount=result.deliveredcount;
              this.receivedcount=result.receivedcount;
              this.allcount=result.allcount;
            }
            else if(res.json().status==400){                          
              this.toastr.error('Error', res.json().err_field);
            }
           
           
        },
          (err)=>{    
               
            this.toastr.error('Error', err);
        })
}

  fngetOrderListView() {   
    if(this.showProgress){
      return;
    }
    this.isselectlist=false;
    this.fnShowProgress();
      var data = {
        filterby:this.filterby,
        modifiedby:this.loginService.fnGetModUserId(), 
      }
      this.appService.getDeliveryOrderListView(data)
          .subscribe(
            (res)=>{           
              this.fnHideProgress();       
              if(res.json().status==200){                          
                this.deliveryorderColl=res.json().result;
                this.fnSetDataTable();

                this.fngetStatus();
                
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }                          
          },
            (err)=>{          
              this.fnHideProgress();       
              this.toastr.error('Error', err);
            })
  }


  fnAddDeliveryOrder(){
    try{
        
  
      this.router.navigate(['/AddDeliveryOrders']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

 
 
  fnEditDeliveryOrder(deptid,orderid){
    try {
     
        let navigationExtras: NavigationExtras = {
          queryParams: {
            'oid': orderid,
            'id': deptid,
            'ltype':'settlelist'
          }
        }
  
      this.router.navigate(['/AddShipment'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Delivery Order?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteDeliveryOrder(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteDeliveryOrder(deptid){
       var data = {
         'deliveryorderid': deptid,            
       }
      this.appService.fnDeleteDeliveryOrder(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
              this.toastr.success('', " The Delivery Order has been deleted successfully.");                 
              this.fngetOrderListView();
            }else{
              this.toastr.error('',res.json().err_field);   
          }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

  private fnSetDataTable(){
   
     
  }


  fnResetGrid(data) {
    this.searchtxt = data;
    this.gridOptions.pageindex = 1
  }
  
  fnCheckValue(values:any,index){
    
    this.deliveryorderColl[index].ischeck=values.currentTarget.checked;   

    this.isselectlist=false;
    const pindex = this.deliveryorderColl.findIndex(sku => sku.ischeck===true);

    if(pindex>-1){   
      
    this.isselectlist=true;
    }

  }



  //Delivery Order Status
  
  fnDeliveryOrderReceived(){
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Received Shipment?')
    .then((confirmed) => 
          {
            if(confirmed){
              this.fnShowProgress();
      
              for(let abo of this.deliveryorderColl){
                if(abo.ischeck){
                  this.fnSetReceivedDeliveryOrder(abo.deliveryorderid)
                }
                
              }
        
              this.fnHideProgress();
            }          
        }        
    );   

  }

  fnSetReceivedDeliveryOrder(deliveryorderid){

  
      var data = {
        deliveryorderid:deliveryorderid,
        isreceived:true,
        modifiedby:this.loginService.fnGetModUserId(),
      }
      this.appService.fnSetDeliveryOrderReceived(data)
          .subscribe(
            (res)=>{           
             
              if(res.json().status==200){                          
                this.fngetOrderListView();
              }
              else if(res.json().status==400){     
                this.fnHideProgress();                        
                this.toastr.error('Error', res.json().err_field);
              }                          
          },
            (err)=>{          
              this.fnHideProgress();       
              this.toastr.error('Error', err);
      })
  }
  
  fnSetUnReceivedDeliveryOrder(deliveryorderid){

  
    var data = {
      deliveryorderid:deliveryorderid,
      isreceived:false,
      modifiedby:this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderReceived(data)
        .subscribe(
          (res)=>{           
           
            if(res.json().status==200){                          
              this.fngetOrderListView();
            }
            else if(res.json().status==400){     
              this.fnHideProgress();                        
              this.toastr.error('Error', res.json().err_field);
            }                          
        },
          (err)=>{          
            this.fnHideProgress();       
            this.toastr.error('Error', err);
    })
}


//RevertStatus
fnConfirmRevertStatus(type){
  this.confirmationDialogService.confirm('Confirm..', 'Do you  want to '+type+' Shipment?')
  .then((confirmed) => 
        {
          if(confirmed){
            this.fnShowProgress();
    

            for(let abo of this.deliveryorderColl){
              if(abo.ischeck){
                // if(type==='UnAssign'){
                //   this.fnSetUnAssignDeliveryOrder(abo.deliveryorderid)
                // }
                // else if(type==='UnPick'){
                //   this.fnSetUnPickDeliveryOrder(abo.deliveryorderid)
                // }
                // else if(type==='UnDelivery'){
                //   this.fnSetUnDeliveredDeliveryOrder(abo.deliveryorderid)
                // }
                // else if(type==='UnReceive'){
                //   this.fnSetUnReceivedDeliveryOrder(abo.deliveryorderid)
                // }
              }
              
            }
      
            this.fnHideProgress();
          }          
      }        
  );   

}


  //Firebase

  // fnFirebaseSetAdminChanges(){
  //   // this.fireshipment.forEach(element => {             
  //   //   if(element.id==="ADMIN"){           
  //   //     if(element.ischange===1){
  //   //       this.fngetOrderListView();
  //   //     }
  //   //   }                   
  //   // })


  //   this.firestore.database.ref('ShipmentChanges/').child("ADMIN").set({  id:"ADMIN",ischange:0})
  // }

  // fnFirebaseSetDeliverBoyChanges(userid){
    
  //   let _ischeck:boolean=false;
  //   let _idenid=""


  //    this.fireshipment.forEach(element => {             
  //       if(element.id===userid){       
  //         _idenid= element.idenid;   
  //         _ischeck=true;
  //       }                   
  //    })
      
  //    if(_ischeck){       
  //       this.firestore.database.ref('ShipmentChanges/').child(_idenid).set({
  //         idenid:_idenid,
  //         id:userid,
  //         ischange:1
  //       })
  //    }
  //    else{
  //       let  bloglist:AngularFireList<any>;
  //       bloglist = this.firestore.list('/ShipmentChanges');
  //       var _odid =bloglist.query.ref.push();


  //       this.firestore.database.ref('ShipmentChanges/').child(_odid.key).set({
  //         idenid:_odid.key,
  //         id:userid,
  //         ischange:1
  //       })
      
  //    }





  // }


  
  
      
  fnPrintData(){
    try{
    
                        
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnExportData(){
    try{
    
                          
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }


  

fnMomentDateFormatter(params) {
  if (params.value) {
    return moment(params.value).format('DD-MM-YYYY');//hh:mm a  
  }
  else {
    return '';
  }
}

fnMomentDateTimeFormatter(params) {
  if (params.value) {
    return moment(params.value).format('DD-MM-YYYY hh:mm a');//  
  }
  else {
    return '';
  }
  
}
  

  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }
  

  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }






}
