import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { AddbrandpopComponent } from '../../brand/addbrandpop/addbrandpop.component';
import { AddcategorypopComponent } from '../../category/addcategorypop/addcategorypop.component';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { AdddepartmentpopComponent } from '../../department/adddepartmentpop/adddepartmentpop.component';
import { ProductfaqComponent } from '../../productfaq/productfaq.component';
import { ProductfindComponent } from '../../productfind/productfind.component';
import { ProductspecificationComponent } from '../../productspecification/productspecification.component';
import { AdduompopComponent } from '../../uom/adduompop/adduompop.component';
import { ProductimageviewComponent } from '../productimageview/productimageview.component';


@Component({
  selector: 'app-editproductsku',
  templateUrl: './editproductsku.component.html',
  styleUrls: ['./editproductsku.component.css']
})
export class EditproductskuComponent implements OnInit {


  @Input() doctype: string;
  @Input() req: {};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();


  @Input() productid: number = 0;
  @Input() skuid: number = 0;
  @Input() productBO: any = {};

  @Input() ismainsku: boolean = false;

  isadduom: boolean = false;
  isadddepartment: boolean = false;
  isaddcategory: boolean = false;
  isaddbrand: boolean = false;

  productform = new FormGroup({
    productid: new FormControl(0),
    skuid: new FormControl(0),
    sku: new FormControl('', Validators.required),
    autosku: new FormControl(''),
    productname: new FormControl('', Validators.required),
    shortdescr: new FormControl(''),
    fulldescr: new FormControl(''),
    uomqty: new FormControl('', Validators.required),
    uomid: new FormControl(null, Validators.required),
    mrp: new FormControl(0),
    sellingprice: new FormControl(0),
    currentstock: new FormControl(0),
    image: new FormControl(null),
    strImage: new FormControl(null),
    ispublished: new FormControl(false),
    productcode: new FormControl(''),
    deptid: new FormControl(0),
    categoryid: new FormControl(0),
    subcategoryid: new FormControl(0),
    brandid: new FormControl(0),
    vendorid: new FormControl(0),
    taxgrpid: new FormControl(0),
    foodsymbolid: new FormControl(0),
    certificateid: new FormControl(0),
    issize: new FormControl(false),
    iscolor: new FormControl(false),
    sizeid: new FormControl(''),
    colorid: new FormControl(''),
    cost: new FormControl(0),
    inactive: new FormControl(false),
  });

  //set image
  image;
  strImage: any;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;

  //Master
  uomColl: any = [];

  @Input() skuprice: any = [];
  @Input() skuimages: any = [];
  @Input() productrelated: any = [];
  @Input() productspecification: any = [];
  @Input() productfaq: any = [];

  //Master
  deptColl: any = [];
  categoryColl: any = [];
  subcategoryColl: any = [];
  brandColl: any = [];
  warehouseColl: any = [];
  taxgroupColl: any = [];
  vendorColl: any = [];
  certificateColl: any = [];
  foodsymbolColl: any = [];
  sizeColl: any = []
  colorColl: any = []
  productcolorsizeColl: any = [];

  sizeid: String = "";
  colorid: String = "";

  filtertab: String = "RELATED";

  imagesize: number = 70;

  taxrate: number = 0;


  warehouseid: number = 0;
  warehousename: any = "";

  constructor(private activeModal: NgbActiveModal, private productFindService: NgbModal, private confirmationDialogService: ConfirmationDialogService,
    private appService: DhukanDataService, private loginService: LoginDataService, private toastr: ToastrService, private imageCompress: NgxImageCompressService) { }

  ngOnInit() {

    this.fngetUom();
    this.fngetWarehouse();
    this.fngetSize();
    this.fngetColor();
   // this.fngetProductColorSize();



    this.isadduom = this.loginService.fnCheckUserRoleAction("UOM", 'CREATE')
    this.isadddepartment = this.loginService.fnCheckUserRoleAction("Department", 'CREATE')
    this.isaddcategory = this.loginService.fnCheckUserRoleAction("Category", 'CREATE')
    this.isaddbrand = this.loginService.fnCheckUserRoleAction("Brand", 'CREATE')


  }

  public fnInitializeProductFormGroup() {
    this.productform.patchValue({
      productid: this.productid,
      skuid: this.productBO.skuid,
      sku: this.productBO.sku,
      autosku: this.productBO.autosku,
      productname: this.productBO.productname,
      shortdescr: this.productBO.shortdescr,
      fulldescr: this.productBO.fulldescr,
      uomqty: this.productBO.uomqty,
      uomid: this.productBO.uomid,
      mrp: this.productBO.mrp,
      sellingprice: this.productBO.sellingprice,
      currentstock: this.productBO.currentstock,
      image: null,
      strImage: null,
      ispublished: this.productBO.ispublished,
      productcode: this.productBO.productcode,
      title: this.productBO.title,
      deptid: this.productBO.deptid,
      categoryid: this.productBO.categoryid,
      subcategoryid: this.productBO.subcategoryid,
      brandid: this.productBO.brandid,
      vendorid: this.productBO.vendorid,
      taxgrpid: this.productBO.taxgrpid,
      foodsymbolid: this.productBO.foodsymbolid,
      certificateid: this.productBO.certificateid,
      issize: this.productBO.issize,
      iscolor: this.productBO.iscolor,
      sizeid: this.productBO.sizeid,
      colorid: this.productBO.colorid,
      cost: this.productBO.cost,
      inactive: false,
    })
    this.sizeid = this.productBO.sizeid;
    this.colorid = this.productBO.colorid;

    //this.fnApplySizeCheck()
  }

  fnValidateRequiredFilter() {

    if (this.ismainsku) {
      this.productform.controls['productcode'].setValidators(Validators.required);
      this.productform.controls['deptid'].setValidators(Validators.required);
      this.productform.controls['categoryid'].setValidators(Validators.required);
      this.productform.controls['brandid'].setValidators(Validators.required);
      this.productform.controls['taxgrpid'].setValidators(Validators.required);

      this.fngetDepartment();
      this.fngetCategory();
      this.fngetBrand();
      this.fngetTaxGroup();
      this.fngetVendor();
      this.fngetFoodSymbol();
      this.fngetCertificate();

    }


  }



  fngetSize() {

    var data = {
      'productid': this.productid,
      'skuid': this.productform.get('skuid').value
    }
    this.appService.getProductSize(data)
      .subscribe(
        (res) => {


          if (res.json().status == 200) {
            this.sizeColl = res.json().result;

            for (let abo of this.sizeColl) {
              if (abo.isselect ===1) {
                abo.ischeck = true;
              } else {
                abo.ischeck = false;
              }
            }

             //console.log(this.sizeColl);
            // this.fnApplySizeCheck();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {

          this.toastr.error('Error', err);
        })
  }



  fnGetSizeId() {
    var _check = "";

    for (let abo of this.sizeColl) {
      if (abo.ischeck === true) {
        if (_check === "") {
          _check = abo.sizeid;
        }
        else {
          _check = _check + "," + abo.sizeid;
        }
      }
    }

    return _check;

  }

  // fnApplySizeCheck() {


  //   if (this.sizeid) {
  //     let aColl = this.sizeid.split(",")

  //     for (let abo of aColl) {
  //       for (let pbo of this.sizeColl) {

  //         if (pbo.sizeid === Number(abo)) {
  //           pbo.ischeck = true;
  //         }
  //       }
  //     }
  //   }
  // }

  fnApplyallBtnClick() {
    for (let pbo of this.sizeColl) {
      pbo.ischeck = true;
    }
  }

  fnUnCheckBtnClick() {
    for (let pbo of this.sizeColl) {
      pbo.ischeck = false;
    }
  }
  checkValue(values: any, index) {
    this.sizeColl[index].ischeck = values.currentTarget.checked;

    // if (values.currentTarget.checked == true) {
    //   const colorindex = this.colorColl.findIndex(sku => sku.ischeck === true);
    //   const colorsizeindex = this.productcolorsizeColl.findIndex(row => row.sizeid === this.sizeColl[index].sizeid);
    //   if (colorsizeindex > -1) {
    //     for (let abo of this.productcolorsizeColl) {
    //       if (this.sizeColl[index].sizeid == abo.sizeid) {
    //         abo.isdelete = false;
    //       }
    //     }

    //   }else{

    //     if (colorindex > -1) {

    //       for (let pBo of this.colorColl) {
    //         const colorsizeindex1 = this.productcolorsizeColl.findIndex(row => row.sizeid === this.sizeColl[index].sizeid && row.colorid === pBo.colorid);
    //         if (colorsizeindex1 > -1) {

    //         }
    //         else {
    //           var sdata = {
    //             productid: this.productid,
    //             skuid: this.productform.get('skuid').value,
    //             warehouseid: this.warehouseid,
    //             warehousename: this.warehousename,
    //             sizeid: this.sizeColl[index].sizeid,
    //             size: this.sizeColl[index].size,
    //             colorid: pBo.colorid,
    //             color: pBo.color,
    //             modifiedby: this.loginService.fnGetModUserId()
    //           }
    //           this.productcolorsizeColl.push(sdata);
    //         }
    //       }

    //     }
    //     else {
    //       var data = {
    //         productid: this.productid,
    //         skuid: this.productform.get('skuid').value,
    //         warehouseid: this.warehouseid,
    //         warehousename: this.warehousename,
    //         sizeid: this.sizeColl[index].sizeid,
    //         size: this.sizeColl[index].size,
    //         colorid: 0,
    //         modifiedby: this.loginService.fnGetModUserId()
    //       }
    //       this.productcolorsizeColl.push(data);

    //     }


    //   }


    // }
    // else {
    //   for (let abo of this.productcolorsizeColl) {
    //     if (this.sizeColl[index].sizeid == abo.sizeid) {
    //       abo.isdelete = true;
    //     }
    //   }
    

    // }

  }




  fngetColor() {

    var data = {
      'productid': this.productid,
      'skuid': this.productform.get('skuid').value
    }
    this.appService.getProductColor(data)
      .subscribe(
        (res) => {


          if (res.json().status == 200) {
            this.colorColl = res.json().result;
            
            for (let abo of this.colorColl) {
              if (abo.isselect ===1) {
                abo.ischeck = true;
              } else {
                abo.ischeck = false;
              }
            }

            // this.fnApplyColorCheck();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {

          this.toastr.error('Error', err);
        })
  }



  fnGetColorId() {
    var _check = "";

    for (let abo of this.colorColl) {
      if (abo.ischeck === true) {
        if (_check === "") {
          _check = abo.colorid;
        }
        else {
          _check = _check + "," + abo.colorid;
        }
      }
    }

    return _check;

  }


  fnApplyallColorBtnClick() {
    for (let pbo of this.colorColl) {
      pbo.ischeck = true;
    }
  }

  fnUnCheckColorBtnClick() {
    for (let pbo of this.colorColl) {
      pbo.ischeck = false;
    }
  }
  checkValueColor(values: any, index) {
    this.colorColl[index].ischeck = values.currentTarget.checked;



    
    // if (values.currentTarget.checked == true) {
    //   const sizeindex = this.sizeColl.findIndex(sku => sku.ischeck === true);
    //   const colorsizeindex = this.productcolorsizeColl.findIndex(row => row.colorid === this.colorColl[index].colorid);
    //   if (colorsizeindex > -1) {
    //     for (let abo of this.productcolorsizeColl) {
    //       if (this.colorColl[index].colorid == abo.colorid) {
    //         abo.isdelete = false;
    //       }
    //     }

    //   }else{

    //     if (sizeindex > -1) {

    //       for (let pBo of this.sizeColl) {
    //         const colorsizeindex1 = this.productcolorsizeColl.findIndex(row => row.colorid === this.colorColl[index].colorid && row.sizeid === pBo.sizeid);
    //         if (colorsizeindex1 > -1) {

    //         }
    //         else {
    //           var sdata = {
    //             productid: this.productid,
    //             skuid: this.productform.get('skuid').value,
    //             warehouseid: this.warehouseid,
    //             warehousename: this.warehousename,
    //             colorid: this.colorColl[index].colorid,
    //             color: this.colorColl[index].color,
    //             sizeid: pBo.sizeid,
    //             size: pBo.size,
    //             modifiedby: this.loginService.fnGetModUserId()
    //           }
    //           this.productcolorsizeColl.push(sdata);
    //         }
    //       }

    //     }
    //     else {
    //       var data = {
    //         productid: this.productid,
    //         skuid: this.productform.get('skuid').value,
    //         warehouseid: this.warehouseid,
    //         warehousename: this.warehousename,
    //         sizeid: this.sizeColl[index].sizeid,
    //         size: this.sizeColl[index].size,
    //         colorid: 0,
    //         modifiedby: this.loginService.fnGetModUserId()
    //       }
    //       this.productcolorsizeColl.push(data);

    //     }
    //   }
    // }
    // else {
    //   for (let abo of this.productcolorsizeColl) {
    //     if (this.sizeColl[index].colorid == abo.colorid) {
    //       abo.isdelete = true;
    //     }
    //   }    
    // }

  }




  fngetDepartment() {
    var data = {
      'deptid': 0,
    }
    this.appService.getDepartment(data)
      .subscribe(
        (res) => {
          this.deptColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetCategory() {
    let _deptid = this.productform.get('deptid').value;
    var data = {
      'categoryid': 0,
      'deptid': _deptid,
    }

    if (_deptid > 0) {
      this.appService.getCategory(data)
        .subscribe(
          (res) => {
            this.categoryColl = res.json().result;

            if (this.categoryColl.length === 1 && this.skuid === 0) {
              this.productform.patchValue({ 'categoryid': this.categoryColl[0].categoryid })
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          })
    }
    else {
      this.categoryColl = [];
    }
  }


  fngetBrand() {
    var data = {
      'brandid': 0,
    }
    this.appService.getBrand(data)
      .subscribe(
        (res) => {
          this.brandColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetTaxGroup() {
    var data = {
      'taxgroupid': 0,
    }
    this.appService.getTaxGroup(data)
      .subscribe(
        (res) => {
          this.taxgroupColl = res.json().result;
          this.fnChangeTaxgroup()
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fnChangeTaxgroup() {

    for (let abo of this.taxgroupColl) {
      if (this.productform.get('taxgrpid').value === abo.taxgrpid) {
        this.taxrate = abo.taxrate;
      }
    }

    this.fnCalcSpWoTaxAmount();
  }

  fngetVendor() {
    var data = {
      'vendorid': 0,
    }
    this.appService.getVendor(data)
      .subscribe(
        (res) => {
          this.vendorColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetCertificate() {
    var data = {
      'certificateid': 0,
    }
    this.appService.getCertificate(data)
      .subscribe(
        (res) => {
          this.certificateColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetFoodSymbol() {
    var data = {
      'foodsymbolid': 0,
    }
    this.appService.getFoodSymbol(data)
      .subscribe(
        (res) => {
          this.foodsymbolColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }



  fngetUom() {
    var data = {
      'uomid': 0,
    }
    this.appService.getUom(data)
      .subscribe(
        (res) => {
          this.uomColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  fngetWarehouse() {
    var data = {
      'warehouseid': 0,
    }
    this.appService.getWarehouse(data)
      .subscribe(
        (res) => {
          this.warehouseColl = res.json().result;

          if (this.warehouseColl.length > 0) {
            this.warehousename = this.warehouseColl[0].warehousename;
            this.warehouseid = this.warehouseColl[0].warehouseid;
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetProductColorSize() {

    var data = {
      'productid': this.productid,
      'skuid': this.productform.get('skuid').value
    }
    this.appService.getProductColorSize(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            this.productcolorsizeColl = res.json().result;
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {

          this.toastr.error('Error', err);
        })
  }




  fnGetAutoCodeProduct() {
    var data = {}

    this.appService.getAutoCodeProduct(data).subscribe(
      (res) => {

        if (res.json().status === 200) {

          let sku = res.json().result[1][0].sku;
          this.productform.patchValue({ sku: sku });

        }

      },
      (err) => {
        this.toastr.error('Error', err);
      });
  }

  //Save Btn Click
  fnSaveBtnClick(mode) {

    try {
      this.submitted = true;
      this.successfully = false;


      //Validate
      if (this.productform.valid) {


        this.fnShowProgress();

        //Check
        var data = {
          productid: this.productid,
          skuid: this.productform.get('skuid').value,
          sku: this.productform.get('sku').value,
          autosku: this.productform.get('autosku').value,
          productname: this.productform.get('productname').value,
          shortdescr: this.productform.get('shortdescr').value,
          fulldescr: this.productform.get('fulldescr').value,
          uomqty: this.productform.get('uomqty').value,
          uomid: this.productform.get('uomid').value,
          mrp: this.productform.get('mrp').value,
          sellingprice: this.productform.get('sellingprice').value,
          currentstock: this.productform.get('currentstock').value,
          ispublished: this.productform.get('ispublished').value,
          'inactive': 0,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
          'productcode': this.productform.get('productcode').value,
          'title': this.productBO.title,
          'deptid': this.productform.get('deptid').value,
          'categoryid': this.productform.get('categoryid').value,
          'subcategoryid': this.productform.get('subcategoryid').value,
          'brandid': this.productform.get('brandid').value,
          'vendorid': this.productform.get('vendorid').value,
          'taxgrpid': this.productform.get('taxgrpid').value,
          'foodsymbolid': this.productform.get('foodsymbolid').value,
          'certificateid': this.productform.get('certificateid').value,
          'issize': this.productform.get('issize').value,
          'iscolor': this.productform.get('iscolor').value,
          'cost': this.productform.get('cost').value,
          'sizeid': this.fnGetSizeId(),
          'colorid': this.fnGetColorId(),
          skuprice: this.skuprice,
          skuimages: this.skuimages,
          productrelated: this.productrelated,
          productcrosssell: [],
          productfaq: this.productfaq,
          productspecification: this.productspecification,
          productcombo: [],
          productcolor: this.colorColl,
          productsize: this.sizeColl,
        }
        if (this.productid > 0) {
          this.fnSetProductSku(data);
        }
      }
    } catch (ex) {


      this.fnHideProgress();
      this.fnShowErrorMsg(ex);
    }
  }


  fnSetProductSku(data) {

    this.appService.fnSetProductSku(data).subscribe(
      (res) => {

        if (res.json().result != "") {
          this.toastr.success('', "The product has been updated successfully.");

          if (data.skuid > 0) {
            this.fnInsertActivityLogSku('EDIT', this.productid, data.sku + "-" + data.productname);
          } else {
            this.fnInsertActivityLogSku('INSERT', this.productid, data.sku + "-" + data.productname);
          }

          this.fnHideProgress();

          this.accept();
        }
      },
      (err) => {
        this.fnHideProgress();
        console.log(err)
        this.toastr.error('Error', err);
      });

  }




  private fnEditSkuImages(event) {
    try {

      if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;

        for (let i = 0; i < filesAmount; i++) {

          var file: File = event.target.files[i];


          // if (file.size / 1000 > this.imagesize) {
          //   continue;
          // }

          const fileReader: FileReader = new FileReader();

          fileReader.onload = (event) => {

            this.image = fileReader.result;
            this.strImage = this.image.split(',')[1];

            this.imageCompress.compressFile(this.image, 75, 50).then(
              result => {
                this.strImage = result.split(',')[1];
                var _skuimages =
                {
                  productid: this.productid,
                  skuid: 0,
                  picid: 0,
                  image: result,
                  strImage: this.strImage,
                  isclear: 0,
                  picurl: ""
                }
                this.skuimages.push(_skuimages);
              }
            );

          }

          fileReader.readAsDataURL(event.target.files[i]);
        }




      }


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }

  }




  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }

  // Sku  Images
  removeimage(pictureindex) {


    if (pictureindex > -1) {

      if (this.skuimages[pictureindex].picid > 0) {
        this.skuimages[pictureindex].isclear = 1;
      }
      else {
        this.skuimages.splice(pictureindex, 1);
      }

    }



  }

  public accept() {

    this.emitData.emit(true);
    this.activeModal.close(true);
  }

  public decline() {
    this.activeModal.dismiss();
  }


  //Specification Product
  fnAddSpecificationProduct() {
    const modalRef = this.productFindService.open(ProductspecificationComponent, { size: 'lg' });
    modalRef.componentInstance.doctype = "PRODUCT SPECIFICATION";
    modalRef.componentInstance.mode = "ADD";

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      var _specification = {
        specificationid: receivedEntry.specificationid,
        specificationname: receivedEntry.specificationname,
        value: receivedEntry.value,
        productid: 0,
        skuid: 0
      }
      this.productspecification.push(_specification);

    });

  }

  fnDeleteSpecificationProduct(prodindex) {
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Faq?')
      .then((confirmed) => {
        if (confirmed) {
          this.productspecification.splice(prodindex, 1);

        }
      });
  }

  fnEditSpecificationProduct(prodindex) {

    const modalRef = this.productFindService.open(ProductspecificationComponent, { size: 'lg' });
    modalRef.componentInstance.doctype = "PRODUCT SPECIFICATION";
    modalRef.componentInstance.mode = "EDIT";

    modalRef.componentInstance.specificationid = this.productspecification[prodindex].specificationid;
    modalRef.componentInstance.editorValue = this.productspecification[prodindex].value;


    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


      this.productspecification[prodindex].specificationid = receivedEntry.specificationid;
      this.productspecification[prodindex].specificationname = receivedEntry.specificationname;
      this.productspecification[prodindex].value = receivedEntry.value;

    });
  }



  //Related Product
  fnAddRelatedProduct() {


    const modalRef = this.productFindService.open(ProductfindComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "RELATED PRODUCT";

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


      for (let wbo of receivedEntry) {

        if (this.productrelated.length === 0) {
          wbo.relatedproductid = wbo.productid;
          wbo.relatedskuid = wbo.skuid;
          this.productrelated.push(wbo);
        }
        else {

          const exitindex = this.productrelated.findIndex(sku => sku.productid === wbo.productid && sku.skuid === wbo.skuid);

          if (exitindex < 0) {
            wbo.relatedproductid = wbo.productid;
            wbo.relatedskuid = wbo.skuid;
            this.productrelated.push(wbo);
          }

        }


      }
    });


  }

  fnDeleteRelatedProduct(prodindex) {
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Related Product')
      .then((confirmed) => {
        if (confirmed) {

          this.productrelated.splice(prodindex, 1);

        }
      });
  }




  //Faq Product
  fnAddFaqProduct() {
    const modalRef = this.productFindService.open(ProductfaqComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "PRODUCT FAQ";

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


      var _faq = {
        question: receivedEntry.question,
        answer: receivedEntry.answer,
        productid: 0,
        skuid: 0
      }
      this.productfaq.push(_faq);

    });

  }

  fnDeleteFaqProduct(prodindex) {
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Faq?')
      .then((confirmed) => {
        if (confirmed) {

          if (prodindex > -1) {
            this.productfaq.splice(prodindex, 1);
          }
        }

      });
  }

  fnEditFaqProduct(skuid, sno, prodindex) {
    const modalRef = this.productFindService.open(ProductfaqComponent, { size: 'lg' });
    modalRef.componentInstance.doctype = "PRODUCT FAQ";

    modalRef.componentInstance.question = this.productfaq[prodindex].question;
    modalRef.componentInstance.answer = this.productfaq[prodindex].answer;



    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


      this.productfaq[prodindex].question = receivedEntry.question;
      this.productfaq[prodindex].answer = receivedEntry.answer
    });

  }


  //Pop
  private fnAddUom() {

    const modalRef = this.productFindService.open(AdduompopComponent, { size: <any>'l' });
    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetUom();

      if (receivedEntry > 0) {
        this.productform.patchValue({ uomid: receivedEntry })
      }
    })

  }

  private fnAddDepartment() {

    const modalRef = this.productFindService.open(AdddepartmentpopComponent, { size: <any>'l' });
    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetDepartment();

      if (receivedEntry > 0) {
        this.productform.patchValue({ deptid: receivedEntry })
      }
    })

  }

  private fnAddCategory() {

    const modalRef = this.productFindService.open(AddcategorypopComponent, { size: <any>'l' });

    modalRef.componentInstance.deptid = this.productform.get('deptid').value
    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetCategory();

      if (receivedEntry > 0) {
        this.productform.patchValue({ categoryid: receivedEntry })
      }
    })

  }

  private fnAddBrand() {

    const modalRef = this.productFindService.open(AddbrandpopComponent, { size: <any>'l' });
    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetBrand();

      if (receivedEntry > 0) {
        this.productform.patchValue({ brandid: receivedEntry })
      }
    })

  }

  private fnCalcSpWoTaxAmount() {

    for (let abo of this.skuprice) {

      var _sptaxamt = this.taxrate * (abo.sellingprice / 100);

      abo.sellingpricewotax = abo.sellingprice - _sptaxamt

      abo.sellingpricewotax = abo.sellingpricewotax.toFixed(2);


    }

  }


  fnShowZoomProduct(image, picurl) {
    const modalRef = this.productFindService.open(ProductimageviewComponent, { size: <any>'l' },);
    modalRef.componentInstance.doctype = "";
    modalRef.componentInstance.picurl = picurl;
    modalRef.componentInstance.image = image;
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


    });

  }



  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: "Product",
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }



  fnInsertActivityLogSku(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: "SKU",
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }


  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    console.log(ex)
    this.toastr.warning('Internal Error', ex);
  }



}
