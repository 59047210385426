import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { AppSettings } from '../constants/constants';
import { PagelayoutorderComponent } from 'src/app/components/page/pagelayoutorder/pagelayoutorder.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class LoginDataService {

  public islogin: boolean = false;

  serviceloginChange: Subject<boolean> = new Subject<boolean>();


  userBO: any = {};
  userrolerightsColl: any = [];
  userView: any = {};

  constructor(private http: Http, public router: Router, private productFindService: NgbModal) { }


  fnpostInputParams(url, params) {
    const headers = new Headers({
      // 'Content-Type':  "application/JSON",
      'token': (localStorage.adtoken === undefined) ? '' : JSON.parse(localStorage.adtoken)
    });

    return this.http.post(AppSettings.base_url + url, params, { headers: headers });
  }




  //Get UserRole List
  fnGetUserRoleList() {
    var data = {
      'userid': 0,
    }

    this.fnpostInputParams(AppSettings.getUserRoleRightsUserUrl, data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            var _res = res.json().result;

            if (_res.length) {


              if (_res[0].length > 0) {
                this.userBO = _res[0][0];
              }
              else {
                this.userBO = {};
              }

              this.userrolerightsColl = _res[1];
            }

            this.userView = {
              userBO: this.userBO,
              userrolerightsColl: this.userrolerightsColl
            }


            this.serviceloginChange.next(this.userView);
          }
          else if (res.json().status == 400) {
            this.fnShowErrorMsg('Error' + res.json().err_field);
          }
        },
        (err) => {

          this.fnShowErrorMsg('Error' + err);
        })

  }




  fnCheckUser() {

    if (this.userBO.userid) {
      return true;
    }
    // else if(localStorage.adtoken !== undefined){
    //   return true;
    // }
    else {
      return false;
    }

  }
  fnGetModUserId() {
    if (this.userBO.userid) {
      return this.userBO.userid;
    }
    else {
      return 0;
    }
  }

  fnUserFaildRedirect() {
    if (!this.userBO) {
      this.router.navigate(['/Login']);
    }
  }

  fnCheckAdminUser() {

    if (this.userBO.userid) {
      return this.userBO.isadmin;
    }
    else {
      return 0;
    }
  }


  fnCheckUserRoleAll(formname) {

    if (this.userrolerightsColl) {

      const index = this.userrolerightsColl.findIndex(abo => abo.userappformname === formname);
      if (index > -1) {
        var _pBO = this.userrolerightsColl[index];

        if (_pBO.uappcreate || _pBO.uappalter || _pBO.uappdelete || _pBO.uappview || _pBO.uappprint || _pBO.uappexport) {
          return true;
        }
        else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  fnCheckUserRoleAction(formname, action) {

    if (this.userrolerightsColl) {

      const index = this.userrolerightsColl.findIndex(abo => abo.userappformname === formname);
      if (index > -1) {
        var _pBO = this.userrolerightsColl[index];

        if (action === 'CREATE' && _pBO.uappcreate) {
          return true;
        }
        else if (action === 'ALTER' && _pBO.uappalter) {
          return true;
        }
        else if (action === 'DELETE' && _pBO.uappdelete) {
          return true;
        }
        else if (action === 'VIEW' && _pBO.uappview) {
          return true;
        }
        else if (action === 'PRINT' && _pBO.uappprint) {
          return true;
        }
        else if (action === 'EXPORT' && _pBO.uappexport) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }

    } else {
      return false;
    }
  }

  fnDecimalBlur(event) {
    if (event.target.value !== '') {

      event.target.value = parseFloat(event.target.value).toFixed(2)

    }
  }

  fnShowPagePreview(pageid) {
    if (Number(pageid) === 0) {
      return
    }

    var data = {
      'pageid': pageid,
    }

    this.fnpostInputParams(AppSettings.getPageUrl, data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            var _res = res.json().result;

            if (_res.length) {

              let pagelayoutColl: any = [];


              let _pagelayout = res.json().result[1];
              let _pagedetails = res.json().result[2];

              for (let pbo of _pagelayout) {

                var pagedetails = []
                for (let mbo of _pagedetails) {
                  if (mbo.pagelayoutid === pbo.pagelayoutid) {
                    pagedetails.push(mbo)
                  }
                }
                pbo.pagedetails = pagedetails;

                pagelayoutColl.push(pbo);
              }


              const modalRef = this.productFindService.open(PagelayoutorderComponent, { size: <any>'l' });
              modalRef.componentInstance.doctype = "Page Layout";
              modalRef.componentInstance.mode = "VIEW";
              modalRef.componentInstance.fnSetPageLayout(pagelayoutColl);
              

            }
            else if (res.json().status == 400) {
              this.fnShowErrorMsg('Error' + res.json().err_field);
            }
          }
        },
        (err) => {

          this.fnShowErrorMsg('Error' + err);
        })
  }


  private fnShowErrorMsg(ex) {
    console.log(ex);
  }



}
