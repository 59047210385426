import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addcustomer',
  templateUrl: './addcustomer.component.html',
  styleUrls: ['./addcustomer.component.css']
})
export class AddcustomerComponent implements OnInit {



//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;

genderColl:any=[];

//Edit Get Values
customerColl:any=[];
customerid:number=0

customerform=new FormGroup({         
  firstname:new FormControl('',Validators.required),           
  lastname:new FormControl('',Validators.required), 
  mobile:new FormControl('',Validators.required), 
  email:new FormControl('',Validators.required), 
  referralcode:new FormControl(''), 
  refercode:new FormControl(''), 
  gender:new FormControl(''), 
  dob:new FormControl(''), 
  commlanguageid:new FormControl(''), 
  altmobile:new FormControl(''), 
  customerid:new FormControl(0),      
  inactive:new FormControl(false),     
});


constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private datePipe: DatePipe,private loginService:LoginDataService) { 
     this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.customerid = params.id
      }  
     });
}

ngOnInit() {
   try{
       this.isaddmode=true;
       this.customerform.reset();
       this.fnInitializecustomerFormGroup(); 

       if(this.customerid>0){
         this.fngetCustomer();
       }

       this.fnGetGender();
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

fnInitializecustomerFormGroup(){
   this.customerform.setValue({
    firstname:'',
    lastname:'',   
    mobile:'',
    email:'',
    referralcode:'',
    refercode:'',
    gender:'',
    dob:'',
    commlanguageid:'',
    altmobile:'',
    customerid:0,      
    inactive:false,      
   })
}



fnGetGender(){
  this.genderColl.push({name:'M',displayname:'Male'})
  this.genderColl.push({name:'F',displayname:'Female'})
  
}




//Get 
fngetCustomer() {        
     var data = {
       'customerid':this.customerid,            
     }
     this.appService.getCustomerAdmin(data)
     .subscribe(
       (res)=>{                  
         this.customerColl=res.json().result;
          
         this.fnEditMode(this.customerColl[0]);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.customerform.valid){
         //Check
         var data = {
               'customerid': this.customerid,       
               'firstname':this.customerform.get('firstname').value,            
               'lastname':this.customerform.get('lastname').value,           
               'mobile':this.customerform.get('mobile').value,            
               'email':this.customerform.get('email').value,  
               'referralcode':this.customerform.get('referralcode').value,                           
               'gender':this.customerform.get('gender').value,  
               'dob':this.fnFormatDatetime(this.customerform.get('dob').value),            
               'commlanguageid':this.customerform.get('commlanguageid').value,  
               'refercode':this.customerform.get('refercode').value, 
               'altmobile':this.customerform.get('altmobile').value, 
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
         }
         this.appService.fnCheckCustomerAdmin(data).subscribe(
           (res)=>{
               var _checkresult=res.json().result[0].checkfield;
             
               if(_checkresult==="done"){
                     if (this.customerform.get('customerid').value>0){
                       //Update Department
                       this.fnSetCustomer(data);
                     }
                     else{
                       //Insert Department
                       this.fnInsertCustomer(data);
                     }
               }
               else{        
                     if(_checkresult==="mobile"){
                       this.customerform.controls['mobile'].setErrors({'notfound': true});
                     }
                     else if(_checkresult==="email"){
                      this.customerform.controls['email'].setErrors({'notfound': true});
                    }
               }
         },
         (err)=>{
             this.toastr.error('Error', err);           
         });
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertCustomer(data){    
 
   this.appService.fnInsertCustomerAdmin(data).subscribe(
     (res)=>{
     if(res.json().result!=""){
       this.toastr.success('', "The new customer has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.firstname+"- "+data.mobile);

       this.fnCloseCustomer();
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetCustomer(data){    

this.appService.fnSetCustomerAdmin(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The customer has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.customerid,data.firstname+"- "+data.mobile);

     this.fnCloseCustomer();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseCustomer(){
try{
  if(this.customerid>0){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        'id': this.customerid,
      }
      }

    this.router.navigate(['/CustomerView'], navigationExtras);
  }
  else{
    this.router.navigate(['/Customer']);
  }
  
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){

  deptBO.dob= this.fnConvertDatetime(this.fnFormatDatetime(deptBO.dob));

 this.customerform.setValue({
  firstname:deptBO.firstname,
  lastname:deptBO.lastname,  
  mobile:deptBO.mobile,
  email:deptBO.email,
  referralcode:deptBO.referralcode,
  refercode:deptBO.referralcodecustomer,
  gender:deptBO.gender,
  dob:deptBO.dob,
  commlanguageid:deptBO.commlanguageid,
  altmobile:deptBO.altmobile,
  customerid:deptBO.customerid,   
  inactive:false,    
 });


this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}


fnFormatDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      return this.datePipe.transform(dt,"yyyy-MM-dd")  
    }
    
    }else {
    return null
  }
  
}

fnConvertDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      

      var dateParts = dt.split("-"); 

       var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 

       sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());

       
       
       return sdate;
    }
    
    }else {
    return null
  }
 
}


fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:"Customer",
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}



private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}


}
