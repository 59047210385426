import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emptystage',
  templateUrl: './emptystage.component.html',
  styleUrls: ['./emptystage.component.css']
})
export class EmptystageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
