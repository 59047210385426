import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDisablecontrol]'
})
export class DisablecontrolDirective {
 
	@Input() set disableControl(condition : boolean)
	{
		const action = condition ? 'disable' : 'enable';
    
		setTimeout(() => this.ngControl.control[action](), 10);
	}

	constructor(private ngControl : NgControl)
	{
	}
}
